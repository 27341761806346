const pincodeData = [
    {
        "pincode": 751002,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413402,
        "city": "Vairag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 471525,
        "city": "Lavkush Nagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 788107,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 380002,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 303008,
        "city": "Dudu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796290,
        "city": "Ngopa",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 756056,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560043,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686602,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 226011,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743710,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605702,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670676,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 625572,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686539,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 324007,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686519,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144040,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523336,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396540,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 576222,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843132,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 603211,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 277502,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642117,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723157,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678642,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457550,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 770038,
        "city": "Lahunipara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 272105,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800016,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 504305,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 473444,
        "city": "Mungaoli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 175042,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 603101,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758085,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 742202,
        "city": "Dhulian",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 612501,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221718,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581353,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248201,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 805107,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 142051,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 520015,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 222303,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 302007,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630554,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695510,
        "city": "Neyyattinkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523169,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678542,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754207,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722169,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713423,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845452,
        "city": "Lauria Nandangarh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577182,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 133206,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 192202,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413512,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843138,
        "city": "Buxar",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 612102,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 451881,
        "city": "Pansemal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 847309,
        "city": "Balugaon",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 770046,
        "city": "Birmitrapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394107,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 822128,
        "city": "Nagar Untari",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 733207,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635814,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 790001,
        "city": "Bomdila",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 802129,
        "city": "Malyabag",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533252,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281206,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361336,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 632511,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400034,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 753013,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581352,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172028,
        "city": "Nankhari",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 816120,
        "city": "Barharwa",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382815,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 682316,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 522410,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682033,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 825401,
        "city": "Chatra",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 795159,
        "city": "Noney",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 828131,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 793126,
        "city": "Riangdo",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 782425,
        "city": "Chaparmukh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743272,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636307,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 325205,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444705,
        "city": "Daryapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 787035,
        "city": "Gogamukh",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 322204,
        "city": "Nadoti",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605104,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600077,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754118,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 458336,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 496109,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 586102,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412302,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 793015,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 207121,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394185,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 210202,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571115,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 608502,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370156,
        "city": "Rapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 209822,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 614802,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470666,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 848216,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626135,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576254,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 757053,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 603104,
        "city": "Tirukalukundram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761123,
        "city": "Jagannathprasad",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560006,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 136034,
        "city": "Pehowa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721405,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 224234,
        "city": "Faizabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721513,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695041,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 321029,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276131,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 364421,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 263656,
        "city": "Dwarahat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415611,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 390007,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 841286,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 227813,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533125,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629163,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629708,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272202,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630104,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444125,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 364275,
        "city": "Palitana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680584,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 783385,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 532425,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484552,
        "city": "Pali Birsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 752093,
        "city": "Daspalla",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676503,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 637020,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686501,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 629601,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 785604,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 847232,
        "city": "Khutauna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262309,
        "city": "Tanakpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271607,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500101,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 824220,
        "city": "Barachatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 232115,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638503,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244922,
        "city": "Shahabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506012,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 206303,
        "city": "Kishni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413523,
        "city": "Ahmadpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 204212,
        "city": "Sikandra Rao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272209,
        "city": "Khalilabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 414011,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 768205,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431145,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673303,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 713361,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 233222,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 477331,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 415511,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 363622,
        "city": "Wankaner",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600101,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 854203,
        "city": "Bhawanipur Rajdham",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523264,
        "city": "Chimakurthy",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276405,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 455111,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 835103,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752079,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 171212,
        "city": "Theog",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673308,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 206252,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411009,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 781334,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 140123,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673513,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 804426,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626125,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470228,
        "city": "Rehli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 613504,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614205,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631151,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638706,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192123,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847215,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534475,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683518,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754292,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 277212,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573102,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248018,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 181113,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688503,
        "city": "Haripad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 614203,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517501,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 723133,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370130,
        "city": "Anjar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 205302,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 206130,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505497,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534406,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571432,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835230,
        "city": "ChainpurJH",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518112,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630557,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271824,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261403,
        "city": "Mishrikh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250223,
        "city": "Daurala",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382835,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 713302,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 305813,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410207,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 751029,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 524240,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606205,
        "city": "Memalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605755,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202392,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225122,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416551,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 610204,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518135,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614717,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507183,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690513,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 495445,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 400064,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754037,
        "city": "Badamba",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788169,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627856,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382170,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713303,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695027,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 210501,
        "city": "Muskara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534305,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148105,
        "city": "Barnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493109,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 639205,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400001,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 581117,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401703,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 851204,
        "city": "Khagaria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 801306,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614626,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524406,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522307,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641687,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486223,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 303301,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781311,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 788719,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 781017,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 496005,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 734227,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627422,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509357,
        "city": "Amangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456331,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 304025,
        "city": "Bonli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125102,
        "city": "Ellenabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 182117,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533345,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852108,
        "city": "Supaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 334402,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500095,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 561213,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600110,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 682009,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 743288,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515832,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160064,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 147104,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246735,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690573,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 455459,
        "city": "Khategaon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 176315,
        "city": "Bharmour",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 457331,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 391101,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 382410,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600063,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 121014,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 590006,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743441,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682041,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 783320,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 394650,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 191104,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 201007,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246729,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626124,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534225,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686004,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 221407,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577533,
        "city": "Hosadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 146002,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411045,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 441226,
        "city": "Saoli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 249203,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686635,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 452016,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 631206,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829105,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362640,
        "city": "Manavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 177106,
        "city": "Ghati Billan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 325601,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799266,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 143006,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 831003,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680002,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 302024,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 523155,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517581,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225401,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249155,
        "city": "Ghansali",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 361320,
        "city": "Bhatiya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 360570,
        "city": "Kutiyana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 506112,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423202,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 145029,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560116,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 756140,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625530,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612107,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 608305,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440036,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 321302,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754106,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 685553,
        "city": "Nedumkandam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 487551,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 678001,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 410302,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 670008,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 603110,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847240,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606201,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781030,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 712153,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523165,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500052,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577543,
        "city": "Challakere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781014,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 761125,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788007,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 683515,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 503225,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721458,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521332,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227105,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743442,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847119,
        "city": "Bharwara",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 752102,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322236,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454365,
        "city": "Kukshi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 222126,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 335702,
        "city": "Srivijaynagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534311,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574326,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577429,
        "city": "Soraba",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144516,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 203135,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 335027,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 394651,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 415107,
        "city": "Rahimatpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 281202,
        "city": "Vrindavan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841318,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609606,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712221,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680656,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341001,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516115,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523357,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689519,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 574233,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606104,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689595,
        "city": "Haripad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 322218,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 193109,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786622,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 334006,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262908,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686521,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628623,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609106,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382727,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 522403,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431109,
        "city": "Gangapur-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 639007,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 788103,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 524306,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333707,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133302,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735206,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585216,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639001,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229404,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 334002,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752119,
        "city": "Astaranga",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712122,
        "city": "Kamarpukur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673618,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 370465,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700117,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742212,
        "city": "Farakka",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 457778,
        "city": "Jhabua",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 360311,
        "city": "Gondal",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 276305,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 193502,
        "city": "Bandipora",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845101,
        "city": "Bagaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 458888,
        "city": "Garoth",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 483119,
        "city": "Tendukheda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 229411,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695581,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 388130,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 175026,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691603,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400210,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 191103,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403721,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 759028,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670012,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 814150,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 273213,
        "city": "Sikriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 797113,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 521261,
        "city": "Vuyyuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754029,
        "city": "Athagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425424,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 384550,
        "city": "Vijapur",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 799260,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 523303,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516003,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416522,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 142047,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332002,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 496661,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 209652,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 455227,
        "city": "Chapda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 481884,
        "city": "Bajag",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 614207,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768006,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 796161,
        "city": "Saitual",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 410303,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 173028,
        "city": "Paonta Sahib",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 483110,
        "city": "ShahpuraMP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 521343,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756166,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786008,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 689517,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502302,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422113,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 523186,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143205,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470229,
        "city": "Rehli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 678503,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 632004,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 761106,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505215,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741250,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 815359,
        "city": "Kundahit",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 396409,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 205001,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682320,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 814131,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 226101,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247340,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814152,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 530027,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 723161,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 244601,
        "city": "Thakurdwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227207,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 125055,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 604210,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524403,
        "city": "Vidyanagar",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581109,
        "city": "Hirekerur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636806,
        "city": "Palacode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176084,
        "city": "Dheera",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 147202,
        "city": "Mandi Gobindgarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721122,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415634,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 855120,
        "city": "Bahadurganj",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 176318,
        "city": "Chamba",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532222,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845414,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700088,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 530025,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 411012,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 201016,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 803211,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793120,
        "city": "Mairang",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679327,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 442401,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 732138,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828203,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 497220,
        "city": "Ramanujganj",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 382435,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680022,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144015,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518468,
        "city": "Dhone",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 816124,
        "city": "Madhupur",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 686142,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 785641,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 271836,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 411208,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 680661,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 622503,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507135,
        "city": "Nellipaka",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711105,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577522,
        "city": "Challakere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606705,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522401,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 816104,
        "city": "Pakur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691005,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 283152,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209293,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711304,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627353,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 518002,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 637002,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813201,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676105,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 121011,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 688003,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689505,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 815316,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246701,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497117,
        "city": "Premnagar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 576232,
        "city": "Byndoor",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636119,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689586,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 472115,
        "city": "Jatara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 461331,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 784150,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 685604,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 394010,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 394660,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 123106,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712201,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413506,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444111,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 575030,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606110,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635204,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799100,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 621706,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560025,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 201017,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517408,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285127,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224207,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503003,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 232120,
        "city": "Mughal Sarai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606903,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423303,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 342005,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 481995,
        "city": "Bichhiya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 786121,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 225204,
        "city": "Barabanki",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 306703,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 797121,
        "city": "Kohima",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 758079,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 225305,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614703,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396110,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521329,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507166,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686586,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 678582,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560063,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688543,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 685556,
        "city": "Kuthumkal",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 638002,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 821108,
        "city": "Kudra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628229,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625111,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625023,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 363530,
        "city": "Chotila",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 560037,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591244,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285122,
        "city": "Orai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845425,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627803,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799277,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 524313,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563136,
        "city": "Mulbagal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813115,
        "city": "Banka",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 736134,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691562,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577415,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 490026,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 141308,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741160,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 487770,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 683514,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 712154,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783371,
        "city": "Chapar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 206301,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796701,
        "city": "Lunglei",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 505524,
        "city": "Choppadandi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182201,
        "city": "Doda",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690539,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 441803,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 403114,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 628215,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322235,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680569,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 679344,
        "city": "Nilambur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 741503,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785654,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627809,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 181145,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785612,
        "city": "Bokakhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 244714,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 387240,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 843118,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341021,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721453,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 424304,
        "city": "Sakri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 802159,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 835102,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581323,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 846003,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689661,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 393135,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 421300,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 641663,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574231,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591232,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403104,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 502115,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122413,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396145,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691334,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 723214,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673025,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 766104,
        "city": "Nuapada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 770014,
        "city": "Subdega",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 770021,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 491113,
        "city": "Berla",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 249126,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581202,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517112,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431136,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788706,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 713371,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 221007,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 524002,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143119,
        "city": "Tarsikka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802182,
        "city": "Sasaram",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 761005,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411015,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 415614,
        "city": "Ganpatipule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 326516,
        "city": "Chaumahla",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413008,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 141203,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 586125,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 787029,
        "city": "Gogamukh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 504309,
        "city": "Jainad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 228131,
        "city": "Dostpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841215,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 685502,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 323613,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 171014,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673010,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 583232,
        "city": "Yelburga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721130,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700012,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 171214,
        "city": "Chirgaon-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413534,
        "city": "Bhoom",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441408,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 630103,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 509339,
        "city": "Kosgi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631602,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683554,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425511,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 335051,
        "city": "Raisinghnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585228,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403516,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 185151,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444902,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 737121,
        "city": "Jorethang",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 560117,
        "city": "Bangalore",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 144037,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 495119,
        "city": "Pendra",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 276201,
        "city": "Kerakat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521231,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 441903,
        "city": "Pauni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795010,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 403002,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388215,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 342007,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761107,
        "city": "Badagada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686146,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 171205,
        "city": "Jubbal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382165,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 756171,
        "city": "Dhamara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585290,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416628,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517414,
        "city": "Punganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742307,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 204213,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410515,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 800009,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521312,
        "city": "Gannavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212301,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754225,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781339,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 394710,
        "city": "Ahwa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 734320,
        "city": "Kalijhora",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 225206,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508250,
        "city": "Yacharam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641047,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572142,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122007,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 582103,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795129,
        "city": "Kangpokpi",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 509127,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622403,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841217,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695571,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 795112,
        "city": "Senapati",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 581400,
        "city": "Mallapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571254,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480882,
        "city": "Seoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673602,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425307,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144632,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628701,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713335,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362268,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 641006,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625402,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614701,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 326513,
        "city": "Bhawani Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151302,
        "city": "Talwandi Sabo",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757031,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 146108,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754231,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 185202,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 151212,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581153,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679511,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 632009,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517591,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572217,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522124,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382415,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 325222,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 335901,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844507,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389325,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 136033,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785002,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 591339,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756119,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 380024,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 682022,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 209748,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 575024,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 606908,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620120,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636815,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484120,
        "city": "Shahdol",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 444203,
        "city": "Shegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416412,
        "city": "Jath",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 201315,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532212,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560050,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802220,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577180,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473443,
        "city": "Mungaoli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 785632,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 390008,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 604206,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585105,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627758,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312205,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591236,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689722,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 824206,
        "city": "Imamganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700148,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 833216,
        "city": "Seraikella",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621117,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175027,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148007,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 281004,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 206250,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396150,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 229417,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574109,
        "city": "Eedu",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 759119,
        "city": "Pallahara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 515241,
        "city": "Gorantla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600081,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 211022,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781006,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 271845,
        "city": "Ikauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 793105,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 230123,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 140601,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756036,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673521,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571105,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124021,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802223,
        "city": "Sandesh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 425405,
        "city": "Shirpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 231208,
        "city": "Renukoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848135,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246472,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673028,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 682024,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 410401,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 606107,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443105,
        "city": "Buldhana",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364265,
        "city": "Palitana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 301713,
        "city": "Narnaul",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305408,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140702,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412203,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 761116,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 613103,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524226,
        "city": "Udayagiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843152,
        "city": "Sahebganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 711415,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754155,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143409,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759143,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 442109,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 191202,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632403,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515005,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 425510,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 335513,
        "city": "Hanumangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363520,
        "city": "Chotila",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721121,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786124,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 508252,
        "city": "Choutuppal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635808,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143116,
        "city": "Tarsikka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688005,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 246164,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712701,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175105,
        "city": "Jari",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 609805,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361310,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 632608,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302005,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 272201,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360545,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 456222,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 736101,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140502,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532427,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201307,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425109,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273011,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505467,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783301,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 629003,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 481115,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 741404,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122232,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 127114,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502267,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263127,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721212,
        "city": "Ghatal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384410,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635754,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581314,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192401,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534210,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532243,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285001,
        "city": "Orai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678559,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 396375,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 387370,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 274601,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713157,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 313334,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583287,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522016,
        "city": "Tadikonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 833215,
        "city": "Chaibasa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175023,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 483331,
        "city": "Raipura",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 221302,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676528,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 341502,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402307,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 211013,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 801105,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212665,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305601,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635123,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203121,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 794108,
        "city": "Resubelpara",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 638696,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 734218,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743302,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263002,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415005,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 229103,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363631,
        "city": "Morbi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 530014,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 793122,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 754024,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209501,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470115,
        "city": "Khurai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 574117,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688524,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843313,
        "city": "Dhaka",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606302,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364081,
        "city": "Talaja",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721503,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841241,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416015,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628401,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623206,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783389,
        "city": "Chapar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 685605,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 322025,
        "city": "Khandar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 787052,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 303804,
        "city": "Chomu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785696,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 560041,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176325,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585202,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390017,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 221005,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 474012,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 143115,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 611117,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 488442,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 176309,
        "city": "Bharmour",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391421,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 515202,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383240,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 679328,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 752104,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144424,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 402206,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 797105,
        "city": "Tseminyu",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 612701,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627853,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495663,
        "city": "Akaltara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 302025,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 722151,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362650,
        "city": "Kutiyana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 670645,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 797120,
        "city": "Kohima",
        "state": "Nagaland",
        "Zone": "NE"
       },
       {
        "pincode": 734122,
        "city": "Bijanbari",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784510,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 604409,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799035,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 271503,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 504312,
        "city": "Adilabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670604,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 829205,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691017,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629197,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742166,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177208,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 475001,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 481111,
        "city": "Malanjkhand",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 441801,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 389151,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 421006,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691524,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635205,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 133021,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 805102,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 333045,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522619,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580114,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686015,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756128,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 508001,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 737128,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 848134,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534215,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495455,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 626190,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 494223,
        "city": "Bastar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 284303,
        "city": "Poonchh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731301,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 485446,
        "city": "Nagod",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431540,
        "city": "Parbhani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 851220,
        "city": "Khagaria",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 795003,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 415506,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 626136,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574225,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673047,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 734011,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 631703,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764005,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518385,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387560,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 223102,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176203,
        "city": "Nurpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247776,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222111,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571607,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713343,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676301,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686512,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 225406,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670332,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 721422,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383330,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 281308,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 851211,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416507,
        "city": "Halkarni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141127,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425309,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 583220,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 575018,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609003,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638316,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560005,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 612704,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 251318,
        "city": "Budhana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261202,
        "city": "Biswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722132,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380012,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 678631,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 385540,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 625004,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 637203,
        "city": "Tiruchengode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322023,
        "city": "Bonli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 685511,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 212404,
        "city": "Phulpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586124,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212650,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802122,
        "city": "Rajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734311,
        "city": "Labha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 493335,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 691545,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341305,
        "city": "Didwana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176308,
        "city": "Sundla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400007,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 382110,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 425305,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 752062,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 205152,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754237,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 751005,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680662,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 848155,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628907,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273008,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 395210,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 624101,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 455332,
        "city": "Khategaon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 243701,
        "city": "Milak",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741138,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761052,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700030,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841312,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 124108,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425448,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 142049,
        "city": "Bagha purana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416614,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 788720,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 678010,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 523201,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636803,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825303,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 580118,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480884,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 843350,
        "city": "Sitamarhi",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 591181,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 225208,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 390005,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 754293,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 184203,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415414,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 732143,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571402,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 845106,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695122,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 441807,
        "city": "Sadak Arjuni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 305024,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160009,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411058,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 523190,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121106,
        "city": "Hodal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 414110,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 721632,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534435,
        "city": "Dwaraka Tirumala",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754247,
        "city": "Cuttack",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 210121,
        "city": "Baberu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 401102,
        "city": "Saphale",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 626102,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621718,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 341509,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638011,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 393040,
        "city": "Dediapada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 814115,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 452006,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 124513,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505187,
        "city": "Peddapalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492015,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 242021,
        "city": "Babrala",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686518,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757034,
        "city": "Jashipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733121,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 201014,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 473002,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 577544,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508223,
        "city": "Thirumalagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770040,
        "city": "Lahunipara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 233301,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835305,
        "city": "Lohardaga",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767038,
        "city": "Khaprakhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 387310,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413309,
        "city": "Sangola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110060,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 683111,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 389350,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 766020,
        "city": "Muniguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 803202,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 395013,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 721153,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639136,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756168,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522236,
        "city": "Tadikonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691521,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 466119,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 486889,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 140405,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752065,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606401,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639112,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814117,
        "city": "Dumka",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 680301,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 614401,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272003,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305021,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623707,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390015,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 760034,
        "city": "Behrampur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 624306,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625125,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246441,
        "city": "Tharali",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673623,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756025,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627855,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148023,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756084,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785661,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 605012,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 736133,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761215,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 457339,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416414,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 421001,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 601208,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 242405,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388132,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 392140,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500380,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 803218,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 140203,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 488050,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 628003,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600099,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636352,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431742,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 587312,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732216,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 126101,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422212,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 445206,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 332028,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144003,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 251301,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700050,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 535214,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451221,
        "city": "Maheshwar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 160104,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680601,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303502,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 576219,
        "city": "Byndoor",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360405,
        "city": "Dhoraji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 473112,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 360460,
        "city": "Upleta",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 690563,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 221706,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581362,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851112,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614206,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327608,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141118,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722112,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 121103,
        "city": "Palwal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686687,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 673526,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624206,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 201003,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 822113,
        "city": "Chhatarpur-JR",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 484881,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431711,
        "city": "Dharmabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382042,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 638512,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829103,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414402,
        "city": "Karjat-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 736102,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 380051,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 110026,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 533241,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152123,
        "city": "Fazilka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630207,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 480004,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 142030,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 331303,
        "city": "Rajgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 390010,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 811310,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 813123,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 535534,
        "city": "Kurupam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606707,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496665,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 695570,
        "city": "Kallikkad",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 641003,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505407,
        "city": "Jammikunta",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 762110,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 305403,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 753005,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332024,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125056,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591246,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421603,
        "city": "Asangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 711120,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621115,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173220,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 854204,
        "city": "Bhawanipur Rajdham",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 492109,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110080,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 803212,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 517297,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581320,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131023,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 475533,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 628702,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 424309,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 823000,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534272,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690109,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382025,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 202132,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506223,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532186,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627757,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151004,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 202421,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624006,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 441609,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584123,
        "city": "Manvi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 282008,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144523,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845455,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722155,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500035,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691309,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401402,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 505455,
        "city": "Jagtial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689121,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 141106,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572118,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127040,
        "city": "Siwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712429,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221011,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605601,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577231,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686012,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 370007,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 335803,
        "city": "Pilibanga",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637411,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600006,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 244225,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341517,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 152115,
        "city": "Malout",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110116,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 458895,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 177034,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 813113,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695568,
        "city": "Nedumangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 148008,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743203,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125047,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583111,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781369,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 755051,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 758082,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721440,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 491337,
        "city": "Mungeli",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431809,
        "city": "Dharmabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 401608,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 147201,
        "city": "Nabha",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 492002,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 247661,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411051,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 829201,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 341013,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585224,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534217,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522615,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202133,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683550,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 481441,
        "city": "Waraseoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 786007,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 518348,
        "city": "Alur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560046,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 131028,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 854301,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 787059,
        "city": "Silapathar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 603103,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 393110,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 222128,
        "city": "Mariahu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 123029,
        "city": "Mahendragarh-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581329,
        "city": "Haliyal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 185156,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176032,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688586,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403702,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 793019,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 764036,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 813212,
        "city": "Sangrampur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144504,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228121,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673505,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679331,
        "city": "Nilambur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 193411,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313327,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382350,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 671319,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627604,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171007,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465691,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 636905,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412104,
        "city": "Saswad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 825317,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 277214,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799202,
        "city": "Khowai",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 227111,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509336,
        "city": "Kosgi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782426,
        "city": "Kampur Town",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 410218,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 845457,
        "city": "Dhaka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 532406,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 607308,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516129,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796082,
        "city": "Kolasib",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 272206,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 485005,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 523001,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362215,
        "city": "Mendarda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 273407,
        "city": "Sikriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394130,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 504294,
        "city": "Asifabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680509,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 761132,
        "city": "Galleri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110302,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 734224,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 481102,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 585291,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274806,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686662,
        "city": "Koothattukulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 306021,
        "city": "Marwar Junction",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625011,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636104,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742121,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572108,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 247775,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174002,
        "city": "Naina Devi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 205151,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 613501,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572126,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560007,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 768035,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332711,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695002,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 625566,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 394163,
        "city": "Mandvi (Surat)",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 722173,
        "city": "Hura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524304,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421004,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 283125,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673003,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 212654,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 201106,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682307,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413218,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 131403,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210422,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743353,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 133010,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742102,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 466114,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 425113,
        "city": "Amalner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 306103,
        "city": "Sojat",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389341,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686026,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 691319,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 122204,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 302015,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605011,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679302,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680519,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 229403,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689682,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 752118,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670692,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 673585,
        "city": "Narikkuni",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 638182,
        "city": "Velur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504106,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535280,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627759,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110009,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 225207,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110022,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 627413,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221503,
        "city": "Saidabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686730,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 172108,
        "city": "Reckong Peo",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581165,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508248,
        "city": "Devarakonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583223,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370160,
        "city": "Rapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 383421,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 523187,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506381,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712712,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 768224,
        "city": "Bamra",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680655,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416201,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 160041,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 852202,
        "city": "Saharsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 639115,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322255,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503311,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496115,
        "city": "Lailunga",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742286,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 127201,
        "city": "Loharu",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229205,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743435,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505502,
        "city": "Jammikunta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721655,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 470661,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 781328,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 700089,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 225202,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493996,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 413120,
        "city": "Indapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583114,
        "city": "Siruguppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721304,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721638,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 563132,
        "city": "Mulbagal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627451,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444501,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 277124,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144701,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412312,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788832,
        "city": "Lumding",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 262904,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491009,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 203205,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 462001,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 177023,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721504,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 233223,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522341,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535270,
        "city": "Gajapatinagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441214,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515631,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411062,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 132025,
        "city": "Assandh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628704,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465687,
        "city": "Pachore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 691584,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507111,
        "city": "Bhadrachalam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342006,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415616,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 173002,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502117,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632059,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416209,
        "city": "Mudal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 782103,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 606803,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638053,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 507182,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413604,
        "city": "Omerga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686532,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 122003,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516504,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380026,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 678600,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 276125,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695501,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 829116,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415001,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 274202,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403405,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712614,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 520009,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 383002,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 464224,
        "city": "Bina",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 752050,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788804,
        "city": "Kalain",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 847115,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 185212,
        "city": "Rajouri",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516319,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517589,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799279,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 285123,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 804404,
        "city": "Khizasarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500042,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 676521,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 801506,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 303107,
        "city": "Paota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413412,
        "city": "Vairag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 530029,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 628001,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534330,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 460666,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 174033,
        "city": "Jukhala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522421,
        "city": "Macherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151213,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591101,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 363650,
        "city": "Morbi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271209,
        "city": "Itia Thok",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712502,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 629706,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622301,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384312,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224205,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788102,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 691331,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 221004,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783347,
        "city": "Dotma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 701101,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629187,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690521,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628102,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721423,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416613,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 421501,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 132041,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533222,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743270,
        "city": "Helencha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 562161,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444903,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 785013,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 756083,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 202527,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500020,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 506303,
        "city": "Jangaon",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414002,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 700016,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 803102,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743391,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741515,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627114,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743357,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209625,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691579,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 573215,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629303,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713337,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756101,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581330,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321605,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629174,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229412,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517305,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623708,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628152,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413114,
        "city": "Walchandnagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 227406,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847410,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303108,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560028,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600122,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 485111,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 582111,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848211,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400615,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 770074,
        "city": "Subdega",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825314,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731302,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627435,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600018,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713406,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403710,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 782104,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 174036,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573142,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 485441,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 501512,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532459,
        "city": "Kotturu-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400028,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743379,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733124,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 450006,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 670305,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 848122,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678733,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 456010,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 535202,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480441,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 249196,
        "city": "Uttarkashi",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534302,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691582,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 732123,
        "city": "Chanchal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226103,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690103,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 603204,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 202165,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673577,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721222,
        "city": "Ghatal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691625,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754105,
        "city": "Adaspur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759032,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624312,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176312,
        "city": "Sundla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 852115,
        "city": "Udakishanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605108,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332311,
        "city": "Laxmangarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226003,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507164,
        "city": "Sathupally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486885,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 689504,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629504,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273014,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382424,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 360008,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686560,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 524123,
        "city": "Sullurupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691310,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636111,
        "city": "Mettala",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 792105,
        "city": "Namsai",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 333022,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341542,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782137,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 753295,
        "city": "Cuttack",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 751054,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 391430,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680006,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 110605,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 754020,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110043,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 344708,
        "city": "Dhorimana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625008,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 606604,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829121,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415727,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 182125,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 182301,
        "city": "Katra",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626105,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501505,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629154,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751021,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531022,
        "city": "Seetharampram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785001,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 743700,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 152120,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626716,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360585,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 193302,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413511,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581203,
        "city": "Hangal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574216,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682309,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 273305,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752059,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 387630,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 760008,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388220,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 758075,
        "city": "Telkoi",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 332411,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678548,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 503120,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147103,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574142,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743701,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734009,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638327,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192231,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680591,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689101,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 223107,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396210,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 177027,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695134,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 440015,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 679583,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686502,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444709,
        "city": "Dhamangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144416,
        "city": "Phillaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683553,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515144,
        "city": "Mudigubba",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782403,
        "city": "Jagiroad",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 422005,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 585419,
        "city": "Basavakalyan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517132,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451665,
        "city": "Sendhwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 460330,
        "city": "Betul",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 534460,
        "city": "Chintalpudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441215,
        "city": "Saoli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743262,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 402106,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721156,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394245,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 396180,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413581,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 273203,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743312,
        "city": "Canning",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274206,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796007,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 506008,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741121,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271504,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 762020,
        "city": "Puranakatak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 174312,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571608,
        "city": "Krishnarajngr",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796017,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 152021,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614405,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518599,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416702,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 384460,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 483880,
        "city": "Vijayraghavgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 462039,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 110007,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 209749,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534207,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143526,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382840,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 241301,
        "city": "Madhoganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 171213,
        "city": "Kumarsain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 486776,
        "city": "Rampur Naikin",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 400207,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 276306,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493562,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 624706,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689641,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 622004,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335062,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627251,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524228,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413108,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416801,
        "city": "Talere",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 506329,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 792111,
        "city": "Chowkham",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 246736,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625533,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788116,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 757056,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 605401,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629807,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411100,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713364,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143114,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621202,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380009,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 614723,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456221,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 522264,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176501,
        "city": "Ghati Billan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630321,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854108,
        "city": "Korha",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 793111,
        "city": "Cherrapunji",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 571302,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691315,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 783370,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 722122,
        "city": "Bishnupur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626141,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712247,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 470442,
        "city": "Barodia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 487771,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 271306,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454648,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713131,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584126,
        "city": "Devadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841503,
        "city": "Kuchaikote",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673326,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 203397,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221714,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 765025,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382028,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 604301,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532220,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423601,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 456550,
        "city": "Agar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 827002,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500111,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688550,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 691322,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273307,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743443,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522034,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626204,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313802,
        "city": "Kherwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755010,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 683525,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515765,
        "city": "Kambadur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209721,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764002,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614301,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491557,
        "city": "Dongargarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 301417,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782412,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 680596,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 797004,
        "city": "Kohima",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 314023,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625106,
        "city": "Melur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505532,
        "city": "Jagtial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695310,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 822101,
        "city": "Daltonganj",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 171004,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785611,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 193103,
        "city": "Baramulla",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625101,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623005,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534425,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689721,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 570034,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 392155,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 442702,
        "city": "Gondpipri",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 502287,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425311,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 524224,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443001,
        "city": "Buldhana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673007,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 506324,
        "city": "Danthalapally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151214,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535221,
        "city": "S Kota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175038,
        "city": "Nihri",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110027,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 207242,
        "city": "Ganjdundwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 473665,
        "city": "Karera-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 523182,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224201,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522310,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825418,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 582115,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442306,
        "city": "Pulgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 676505,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627821,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281302,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799280,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 582201,
        "city": "Navalgund",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241304,
        "city": "Mishrikh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209305,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 635752,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581108,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123001,
        "city": "Narnaul",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 208019,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 791120,
        "city": "Ziro",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 737131,
        "city": "Rongli",
        "state": "Sikkim",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 441809,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 843146,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686630,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 176125,
        "city": "Baijnath",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574154,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562119,
        "city": "Kanakapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473335,
        "city": "Ashok Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673019,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 743601,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 843165,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 272155,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688534,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627427,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431805,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 768045,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624802,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274307,
        "city": "Padrauna",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 222108,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 472445,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 731249,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673613,
        "city": "Balussery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560079,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689621,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 784179,
        "city": "Behali",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 160032,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 201301,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 458883,
        "city": "Garoth",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 767030,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 247451,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464881,
        "city": "Begumganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 335705,
        "city": "Pilibanga",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470117,
        "city": "Khurai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 313706,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802113,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 630205,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 842009,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504308,
        "city": "Adilabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124203,
        "city": "Jhajjar",
        "state": "Haryana",
        "Zone": "N2"
       },
       {
        "pincode": 208009,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 123031,
        "city": "Mahendragarh-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 343040,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416306,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 785694,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 741122,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321610,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759147,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342014,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854206,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 603210,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 485004,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 767071,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384120,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680712,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 501111,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 759132,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 524129,
        "city": "Naidupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 208012,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585229,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591119,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563129,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686542,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416001,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 636456,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788167,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 143414,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735135,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 480112,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 761127,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175103,
        "city": "Manali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 181101,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759135,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 277208,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689614,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 209821,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 821110,
        "city": "Nuaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248015,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110079,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 225402,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 206122,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695120,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754220,
        "city": "Chandbali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321408,
        "city": "Weir",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394120,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500073,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400052,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 506350,
        "city": "Narsampet",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 577302,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423207,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 450003,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 221210,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629704,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 250645,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742182,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756120,
        "city": "Dhamanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691571,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 403403,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 206128,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416407,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144403,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756118,
        "city": "Dhamanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143103,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 851201,
        "city": "Maheshkhunt",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643215,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 587314,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523225,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679522,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600050,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 530026,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227413,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301714,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682310,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 678506,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754222,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 402110,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 534444,
        "city": "Kukanoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768219,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342029,
        "city": "Khimsar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 691518,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 573134,
        "city": "Sakleshpur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686605,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 759103,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 473331,
        "city": "Ashok Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 184152,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480000,
        "city": "Sausar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 401101,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 587315,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425114,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 522035,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721445,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690501,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500177,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670632,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 431148,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416145,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 422207,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431515,
        "city": "Parli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 205261,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524302,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754102,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160057,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431743,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521108,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 445301,
        "city": "Ghatanji",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 721201,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 331503,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732148,
        "city": "Malda",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 225123,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389322,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 764071,
        "city": "Pappadahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421604,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 396170,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 769003,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673614,
        "city": "Balussery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843113,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 813231,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416137,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 643226,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711227,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 847202,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 440035,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 396472,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 502310,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524101,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843322,
        "city": "Sonbarsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422213,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382015,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 445204,
        "city": "Pusad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754201,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523279,
        "city": "Kondapi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636702,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631561,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202140,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754004,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629178,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263152,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144501,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 462027,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 502324,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679121,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 243402,
        "city": "Dataganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581333,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505129,
        "city": "Jammikunta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509110,
        "city": "Atmakur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224132,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530048,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306502,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802157,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500074,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 509136,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676502,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 695043,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 603112,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388590,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700130,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480661,
        "city": "Seoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 303801,
        "city": "Chomu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360020,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 141102,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425301,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 801307,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495452,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 722181,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641108,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177043,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 586242,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248003,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 284403,
        "city": "Lalitpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507126,
        "city": "Chintoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470880,
        "city": "Tendukheda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 221713,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431210,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 795101,
        "city": "Kakching",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 272124,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 127028,
        "city": "Bahal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 784115,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 175104,
        "city": "Patlikuhal",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 530040,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 803113,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 245208,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 816106,
        "city": "Maheshpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680608,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507103,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 833221,
        "city": "Noamundi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 203129,
        "city": "Khurja",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535125,
        "city": "Garividi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125042,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508201,
        "city": "Nereducharla",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626531,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680614,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 410210,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 734313,
        "city": "Mungpoo",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425421,
        "city": "Shirpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144033,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388265,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 735207,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533308,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680752,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 673013,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 533218,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642206,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221012,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686595,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 670339,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 422003,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 272192,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243637,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224168,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786610,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 848518,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 172106,
        "city": "Kinnaur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400073,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571301,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394248,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 334802,
        "city": "Nokha",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431709,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502300,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496116,
        "city": "Dharamjaigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 590010,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583116,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 277303,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177113,
        "city": "Chintpurni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517582,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480998,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 735219,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761008,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246125,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 842001,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 762028,
        "city": "Galleri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425110,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 121000,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781003,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305924,
        "city": "Badnaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629251,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629851,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641602,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224121,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209308,
        "city": "Ghatampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400038,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 500031,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841423,
        "city": "Mohammadpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332407,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847311,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 314403,
        "city": "Simalwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464651,
        "city": "Raisen",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 847452,
        "city": "Phulparas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 487001,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 788026,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 263134,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383220,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 607805,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303601,
        "city": "Ajeetgarh",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581318,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801304,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212202,
        "city": "Saray Ankil",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 209869,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784529,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 590007,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508217,
        "city": "Miryalaguda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516127,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 326001,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400218,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 585442,
        "city": "Bhalki",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 781327,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 421302,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 184120,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402209,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695557,
        "city": "Nedumangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403730,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 412112,
        "city": "Pirangut",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 275303,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301607,
        "city": "Nagar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 382460,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 181203,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 231223,
        "city": "Bijpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400061,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627416,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517130,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176302,
        "city": "Nurpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425135,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518464,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212623,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425417,
        "city": "Navapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144406,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605651,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411013,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577157,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 500022,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 711107,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416010,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686005,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 172027,
        "city": "Kumarsain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 484220,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 445305,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 851225,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 306708,
        "city": "Sumerpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685606,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 262552,
        "city": "Didihat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 135101,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 457336,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 221308,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250602,
        "city": "Daurala",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175125,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312203,
        "city": "Kapasan",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757036,
        "city": "Raruan",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571475,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683556,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 608601,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244927,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577519,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141801,
        "city": "Mandi Gobindgarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683516,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 177044,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382220,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 143525,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577450,
        "city": "Shikaripur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122016,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 394345,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 230135,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641024,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 782441,
        "city": "Howraghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686609,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 770019,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441913,
        "city": "Tumsar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583117,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388470,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600130,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577003,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581336,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144405,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630107,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 606805,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689602,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 623532,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470120,
        "city": "Rahatgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 247120,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 794001,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 587202,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301101,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700040,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679313,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 625605,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845453,
        "city": "Lauria Nandangarh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785610,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 518511,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 346005,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 758030,
        "city": "Karanjia",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 611109,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606003,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134119,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788711,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 812008,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847428,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 472111,
        "city": "Tikamgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 456440,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 585311,
        "city": "Aland",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400030,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431010,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 495559,
        "city": "Champa",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 576214,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361325,
        "city": "Bhatiya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686549,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413103,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 360005,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 181133,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632508,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192122,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342020,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636403,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422604,
        "city": "Akole",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 142028,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425422,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 241502,
        "city": "Madhoganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313604,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493778,
        "city": "Nagri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 507001,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 492017,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 455336,
        "city": "Khategaon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 484660,
        "city": "Umaria",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416615,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682302,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757041,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689671,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 364530,
        "city": "Savarkundla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 201015,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 635104,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 313201,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230134,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416422,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 380049,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 390023,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 636203,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403716,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 441304,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 414709,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 363435,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 415407,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 222105,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110084,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 788701,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 421401,
        "city": "Murbad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 272204,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623503,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 695504,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 272141,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 284121,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670633,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 682502,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636602,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442402,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521250,
        "city": "Vuyyuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274406,
        "city": "Sewarhi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144102,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 370020,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 516270,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232336,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 481668,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 201313,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754003,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 333034,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246130,
        "city": "Paithani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502345,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301701,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262540,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388150,
        "city": "Tarapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 476337,
        "city": "Sheopur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 743244,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721455,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679561,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 241504,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400707,
        "city": "Jasai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 344022,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400042,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 641027,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686123,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 764058,
        "city": "Kotapad",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623115,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203150,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221507,
        "city": "Phulpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680698,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 301410,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689508,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756116,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363007,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 515662,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713129,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 193301,
        "city": "Sopore",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700118,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841220,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412109,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 411020,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413307,
        "city": "Sangola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 411002,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 306105,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756163,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743411,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389360,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 230202,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591243,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 790104,
        "city": "Tawang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 844115,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 444907,
        "city": "Warud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673661,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 495112,
        "city": "Ratanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 731304,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509223,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523247,
        "city": "Podili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761136,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721143,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 517587,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581354,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140001,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841416,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305007,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 248115,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518452,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 832301,
        "city": "Chakulia",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 768005,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 533264,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614302,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600032,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743502,
        "city": "Ghatakpukur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508257,
        "city": "Devarakonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442002,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 756022,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380001,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 641301,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444704,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 622204,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360024,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 671212,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 765018,
        "city": "Raygada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 135004,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 486556,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 573214,
        "city": "Sakleshpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635121,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509337,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515213,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680568,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 411018,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 641110,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 152117,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 323801,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 451556,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 382140,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 470051,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144208,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272148,
        "city": "Rudhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 795149,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 613301,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854326,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 410222,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 737133,
        "city": "Rongli",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 642002,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301025,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301042,
        "city": "Alwar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 500409,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122002,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 509152,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591509,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382130,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 741319,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124505,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 403529,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400062,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515787,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523332,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629170,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784154,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 141006,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799156,
        "city": "Manu bazar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 146001,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444504,
        "city": "Washim",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 390022,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 500050,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 364750,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 791121,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 533234,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581148,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755020,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783303,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 422105,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 831001,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 833202,
        "city": "Chaibasa",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 142029,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144006,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531019,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533460,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413250,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415620,
        "city": "Ganpatipule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392015,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 845522,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 679580,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 805124,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 794103,
        "city": "Dalu",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 680657,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 563117,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732212,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 362235,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 229303,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440005,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 503112,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 828204,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639002,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274182,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464001,
        "city": "Vidisha",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 590013,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 755039,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522325,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303307,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518301,
        "city": "Adoni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276403,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 679337,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 171219,
        "city": "Waknaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301413,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689501,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 202128,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464220,
        "city": "Ganj Basoda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 212204,
        "city": "Sirathu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 610103,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425312,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 523261,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 481882,
        "city": "Bajag",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 323001,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 822115,
        "city": "Japla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122010,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637017,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757048,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416222,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303803,
        "city": "Chomu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671310,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756081,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785102,
        "city": "Majuli",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 560013,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 759018,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 465223,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 461444,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 456664,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 784149,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 495444,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 632207,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534467,
        "city": "Chintalpudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 277001,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847213,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678721,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 273405,
        "city": "Kauriram",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642001,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202123,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532456,
        "city": "Kotturu-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444106,
        "city": "Murtizapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621710,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580007,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500070,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442707,
        "city": "Chamorshi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 735232,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524366,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533253,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505401,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847121,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 507161,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 383310,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 626123,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 314402,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 601103,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606706,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444508,
        "city": "Washim",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 847230,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600026,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 628905,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563131,
        "city": "Mulbagal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691585,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 321417,
        "city": "Weir",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 470664,
        "city": "Hatta",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 515870,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695564,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 370165,
        "city": "Rapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 206245,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786183,
        "city": "Chabua",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 844139,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 392135,
        "city": "Dahej",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 516349,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241406,
        "city": "Pihani",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332718,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523252,
        "city": "Podili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721421,
        "city": "Jhargram",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 453446,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 560040,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 221116,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 212108,
        "city": "JariBazar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384110,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 754018,
        "city": "Adaspur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 532216,
        "city": "Pathapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384565,
        "city": "Vijapur",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 177301,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638054,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388052,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 193403,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796181,
        "city": "Serchhip",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 754285,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411019,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 679563,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621713,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400055,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 606904,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754239,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383345,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 788713,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 632114,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413716,
        "city": "Rahuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442104,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 832104,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571134,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171303,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577542,
        "city": "Hosadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385340,
        "city": "Radhanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 535580,
        "city": "Gajapatinagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612203,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534244,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752016,
        "city": "Pipili",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 476228,
        "city": "Sabalgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 761216,
        "city": "Gunupur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 625704,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 682035,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 403728,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721305,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 796012,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 585372,
        "city": "Chincholi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533016,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754002,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606601,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622411,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391310,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 175021,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382743,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 688528,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581348,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577598,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273164,
        "city": "Nautanwa",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785669,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 152131,
        "city": "Fazilka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 813224,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712501,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670571,
        "city": "Alakode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 443302,
        "city": "Lonar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 225405,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560098,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 814147,
        "city": "Mahagama",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 190017,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 852125,
        "city": "Simrahi Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531011,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829127,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614017,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754133,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 147204,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784025,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 472337,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 586245,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735238,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125039,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636138,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515871,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302023,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756046,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535525,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 242123,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796190,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 423117,
        "city": "Chandwad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 484887,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 509102,
        "city": "Kollapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535591,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643216,
        "city": "Kotagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322213,
        "city": "Nadoti",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521369,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 136131,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721302,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 508111,
        "city": "Mothkur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505209,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444510,
        "city": "Risod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 636101,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209209,
        "city": "Ghatampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641011,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 131301,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380029,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 562125,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743123,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362030,
        "city": "Bhesan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 171002,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143112,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533410,
        "city": "Tuni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421103,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 502306,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172115,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678018,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 783382,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 636004,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560011,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517541,
        "city": "Tada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415703,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305009,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305025,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 829132,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301702,
        "city": "Tijara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563147,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 245402,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470118,
        "city": "Khurai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 825405,
        "city": "Chouparan",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 805141,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 225126,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600024,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 851214,
        "city": "Khagaria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 277307,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756060,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262805,
        "city": "Gola",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670706,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 534230,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632513,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680672,
        "city": "Irinjalakuda",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 533216,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415730,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431741,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721518,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 584119,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756003,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 731218,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504311,
        "city": "Utnoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431509,
        "city": "Mantha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382855,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689666,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 222101,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673503,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 622005,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177109,
        "city": "Chintpurni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691512,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 828124,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574323,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530019,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670708,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 152122,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847201,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521182,
        "city": "Nandigama",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761126,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689644,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679311,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 503224,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141012,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614806,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533503,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 184121,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562106,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144010,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 797005,
        "city": "Kohima",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 743223,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506301,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695587,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 143149,
        "city": "Tarsikka",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 451770,
        "city": "Pansemal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 518124,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516101,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444607,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 500043,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414601,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 733144,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621121,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396035,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521330,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613003,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721629,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413227,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 826015,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571604,
        "city": "Krishnarajngr",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731213,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636809,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585428,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 455339,
        "city": "Khategaon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 481661,
        "city": "Mandla",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695133,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 306101,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612601,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784104,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 732211,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742213,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143010,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756113,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761201,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 846008,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388330,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 416106,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 133008,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678011,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415513,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263652,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802314,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 834009,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 760001,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767027,
        "city": "Patnagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621112,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 764012,
        "city": "Jeypore",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 531027,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248145,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571251,
        "city": "Madikeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835302,
        "city": "Lohardaga",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 840001,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609112,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274602,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 671350,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 466002,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 713159,
        "city": "Panagarh",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 401701,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 678003,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 562300,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334604,
        "city": "Lunkaransar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462006,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 629852,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670307,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502316,
        "city": "Narsapur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276143,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422601,
        "city": "Akole",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 481331,
        "city": "Waraseoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431516,
        "city": "Parli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 591239,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504208,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360430,
        "city": "Upleta",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680751,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 425115,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788015,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 388620,
        "city": "Khambhat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 722149,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 393115,
        "city": "Jhagadia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 125113,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 333701,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 311029,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623601,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803205,
        "city": "Fatuha",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262580,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670016,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802115,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444810,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 642152,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583127,
        "city": "Harpanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509411,
        "city": "Kosgi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247470,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621709,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573129,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799144,
        "city": "Santirbazar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 522262,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632505,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388255,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 756033,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382002,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 193504,
        "city": "Bandipora",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 767002,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505514,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 821311,
        "city": "Rohtas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509360,
        "city": "Kalwakurthy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755038,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 563120,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585265,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690503,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 614704,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507113,
        "city": "Chintoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176111,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160042,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785704,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 380021,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 462120,
        "city": "Berasia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 638403,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 800025,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742227,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641101,
        "city": "Madukkarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501102,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621118,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 445307,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 742169,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370455,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700087,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628212,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 242305,
        "city": "Tilhar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422603,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 759130,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752060,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 611114,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577215,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221102,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382315,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431605,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441406,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 425302,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 785680,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 313001,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761118,
        "city": "Jagannathprasad",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122020,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571511,
        "city": "Ramanagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761036,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695016,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 759016,
        "city": "Bhuban",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636002,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 506315,
        "city": "Danthalapally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627004,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805133,
        "city": "Rajgir",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 464661,
        "city": "Raisen",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 812007,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 758031,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847301,
        "city": "KalyanpurBR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 851113,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623521,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532474,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624302,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799278,
        "city": "Aambasa",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 416426,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 741247,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 711303,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 424302,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 481663,
        "city": "Ghugri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 403706,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712134,
        "city": "Memari",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517569,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764087,
        "city": "Kalimela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125052,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535145,
        "city": "S Kota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700111,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521149,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736173,
        "city": "Dinhata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841505,
        "city": "Kuchaikote",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421202,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 786187,
        "city": "Margherita",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 534197,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735213,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521126,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466113,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 643237,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143204,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148022,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679582,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686580,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 612205,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784026,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 625537,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 211015,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752107,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782101,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 422006,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 848218,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415013,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303510,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 590017,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754031,
        "city": "Badamba",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825102,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577245,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 481776,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 382305,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 283101,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261151,
        "city": "Maholi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424306,
        "city": "Sakri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 680618,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 799013,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 495695,
        "city": "Sakti",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 363410,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 841408,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680304,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506345,
        "city": "Bhupalpally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741238,
        "city": "Gopalnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743445,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591112,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333515,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403105,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 814154,
        "city": "Mahagama",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824122,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521247,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636001,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 231217,
        "city": "Renukoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680547,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670301,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756018,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506352,
        "city": "Mulugu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 146111,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691531,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500123,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 605751,
        "city": "Memalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700069,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522305,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678504,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 272165,
        "city": "Sikriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680011,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 483504,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 722135,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679589,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 625015,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 131039,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606209,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144514,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845406,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160101,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700055,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 233228,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560106,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 173229,
        "city": "Dharampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202410,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686664,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577227,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825410,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522306,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272002,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 580021,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144223,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686638,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 673615,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 711224,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508207,
        "city": "Miryalaguda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423604,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 495684,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 250615,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224125,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623523,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401104,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 495453,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431214,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 277403,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521135,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174317,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784501,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 416709,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 516503,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414607,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689673,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689103,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 509321,
        "city": "Amangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505331,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574274,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504323,
        "city": "Boath Buzurg",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612604,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400071,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 531083,
        "city": "Koruprolu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732140,
        "city": "Chanchal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689646,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742101,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635124,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 345002,
        "city": "Jaisalmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736156,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 201310,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 765020,
        "city": "Muniguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 470221,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 343041,
        "city": "Sanchore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303505,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 212663,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516321,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713419,
        "city": "Panagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577223,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635813,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 606103,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600045,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209307,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 466118,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 602003,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141125,
        "city": "Machhiwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621653,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521102,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 272149,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301409,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431804,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 411032,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 641667,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686538,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 132151,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781312,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 517641,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325201,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122098,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 757021,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184205,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 767021,
        "city": "Dunguripali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 784167,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 782442,
        "city": "Lanka",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 364740,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500013,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572140,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624053,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415411,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144020,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 193102,
        "city": "Baramulla",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212305,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517214,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444502,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 518145,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 460444,
        "city": "Betul",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 821302,
        "city": "Rohtas",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 147105,
        "city": "Patran",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462013,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 682002,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 713102,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635211,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243640,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 341035,
        "city": "Nagaur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 614716,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415530,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 248014,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785660,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 505526,
        "city": "Dharmapuri-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332713,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431812,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 614001,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365430,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 754114,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585106,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572214,
        "city": "Chikkanayakanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382007,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689114,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571428,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572133,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742104,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 460668,
        "city": "Athner",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 249151,
        "city": "Uttarkashi",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 805104,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585103,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625104,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303701,
        "city": "Kalwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721103,
        "city": "Midnapore",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 141419,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431127,
        "city": "Georai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 324001,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741172,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 242406,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670612,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 757002,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678552,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700010,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 825406,
        "city": "Chouparan",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123021,
        "city": "Narnaul",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680613,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415020,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 442101,
        "city": "Pulgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 585243,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691019,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 400113,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 517503,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572212,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781330,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 641607,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534342,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 424205,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 761214,
        "city": "Rayagada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500033,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 509125,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851119,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414403,
        "city": "Karjat-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 212659,
        "city": "Ghatampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226009,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743368,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516484,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713208,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 800001,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628007,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 638109,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465227,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 470124,
        "city": "Bina",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 765021,
        "city": "Muniguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 209203,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503321,
        "city": "Banswada",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431746,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 333801,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671323,
        "city": "Manjeshwar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 531061,
        "city": "Yellamanchili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177212,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175131,
        "city": "Manali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301404,
        "city": "Khairthal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607003,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 570019,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 410502,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 851202,
        "city": "Maheshkhunt",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 402111,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 604302,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845436,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571231,
        "city": "Shanivarasanthe",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767037,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670621,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 500093,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442604,
        "city": "Chamorshi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 731238,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743202,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 325003,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391151,
        "city": "Nasvadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413215,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621102,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721133,
        "city": "Belda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262550,
        "city": "Didihat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788004,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 636452,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388250,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 821103,
        "city": "Chainpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394320,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 711226,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396230,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 752087,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 281306,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 171215,
        "city": "Jubbal",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 140114,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673572,
        "city": "Narikkuni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 485336,
        "city": "Majhgawa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 524307,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516311,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631051,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560023,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783130,
        "city": "Boko",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 177020,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518007,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141017,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689723,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 484444,
        "city": "Kotma",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 480994,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 670672,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425123,
        "city": "Chopda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577546,
        "city": "Amarapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686653,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144506,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391410,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 560114,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621016,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571139,
        "city": "Hunsur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 423212,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 524322,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626607,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690001,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 518155,
        "city": "Allagadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605008,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781033,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 247777,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636457,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600124,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 341028,
        "city": "Khimsar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 211012,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581358,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522437,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761007,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591240,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781010,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 629304,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416606,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571313,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563146,
        "city": "Chintamani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643202,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 582102,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392115,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 360023,
        "city": "Chotila",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 311605,
        "city": "Mandalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786154,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 733149,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624803,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202146,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621315,
        "city": "Manapparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210434,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 670595,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 788702,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 410204,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 501508,
        "city": "Ibrahimpatnam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577517,
        "city": "Jagaluru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332022,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 232333,
        "city": "Gahmar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788834,
        "city": "Mahur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 400060,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627602,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801509,
        "city": "Patna",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 382480,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 695005,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 811307,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524317,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571314,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757019,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412301,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 383010,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635811,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 311303,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 643211,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688006,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 781326,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 824231,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531115,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387331,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 143002,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 362110,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 673534,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 148018,
        "city": "Nabha",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691324,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144524,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625013,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 756139,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 461111,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 767019,
        "city": "Binka",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721315,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560055,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678633,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 678595,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 450017,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 500057,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384270,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 246482,
        "city": "Tharali",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 795136,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 741502,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322232,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843330,
        "city": "Sonbarsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577534,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637101,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722133,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383434,
        "city": "Satlasana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 441808,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431106,
        "city": "Selu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 530053,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 761032,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 174315,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228118,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518018,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679348,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 635851,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342019,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793004,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 642201,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711405,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713323,
        "city": "Mejia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262533,
        "city": "Berinag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 414301,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271320,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 635118,
        "city": "Denkanikottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413208,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 844125,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 533211,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122224,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577529,
        "city": "Molakalmuru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605759,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416402,
        "city": "Jath",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 136021,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 327801,
        "city": "Kushalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625517,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573137,
        "city": "Shanivarasanthe",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603003,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 561205,
        "city": "Doddaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518502,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 249175,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523003,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 425403,
        "city": "Shindkheda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 224401,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 581110,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110124,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 700057,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841407,
        "city": "Mohammadpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400041,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 620026,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 637211,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441212,
        "city": "Mul",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 756049,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521002,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785667,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 132157,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303323,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230125,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263638,
        "city": "Someshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 609601,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 793008,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 445101,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 142027,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144305,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752114,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788014,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 326021,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416604,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581172,
        "city": "Haveri",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 614015,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711404,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 227411,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122021,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 233233,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148048,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517172,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560082,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 671324,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 204215,
        "city": "Sikandra Rao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272126,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360030,
        "city": "Gondal",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 673644,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 683105,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 760006,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388410,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802116,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389180,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 143507,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 326003,
        "city": "Aklera",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691623,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 670631,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 638056,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396439,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 676106,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457888,
        "city": "Alirajpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 741139,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626140,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276121,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 395650,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141122,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 226028,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 272208,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713434,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629205,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 202125,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 227101,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571419,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585320,
        "city": "Sedam",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571215,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571189,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680651,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444401,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 735236,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 284306,
        "city": "Poonchh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210120,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531024,
        "city": "Paderu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303340,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263149,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686693,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571433,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 450554,
        "city": "Sanawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754025,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 801113,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532426,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 242222,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786190,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 481662,
        "city": "Tikariya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 503001,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781325,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 233002,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523227,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509408,
        "city": "Kadthal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785640,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 523291,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761133,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415114,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 534239,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613004,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781037,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 586201,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620011,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 797108,
        "city": "Phek",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 394327,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 581357,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 753006,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 516217,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486888,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 737102,
        "city": "Gangtok",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 532185,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421304,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400066,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 415022,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686003,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 311405,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 152002,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560083,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 410506,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 612206,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 314025,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533003,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712222,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502113,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600132,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 641029,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 321402,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632520,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 490024,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 573116,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628616,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788735,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 361150,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 785615,
        "city": "Bokakhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 785695,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743304,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689105,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743395,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695014,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 284302,
        "city": "Poonchh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700015,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 626549,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742201,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176320,
        "city": "Sundla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690551,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 331803,
        "city": "Shri Dungargarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 331804,
        "city": "Shri Dungargarh",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 227811,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845432,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416413,
        "city": "Jath",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 182122,
        "city": "Ramnagar-JK",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202411,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 477001,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 759013,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577132,
        "city": "Mudigere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246745,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686615,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 456224,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 282001,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 631210,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614602,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742164,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786186,
        "city": "Digboi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 441910,
        "city": "Pauni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 248141,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623109,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370645,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431510,
        "city": "Mantha",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 691002,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 412308,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 795140,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 171006,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515405,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623712,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734002,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 845413,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690530,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757047,
        "city": "Jashipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586114,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221301,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 284404,
        "city": "Mahrauni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276303,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757024,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 172030,
        "city": "Kumarsain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721427,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793200,
        "city": "Khliehriat",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 424209,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442111,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 402102,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 757026,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 205264,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788013,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 209738,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695141,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 143407,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516128,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 445322,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 122103,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144532,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683522,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 532219,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 389347,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 495118,
        "city": "Pendra",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 847124,
        "city": "Bharwara",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 721464,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759129,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248146,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 770049,
        "city": "Rourkela",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 670591,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 826124,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382225,
        "city": "Dholka",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 250601,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609311,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691304,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571325,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247773,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815317,
        "city": "Tisri",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 201009,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416528,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 854339,
        "city": "Birpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 573124,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413714,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 604001,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803114,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682506,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 451113,
        "city": "Sanawad",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577216,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302016,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229001,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244304,
        "city": "Sambhal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518523,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387710,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 632055,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122225,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110081,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 713347,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416515,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 211004,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176073,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630559,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221409,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641664,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506370,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 177006,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 331411,
        "city": "Sardarshahr",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 496330,
        "city": "Jashpur Nagar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 574217,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160025,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 764039,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 502314,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518563,
        "city": "Sirvella",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411034,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 473113,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 765033,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691590,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110045,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 600067,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670504,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 760010,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110506,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 742151,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670566,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403506,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271609,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424105,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 523305,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465230,
        "city": "Nalkheda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 385506,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 591108,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682509,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713149,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 601201,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171019,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673328,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 574211,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824121,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 607804,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673506,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 517415,
        "city": "Venkatagirikota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229127,
        "city": "Salon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 804431,
        "city": "Hilsa",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 562135,
        "city": "Devanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244602,
        "city": "Thakurdwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673509,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636704,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 424006,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 752111,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502325,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741125,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271203,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242223,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638457,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695143,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416525,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 733150,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 600033,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 322212,
        "city": "Nadoti",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454552,
        "city": "Maheshwar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 581116,
        "city": "Hirekerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583222,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629402,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638459,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 454010,
        "city": "Dhar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 225302,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174310,
        "city": "Naina Devi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505501,
        "city": "Choppadandi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733129,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712157,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387810,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 622201,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712704,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411068,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 494669,
        "city": "Bhanupratappur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 394360,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 423704,
        "city": "Vaijapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 410512,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 241127,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535526,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843115,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332408,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624617,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560003,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 443101,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 723130,
        "city": "Hura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262907,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575006,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 152033,
        "city": "Jalalabad",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144004,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246446,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141021,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516175,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364465,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 473118,
        "city": "Binaganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 143516,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574264,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142041,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416803,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673611,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 591238,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 794112,
        "city": "Resubelpara",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 475336,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 121006,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743130,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 182121,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384260,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416805,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 586117,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835222,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 123506,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 711203,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621107,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743101,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230146,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 791001,
        "city": "Aalo",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 477332,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 606306,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402309,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 229413,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518186,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 450992,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 413201,
        "city": "Jamkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682507,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577599,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532001,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755023,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515002,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160066,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444900,
        "city": "Morshi",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 244501,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394680,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 424002,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 495115,
        "city": "Mungeli",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 560108,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713301,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689642,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 606303,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700048,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 225131,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 422602,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678731,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 412111,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110075,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 682505,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 495687,
        "city": "Sakti",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 844128,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422308,
        "city": "Lasalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 828301,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522308,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506007,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630551,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272163,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 580213,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560027,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227817,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403523,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 533001,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795004,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 387510,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 841216,
        "city": "Dighwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643241,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205266,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 627113,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221205,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 208001,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754014,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431125,
        "city": "Beed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 679576,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802212,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576225,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 341318,
        "city": "Khatu Khurd",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 231209,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 753027,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 132023,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695026,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416144,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584139,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689587,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 814118,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 848505,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 847229,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632517,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 208003,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 803123,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 444604,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 246449,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 477441,
        "city": "Lahar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 573111,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 832108,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301406,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584147,
        "city": "Manvi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387421,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 494670,
        "city": "Bhanupratappur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 442505,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 767068,
        "city": "Loisingha",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574232,
        "city": "Panja",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 191201,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141115,
        "city": "Machhiwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743706,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600027,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414106,
        "city": "Pathardi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502210,
        "city": "Mominpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784125,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 500098,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630202,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143104,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403515,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273163,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607307,
        "city": "Cuddalore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813109,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577526,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712156,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250342,
        "city": "Daurala",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361008,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 322243,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782140,
        "city": "Puranaigudam",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 632504,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370170,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 805131,
        "city": "Wazirganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686620,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632326,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679575,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 245409,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176223,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 782435,
        "city": "Hojai",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 688008,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600043,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 134001,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752054,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 504107,
        "city": "Navipet",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 800020,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 362265,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 560045,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609504,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 610205,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431204,
        "city": "Partur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571321,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517002,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144034,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577548,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534124,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140133,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321616,
        "city": "Mahwa",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 410208,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 799105,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 561212,
        "city": "Bagepalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761026,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695303,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 764021,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641062,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 262122,
        "city": "Puranpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784506,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 413707,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141013,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505528,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686614,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 364060,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 209302,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 612905,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140412,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577418,
        "city": "Hosanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591324,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734007,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403102,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571457,
        "city": "Hanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 453771,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 242226,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176096,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 613105,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334801,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 322022,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609309,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384151,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 413715,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 396430,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 401607,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628753,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411057,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110019,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 133004,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 825312,
        "city": "Bagodar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691503,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416421,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577552,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733128,
        "city": "Itahar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642052,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741165,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141108,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721162,
        "city": "Haldia",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 803215,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 244901,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 307515,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 854344,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 148047,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 471408,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 474042,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 522010,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679106,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641012,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 226012,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 272175,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403406,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 609302,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401016,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 442503,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 222144,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507136,
        "city": "Tadvai",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 608703,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695015,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 623312,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854304,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 835303,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 824118,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 224146,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530016,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783126,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 623403,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502296,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743125,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141113,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400027,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 700121,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 788012,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 577596,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160068,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517645,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670564,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 431801,
        "city": "Bhokar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441404,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 122011,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444110,
        "city": "Murtizapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 534401,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 602103,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614620,
        "city": "Manamelkudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743252,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221115,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591103,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396469,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 742181,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322215,
        "city": "Nadoti",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600106,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 311602,
        "city": "Mandalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572160,
        "city": "Tumkur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 603310,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828401,
        "city": "Gomoh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360480,
        "city": "Jamjodhpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 523302,
        "city": "Martur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248011,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754219,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695614,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515763,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680009,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 560113,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 733213,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695017,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 182148,
        "city": "Ramban",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 174405,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 135002,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 311001,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591153,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686505,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 508258,
        "city": "Devarakonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 471510,
        "city": "Lavkush Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 573210,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534351,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 206243,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312604,
        "city": "Chhoti Sadri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 613108,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413133,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 857225,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531060,
        "city": "Yellamanchili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581212,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 475110,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 848114,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625404,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 821113,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695612,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843311,
        "city": "Runnisaidpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248004,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431745,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695145,
        "city": "Parippally",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757054,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 637019,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271123,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517247,
        "city": "Punganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174201,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 494005,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 685602,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515541,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342026,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342028,
        "city": "Balesar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799005,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 202417,
        "city": "Shahabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712235,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144012,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203133,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 518513,
        "city": "Velgodu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262501,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583136,
        "city": "Kotturu",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811309,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507118,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442913,
        "city": "Chimur",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 387130,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271302,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 471411,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 523273,
        "city": "Kondapi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844122,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303706,
        "city": "Kalwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110117,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 591109,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517646,
        "city": "Tada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 495005,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 570023,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841242,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425327,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 500016,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 171225,
        "city": "Kotkhai",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623705,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695302,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 281504,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503202,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 321204,
        "city": "Kaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585211,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144802,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 460002,
        "city": "Betul",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 802114,
        "city": "Rajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680722,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 450116,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 424208,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521207,
        "city": "Nuzvid",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394633,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 590014,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621206,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851115,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 391535,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600005,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741154,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845433,
        "city": "Raxaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 723212,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624709,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413317,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686141,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 573202,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671348,
        "city": "Manjeshwar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 626571,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785630,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 303324,
        "city": "Bandikui",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 755032,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143504,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515003,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 311302,
        "city": "Asind",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209865,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607108,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431215,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 412216,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364540,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700004,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680514,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 814112,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 587115,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271835,
        "city": "Ikauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400009,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571235,
        "city": "Shanivarasanthe",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606207,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175128,
        "city": "Patlikuhal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361142,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 230143,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394515,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 691302,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 341316,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 325220,
        "city": "Kawai",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636114,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560004,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 311802,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605001,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 360380,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635803,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518217,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680325,
        "city": "Chalakudy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 602024,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144527,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623135,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562120,
        "city": "Magadi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 582216,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625526,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799131,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 827014,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414113,
        "city": "Pathardi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 751012,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388310,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 487330,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 285203,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391520,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 401201,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 586209,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534227,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221003,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606710,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604504,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641652,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522354,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391460,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689574,
        "city": "Haripad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600041,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 496336,
        "city": "Kusmi",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 401501,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 713130,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 456665,
        "city": "Tarana",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 570002,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 182320,
        "city": "Katra",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370421,
        "city": "Mundra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 396193,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 641049,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400096,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670613,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 841214,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625109,
        "city": "Melur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281501,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533430,
        "city": "Yerravaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 490023,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 676104,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 492104,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673304,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743374,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122203,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 629808,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122101,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 212104,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637504,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 477117,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 530052,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384470,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 743711,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824207,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741313,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600095,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 276136,
        "city": "Jiyanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383317,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680010,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 560078,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 847337,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 501142,
        "city": "Tandur-TL",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690534,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144419,
        "city": "Phillaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416207,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 535463,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741103,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332721,
        "city": "Khandela",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 828110,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623402,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415718,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591310,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638004,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 207122,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 142056,
        "city": "Bagha purana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362263,
        "city": "Mendarda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 506330,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673589,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 799101,
        "city": "Amarpur TP",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 626002,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246469,
        "city": "Ukhimath",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 796901,
        "city": "Siaha",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 445306,
        "city": "Ghatanji",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 766028,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585421,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530018,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144303,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722183,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272194,
        "city": "Rudhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847405,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360110,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 604153,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441805,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 805126,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632319,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734405,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312024,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685584,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 393016,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 413520,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 636011,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122206,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581101,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757020,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591510,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382024,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689531,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457227,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 813223,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 671544,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 690505,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 831007,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523280,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 765023,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721134,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534275,
        "city": "Narsapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414605,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 796410,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 229121,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416117,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 508221,
        "city": "Tungaturthi",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413503,
        "city": "Bhoom",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 732206,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400091,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388365,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 713128,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321613,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713369,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502278,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722168,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 811214,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761103,
        "city": "Hinjilicut",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 325204,
        "city": "Sultanpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 461551,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781318,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 497449,
        "city": "Chirimiri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 500087,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 263159,
        "city": "Ramnagar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174102,
        "city": "Kunihar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230129,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506009,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 831019,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 688557,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144619,
        "city": "Begowal",
        "state": "Punjab",
        "Zone": "N2"
       },
       {
        "pincode": 732103,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 629002,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 221106,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400036,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 455223,
        "city": "Chapda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 821312,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 384275,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 443406,
        "city": "Jalgaon Jamod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 518583,
        "city": "Sirvella",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501509,
        "city": "Yacharam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767065,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627804,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421204,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 508253,
        "city": "Choutuppal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400410,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 485773,
        "city": "Maihar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 531029,
        "city": "Paderu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613602,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 211017,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 464986,
        "city": "Raisen",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 388640,
        "city": "Khambhat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802209,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689612,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 786611,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 516233,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612805,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413526,
        "city": "Bhoom",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 124142,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 122009,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 342304,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623534,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600088,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743446,
        "city": "Hingalganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301424,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761209,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743298,
        "city": "Helencha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614016,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344021,
        "city": "Siwana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627417,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756019,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411067,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 504207,
        "city": "Luxettipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585301,
        "city": "Afzalpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313004,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700102,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742109,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422606,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621221,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722101,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124201,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587111,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580003,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 562111,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577004,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 587301,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125201,
        "city": "Odhan",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125001,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400069,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 691510,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 804420,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695301,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 516350,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271125,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303120,
        "city": "Achrol",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 208018,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680316,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585331,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600085,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577179,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621006,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574285,
        "city": "Kadaba",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678624,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 803107,
        "city": "Barbigha",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754233,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638314,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333048,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577527,
        "city": "Hosadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785627,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 742123,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841502,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 741155,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785613,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 442703,
        "city": "Aheri",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 177108,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845412,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212105,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500890,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 283110,
        "city": "Kirauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 204104,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 314011,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383340,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 473111,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 769016,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521122,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231303,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413004,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 132105,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495551,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 612106,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313328,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 604101,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444911,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 173230,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673527,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 587119,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431112,
        "city": "Sillod",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 758084,
        "city": "Pallahara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 182104,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786189,
        "city": "Chabua",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 505468,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 454350,
        "city": "Dhar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 441901,
        "city": "Sadak Arjuni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 246722,
        "city": "Dhampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711314,
        "city": "Bagnan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124411,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518308,
        "city": "Adoni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396192,
        "city": "Silvassa-DNH",
        "state": "Dadra and Nagar Haveli",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 712101,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638668,
        "city": "Annur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321305,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574244,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782402,
        "city": "Jorabat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 122107,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583218,
        "city": "Kudligi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 251003,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222133,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605604,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799201,
        "city": "Khowai",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 577228,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201312,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843112,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421301,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 680302,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 609503,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 833212,
        "city": "Jagannathpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624711,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627808,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583128,
        "city": "Kudligi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712147,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 455118,
        "city": "Dewas",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 638101,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641697,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322028,
        "city": "Bonli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515281,
        "city": "Amarapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413527,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 335511,
        "city": "Bhadra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582117,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 341303,
        "city": "Didwana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 392160,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 384205,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 134117,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 423102,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 680612,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 591143,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621014,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625601,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480106,
        "city": "Sausar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 403109,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144703,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585316,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202135,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273151,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533107,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843139,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403804,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 534204,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534269,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 853204,
        "city": "Naugachia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643203,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743354,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423703,
        "city": "Vaijapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691540,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 761144,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303602,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591346,
        "city": "Gokak",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 229402,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574198,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571812,
        "city": "Krishnarajpet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177117,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712403,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 732201,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416317,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 628751,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410005,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 361210,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 461110,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 759151,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 722203,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688007,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 241404,
        "city": "Pihani",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151203,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711115,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 202412,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389157,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 202121,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630713,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416610,
        "city": "Kankavali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 606602,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322220,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591313,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639201,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847237,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 281006,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431146,
        "city": "Kannad",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 577230,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401603,
        "city": "Kudus",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 249402,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142045,
        "city": "Bagha purana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 126113,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505301,
        "city": "Sircilla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689662,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 741317,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 474003,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 465110,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673008,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 686585,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 632013,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689549,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635809,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632053,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500086,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741402,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 182101,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734502,
        "city": "Kalimpong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 586104,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506368,
        "city": "Danthalapally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224227,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829129,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382423,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 734209,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712419,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781128,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 570022,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 364260,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 306308,
        "city": "Bilara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623309,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620004,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 177030,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 171223,
        "city": "Rohru",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 584118,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643201,
        "city": "Kotagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848127,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 342802,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388630,
        "city": "Khambhat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 483501,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 175022,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 590002,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802135,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606752,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484330,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 827004,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416404,
        "city": "Jath",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 370475,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 508105,
        "city": "Alair",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695018,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 123505,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 332317,
        "city": "Laxmangarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722142,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686541,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670592,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 226401,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700131,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508355,
        "city": "Miryalaguda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362135,
        "city": "Talala",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 627502,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670004,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 852114,
        "city": "Madhepura",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388211,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 575026,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627434,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387120,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 277204,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583229,
        "city": "Sindhanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584130,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606907,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576227,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466666,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 486675,
        "city": "Amiliya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 302014,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262724,
        "city": "Dhaurahara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246728,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691500,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 722141,
        "city": "Mirzapur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731303,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500457,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 607801,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457001,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 626540,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680320,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 301017,
        "city": "Tijara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 828113,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212621,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741190,
        "city": "Chapra",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 415401,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431715,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415015,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 781353,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 571455,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680642,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 271806,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250401,
        "city": "Mawana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210421,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625016,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 233232,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636304,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243636,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133003,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781355,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 177029,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731237,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262801,
        "city": "Gola",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 643221,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742163,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 604401,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209502,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741401,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177033,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 825408,
        "city": "Chouparan",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 755028,
        "city": "SUKINDA",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 396403,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 396050,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 788712,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 516130,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302002,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627102,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686581,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781374,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 534004,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506224,
        "city": "Narmetta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516193,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 363421,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 621601,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416514,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 603311,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606001,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332714,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788108,
        "city": "Udarbond",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 251314,
        "city": "Khatauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781367,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 844118,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341505,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814157,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 843143,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509302,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229202,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530007,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581141,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 233311,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757017,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506365,
        "city": "Rayaparthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140115,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 496004,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 842008,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412210,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 230503,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151007,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695039,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 643217,
        "city": "Kotagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143117,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508112,
        "city": "Ramannapeta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516360,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785011,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 458220,
        "city": "Jawad",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 422305,
        "city": "Lasalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 670011,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 574138,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622412,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522017,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614738,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781020,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 141421,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403409,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571454,
        "city": "Krishnarajpet",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 206123,
        "city": "Kishni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246732,
        "city": "Najibabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 804402,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506302,
        "city": "Thirumalagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766100,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713308,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642003,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413221,
        "city": "Mandrup",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 493881,
        "city": "Abhanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754204,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 801305,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788733,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 222304,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 516401,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637206,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390012,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 732001,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 825319,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480991,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 458880,
        "city": "Garoth",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 148029,
        "city": "Sunam",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516364,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465550,
        "city": "Alote",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 393140,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224164,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465226,
        "city": "Nalkheda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 700113,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 796261,
        "city": "Saitual",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 361005,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 629403,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799014,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 678014,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 636407,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 518134,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272301,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332305,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670109,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 470675,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 828132,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577540,
        "city": "Molakalmuru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847407,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695542,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743222,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360575,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 221305,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686039,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 480555,
        "city": "Junnardeo",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 843031,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571121,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462025,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 508238,
        "city": "Kodad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670671,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802214,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 676507,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332705,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 204105,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402109,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 755042,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 795116,
        "city": "Jiribam",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 534327,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577217,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274807,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 172105,
        "city": "Kinnaur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396450,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 712611,
        "city": "Kamarpukur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689694,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 389146,
        "city": "Limkheda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416504,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444906,
        "city": "Warud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 326023,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380005,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 332742,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 392240,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416620,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 327025,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110056,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 333031,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695020,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 224123,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713212,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 620102,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534165,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509335,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690520,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 841211,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504306,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 324008,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141016,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574139,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 822118,
        "city": "Bisrampur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577597,
        "city": "Hosadurga",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600029,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505212,
        "city": "Manthani",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691525,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 249304,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363001,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 825304,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 787055,
        "city": "Dhakuakhana",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 518397,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303328,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480337,
        "city": "Pandhurna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 271823,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 722150,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 492001,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144104,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535101,
        "city": "Garividi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 471516,
        "city": "Lavkush Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416011,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 274403,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 679513,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 208021,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144508,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695021,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 522237,
        "city": "Tadikonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322701,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621806,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244716,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636601,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177022,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 813107,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 831011,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 322219,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560017,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 477227,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 383307,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 422203,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 152020,
        "city": "Fazilka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 325009,
        "city": "Itawa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673519,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 783132,
        "city": "Lakhipur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 679122,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 221506,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273409,
        "city": "Sikriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 327002,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627425,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201309,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388610,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 581351,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531025,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609308,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 162630,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144422,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758080,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686583,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788104,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 821118,
        "city": "Thakurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303807,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785602,
        "city": "Sarupathar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627119,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828126,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 825324,
        "city": "Barki Saria",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263655,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 732202,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388625,
        "city": "Khambhat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 825101,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609701,
        "city": "Peralam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622504,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574240,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686034,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 281406,
        "city": "Vrindavan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 643006,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 389001,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678575,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 580032,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 523271,
        "city": "Kondapi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700137,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628002,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143417,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382245,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 364003,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 303503,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686121,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176311,
        "city": "Bharmour",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 823311,
        "city": "Khizasarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110110,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 572138,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173201,
        "city": "Baddi",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 393005,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 532292,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741171,
        "city": "Chapra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 604402,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713206,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690570,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382041,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 302009,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 442302,
        "city": "Pulgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 591126,
        "city": "Saundatti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 823004,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 788724,
        "city": "Patharkandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627805,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507123,
        "city": "Yellandu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581197,
        "city": "Hangal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 829102,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700162,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110025,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 690518,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700127,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600091,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 765029,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360579,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721644,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 484555,
        "city": "Pali Birsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 388350,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 341317,
        "city": "Didwana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415122,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 383350,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 832102,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518674,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441611,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 844102,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246768,
        "city": "Najibabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516151,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522313,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722147,
        "city": "Bishnupur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394140,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 262701,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642125,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680125,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756123,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246166,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388580,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 570003,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243301,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680665,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 274249,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 711310,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 277203,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624207,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768026,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 363115,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521246,
        "city": "Vuyyuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 485775,
        "city": "Amarpatan",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 177101,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584114,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412219,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627009,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614712,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613402,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523327,
        "city": "Yerragonda Palem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600017,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534195,
        "city": "Ganapavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754016,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571127,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585303,
        "city": "Jewargi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848136,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638701,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521256,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722148,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670614,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 641004,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695507,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 612401,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783361,
        "city": "Gossaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 612801,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 343049,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799009,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 577541,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110086,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 413101,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 175005,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 306107,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364290,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 610201,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768201,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 311023,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 813110,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 271603,
        "city": "Itia Thok",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272123,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535578,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176061,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110073,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 415004,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 414102,
        "city": "Pathardi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517129,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132108,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521327,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642123,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 389230,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 360021,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 125103,
        "city": "Odhan",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581356,
        "city": "Haliyal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673576,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631702,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131303,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 723158,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761035,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781335,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 835238,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679532,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 606811,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852129,
        "city": "Sonbarsa-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403001,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 421201,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416707,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 804432,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721650,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 443112,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841236,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 134205,
        "city": "Naraingarh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713514,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670142,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 509215,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272130,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 460557,
        "city": "Athner",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 383320,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 734013,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784524,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 700149,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431537,
        "city": "Parbhani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574112,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523230,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781354,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 621215,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585318,
        "city": "Sedam",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584124,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530045,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843156,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 312901,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242407,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 816129,
        "city": "Rajmahal",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 370430,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 328040,
        "city": "Dholpur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 632515,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638104,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 502271,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685614,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 476339,
        "city": "Sheopur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 583125,
        "city": "Harpanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470663,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700074,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272161,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263132,
        "city": "Bhimtal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741236,
        "city": "Chapra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 244227,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400093,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 622203,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683545,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 142001,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 805129,
        "city": "Wazirganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721441,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140408,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743338,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799254,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 522261,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532123,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811304,
        "city": "Sheikhpura",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752066,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416526,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 670679,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 560090,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230128,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523272,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624701,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782002,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743702,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 711402,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 757045,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854103,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583282,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 833104,
        "city": "Manoharpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841413,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 460440,
        "city": "Sarni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 689124,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581345,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600108,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622409,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 791113,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 636006,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 247129,
        "city": "Behat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462032,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 383316,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 845301,
        "city": "Raxaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 742140,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175028,
        "city": "Chachyot",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 811212,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571417,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160051,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 829301,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360330,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 824217,
        "city": "Imamganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534216,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795106,
        "city": "Senapati",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 431750,
        "city": "Vasmat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392230,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 690522,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403725,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 751028,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741223,
        "city": "Gopalnagar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 610003,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721463,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 732139,
        "city": "Chanchal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 142037,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585214,
        "city": "Gurmatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679551,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 508246,
        "city": "Huzur Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532430,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125075,
        "city": "Ellenabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736179,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370202,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585218,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 363660,
        "city": "Morbi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 370485,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635653,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 769008,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 410505,
        "city": "Rajgurunagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431123,
        "city": "Kaij",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 363765,
        "city": "Viramgam",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 365455,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 689572,
        "city": "Haripad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 248121,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 493887,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 786125,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523115,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580008,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144502,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 828304,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 524305,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785675,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 424103,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 497229,
        "city": "Surajapur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 182145,
        "city": "Ramsoo",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 261145,
        "city": "Biswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140128,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413106,
        "city": "Indapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263156,
        "city": "Bhimtal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 561206,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515591,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848214,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 392021,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 335023,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175010,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624401,
        "city": "Natham",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415406,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 766015,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591111,
        "city": "Saundatti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742238,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143512,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144529,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609301,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682038,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 695031,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768116,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605802,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335504,
        "city": "Bhadra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 245407,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788117,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 532461,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401200,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 586213,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603312,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785004,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 680590,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 261402,
        "city": "Mishrikh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244004,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606801,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396370,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 243202,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413220,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 530005,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627357,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502255,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848138,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600123,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 496227,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 125048,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509206,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173235,
        "city": "Darlaghat",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502279,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173206,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 561101,
        "city": "Dabaspete",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142057,
        "city": "Bagha purana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673106,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 816108,
        "city": "Rajmahal",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144214,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 831015,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799003,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 583115,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782124,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 769005,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403709,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144041,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 385545,
        "city": "Dhanera",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 605101,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 735211,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691516,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756082,
        "city": "Basta",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 277504,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224223,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788162,
        "city": "Lalapur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 382121,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585219,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141411,
        "city": "Mandi Gobindgarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 173209,
        "city": "Dharampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462007,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 416418,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 245206,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 671545,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680008,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 224135,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 804406,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623105,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 458660,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 843125,
        "city": "Sahebganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624051,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609604,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500059,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626118,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577225,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132113,
        "city": "Safidon",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388355,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 782139,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 811314,
        "city": "Jhajha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400035,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 678541,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 685616,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 682011,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 642101,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486553,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522024,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691556,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 851102,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627201,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754107,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 610101,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585260,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413529,
        "city": "Ahmadpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 824301,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 755015,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387230,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 212208,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721467,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 800014,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522626,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205135,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852116,
        "city": "Udakishanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754026,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441209,
        "city": "Kurkheda",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686661,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500029,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 314021,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144005,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 332722,
        "city": "Khandela",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502001,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535148,
        "city": "S Kota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221002,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 475671,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 171201,
        "city": "Theog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841219,
        "city": "Dighwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 764028,
        "city": "Tikiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388480,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783384,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 518003,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 246733,
        "city": "Dhampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736177,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 734121,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581132,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754287,
        "city": "Salipur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 832404,
        "city": "Chandil",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 731254,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410402,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 516116,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752064,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 755035,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 847101,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670543,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600040,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141011,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574115,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 328028,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848117,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387540,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 711312,
        "city": "Bagnan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 225414,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583225,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583102,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626103,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415109,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515110,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680688,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415522,
        "city": "Phaltan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 480001,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 121004,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630003,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416116,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 752026,
        "city": "Gopalapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713127,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756044,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502375,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 445002,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 365635,
        "city": "Savarkundla",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 735101,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700032,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 271402,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625604,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829122,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767067,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680589,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571120,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625103,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622202,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423109,
        "city": "Shirdi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 641303,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733133,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 607004,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414303,
        "city": "Parner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 458664,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 831018,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 457882,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 411038,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 600127,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 422004,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 222302,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148108,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305023,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 244902,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854328,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581112,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391230,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 509132,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182124,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144210,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209601,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244701,
        "city": "Milak",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621022,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695030,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 636013,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713377,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585225,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736207,
        "city": "Barobisha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691016,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 209732,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508210,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843128,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743384,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 283102,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382020,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680735,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 833201,
        "city": "Chaibasa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382820,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 508113,
        "city": "Ramannapeta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757040,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500092,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 731121,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532122,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400013,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 755036,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670677,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 754288,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122004,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682005,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 175015,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400704,
        "city": "Jasai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 688562,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 505305,
        "city": "Yellareddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365640,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 232327,
        "city": "Gahmar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689110,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 535216,
        "city": "Bhogapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 282006,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 484336,
        "city": "Kotma",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 175123,
        "city": "Banjar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 143009,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247121,
        "city": "Behat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244414,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 248102,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212601,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 676508,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788156,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 488059,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 768106,
        "city": "Rairakhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628204,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205304,
        "city": "Kishni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680502,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 243635,
        "city": "Dataganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627852,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605105,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 731243,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713215,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522113,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473583,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713141,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360034,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 679502,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 402601,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 395010,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 422214,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 689711,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560060,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721666,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 325004,
        "city": "Itawa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788151,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 679341,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 683548,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 483334,
        "city": "Sihora",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 686503,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 125110,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243005,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571427,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140124,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410221,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 201311,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 134202,
        "city": "Naraingarh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380058,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 394246,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 828120,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571217,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638703,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177048,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 208026,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 497557,
        "city": "Chirimiri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577247,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722222,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362003,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 522312,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 474010,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 313705,
        "city": "Gogunda",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 457770,
        "city": "Petlawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 560019,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122207,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678703,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 851126,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 458778,
        "city": "Bhanpura",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416022,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 454341,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 495116,
        "city": "Ratanpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 303902,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382425,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 331304,
        "city": "Taranagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400901,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 613010,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400006,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 683549,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585418,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814165,
        "city": "Mahagama",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416509,
        "city": "Halkarni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 456661,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 625006,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 222102,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680555,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600066,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 430507,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 224118,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176218,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271319,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755050,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574230,
        "city": "Kadaba",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 395023,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 455440,
        "city": "Khategaon",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 271405,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 781021,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 534450,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 794003,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 143501,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522601,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202120,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273308,
        "city": "Nautanwa",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276402,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768110,
        "city": "Pallahara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581136,
        "city": "Byadagi",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 784118,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 385530,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 841415,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 735134,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574106,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 495223,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 535559,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 249409,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 796310,
        "city": "Khawzawl",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 533287,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630560,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680667,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 208027,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 344702,
        "city": "Dhorimana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431601,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 764001,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625207,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517123,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507124,
        "city": "Yellandu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425411,
        "city": "Nandurbar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305927,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313704,
        "city": "Gogunda",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574199,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 228132,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 453552,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 581350,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625603,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742133,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577142,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784111,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 628151,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625014,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 425304,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 486114,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 360001,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680517,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 613203,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507203,
        "city": "Madhira",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533274,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690504,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 752013,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 532127,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577118,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416014,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 787034,
        "city": "Gogamukh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 711412,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600038,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583122,
        "city": "Siruguppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585304,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505529,
        "city": "Jagtial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496111,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 723156,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 465677,
        "city": "Maksudangarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 486333,
        "city": "Deotalab",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 458113,
        "city": "Manasa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 464886,
        "city": "Silwani",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 768020,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742235,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841287,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 306706,
        "city": "Falna",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768130,
        "city": "Sambalpur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 845427,
        "city": "Dhaka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743144,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752029,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 610105,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364295,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 250612,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632315,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623719,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713142,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 171301,
        "city": "Suni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759022,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 824234,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 277213,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442103,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 572145,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754138,
        "city": "Tirtol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517590,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486341,
        "city": "Deotalab",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 812002,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500067,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 394310,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 743348,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695036,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577545,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743609,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 757077,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700086,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621708,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505530,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518216,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480115,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 303906,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721426,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 224137,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506166,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230405,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845431,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700038,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761017,
        "city": "Mohana",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700077,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 630203,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444720,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518593,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571114,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401205,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 461771,
        "city": "Pipariya",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 303330,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431505,
        "city": "Selu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521213,
        "city": "Vissannapetaa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685508,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 365541,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 365480,
        "city": "Bhesan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 410211,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743292,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 231222,
        "city": "Anpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224186,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143005,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606906,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403707,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 505152,
        "city": "Peddapalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735220,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517432,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 832119,
        "city": "Seraikella",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226025,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531032,
        "city": "Anakapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713145,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271129,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802132,
        "city": "Nuaon",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759026,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212666,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 331501,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 320006,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756144,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625051,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 495674,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 679324,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396418,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 444001,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 781340,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 425104,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795005,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 416611,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 742402,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382625,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 233310,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143406,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591139,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785688,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 754205,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600073,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577512,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281304,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 745010,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 261206,
        "city": "Biswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 443103,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 688527,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 424104,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392165,
        "city": "Dahej",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 382213,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 767041,
        "city": "Patnagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734217,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 174305,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 491222,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 425132,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 620015,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622402,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365410,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 799275,
        "city": "Aambasa",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 700025,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625114,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625002,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 301704,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440028,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 303331,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752084,
        "city": "Daspalla",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 490042,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 457772,
        "city": "Petlawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 173210,
        "city": "Dharampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110090,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680734,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 327605,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480221,
        "city": "Amarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 284135,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713422,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 801503,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574226,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 193404,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 401406,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 784145,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 364240,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 210126,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574213,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380027,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 370140,
        "city": "Bhachau",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 180020,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 384335,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 845105,
        "city": "Bagaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413117,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 801501,
        "city": "Bihta",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508374,
        "city": "Madgulapally",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 110067,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 272131,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202524,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424106,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 762100,
        "city": "G. Udayagiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784184,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 403803,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 688507,
        "city": "Alappuzha",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 676562,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847228,
        "city": "Basopatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415801,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 562164,
        "city": "Chikkaballapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689512,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415605,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 534198,
        "city": "Ganapavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176063,
        "city": "Baijnath",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521185,
        "city": "Nandigama",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388122,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 226008,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 582234,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676557,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 276128,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 277301,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174307,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581343,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147002,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 122055,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 620005,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721669,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382210,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680617,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502318,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679124,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 622104,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431209,
        "city": "Partur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500265,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574160,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321404,
        "city": "Rupbas",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274604,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673574,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416113,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 126115,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384140,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678705,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 752069,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 302019,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 854331,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686561,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 799120,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 844506,
        "city": "Mahnar Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413306,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627810,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803115,
        "city": "Pavapuri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623006,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762112,
        "city": "G. Udayagiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 387640,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 385521,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 848303,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271006,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 484886,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 393010,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721442,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576215,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754241,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 474006,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 243723,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 734223,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632602,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274802,
        "city": "Khadda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712204,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140119,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464551,
        "city": "Raisen",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 533468,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442102,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678671,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 825420,
        "city": "Godabar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 313702,
        "city": "Jhadol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712232,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 622101,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175133,
        "city": "Keylong",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678574,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 462005,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 782144,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 805105,
        "city": "Pavapuri",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673005,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571439,
        "city": "Hanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795145,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 848512,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625524,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364280,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400208,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521120,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 701111,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695024,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 441121,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515872,
        "city": "Molakalmuru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400103,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515843,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609001,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 797112,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 212402,
        "city": "Phulpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636105,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624619,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691506,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721506,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683107,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 391445,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686633,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700006,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416303,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382404,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 247668,
        "city": "Roorkee",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 813103,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 231501,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413305,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 829149,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 273303,
        "city": "Maharajganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534331,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642109,
        "city": "Madukkarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766016,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577005,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 307021,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 766118,
        "city": "Sinapali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 392163,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 518101,
        "city": "Srisailam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516310,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516203,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585327,
        "city": "Basavakalyan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781347,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743336,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517422,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637105,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686666,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 414101,
        "city": "Karjat-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515521,
        "city": "Galiveedu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688539,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 825322,
        "city": "Barki Saria",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495450,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 508243,
        "city": "Devarakonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 587101,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577139,
        "city": "Sringeri",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442303,
        "city": "Pulgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 302031,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673313,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 277202,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630410,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742405,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274808,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 472339,
        "city": "Tikamgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 208002,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144507,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 132024,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177203,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534240,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600013,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160006,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712203,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635206,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144603,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502102,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247122,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301034,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 515305,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782461,
        "city": "Diphu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 534199,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686018,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 442304,
        "city": "Hinganghat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 360410,
        "city": "Dhoraji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 283201,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176077,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 421308,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 600054,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382008,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 441811,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521328,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143606,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362225,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 207503,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177114,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788111,
        "city": "Udarbond",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 231210,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670331,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 248012,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416004,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 224198,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533309,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574141,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752030,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783383,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 759037,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685612,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 143109,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752068,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635807,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 785105,
        "city": "Majuli",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 802151,
        "city": "Arrah",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 495679,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431811,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502372,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507154,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 345022,
        "city": "Jaisalmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124507,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 394620,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400167,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 202394,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363040,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 241405,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360520,
        "city": "Jamjodhpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 391105,
        "city": "Dabhoi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522411,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577535,
        "city": "Molakalmuru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263158,
        "city": "Bhimtal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413738,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 586108,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627001,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224139,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424202,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 493551,
        "city": "Pithora",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 751030,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757003,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680541,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 482004,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 673312,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 643214,
        "city": "Kotagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 190025,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 177219,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 204106,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400098,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712135,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733208,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 763003,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 142052,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271604,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 828404,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 177007,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577547,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470119,
        "city": "Rahatgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 180018,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 312614,
        "city": "Nimbahera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680322,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 607209,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389002,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 636116,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172101,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403401,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 764078,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 473440,
        "city": "Mungaoli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 683101,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 326033,
        "city": "Aklera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 472336,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 635203,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457222,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 783394,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 229309,
        "city": "Gauriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574220,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141010,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110118,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 192101,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360003,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 493330,
        "city": "Nagri",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754144,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431402,
        "city": "Parbhani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444803,
        "city": "Daryapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686547,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 496100,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 754134,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627301,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532445,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230131,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686507,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 833102,
        "city": "Chakradharpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303101,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754007,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560097,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 535546,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577440,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686106,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 515571,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382016,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 626159,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723101,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 610202,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524230,
        "city": "Udayagiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573125,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201317,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441118,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 249411,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227107,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 232221,
        "city": "Renukoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134203,
        "city": "Naraingarh",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627702,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 262530,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110024,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 206127,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670107,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847313,
        "city": "Pupri",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 523245,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761114,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495117,
        "city": "Pendra",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 799261,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 736145,
        "city": "Sitai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176060,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411036,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 305621,
        "city": "Nasirabad",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754162,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721140,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335037,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403521,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 824103,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581143,
        "city": "Ranebennur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786156,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 683583,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689622,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415307,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 629302,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 845426,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110501,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 227309,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571450,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271205,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835213,
        "city": "Lohardaga",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712249,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 501503,
        "city": "Shankarpalle",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 207502,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 608302,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781001,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 182222,
        "city": "Bhaderwah",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416713,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 248143,
        "city": "Doiwala",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843161,
        "city": "Aurai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 284304,
        "city": "Poonchh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442506,
        "city": "Gadchiroli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 190020,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 284136,
        "city": "Talbahat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609403,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394180,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 209736,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752080,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 210502,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500253,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413521,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 483336,
        "city": "Sihora",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 262308,
        "city": "Khatima",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110097,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 625582,
        "city": "Bodinayakanur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700103,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586207,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203207,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 208010,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713402,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758074,
        "city": "Kendujhar",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 784521,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 845311,
        "city": "Bagaha",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 757023,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 124303,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497778,
        "city": "Janakpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 505416,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422306,
        "city": "Lasalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 799002,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 221716,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686544,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 284120,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144625,
        "city": "Sultanpur Lodhi",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175017,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532242,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362610,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 306421,
        "city": "Pali",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 207247,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402202,
        "city": "Murud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 132110,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689691,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 683582,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 676111,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 630215,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560093,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 247231,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523372,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521245,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247669,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624704,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272154,
        "city": "Menhdawal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361345,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680567,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 673327,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 602004,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 331403,
        "city": "Sardarshahr",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140603,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843312,
        "city": "Aurai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 231221,
        "city": "Renukoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686613,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 326038,
        "city": "Khanpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635801,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 488333,
        "city": "Panna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 575010,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370460,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 583219,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583120,
        "city": "Kurugodu",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383440,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 623526,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784166,
        "city": "Behali",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 585443,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110508,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 495682,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577511,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695142,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 758027,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682032,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 207403,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784103,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 624002,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415527,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400084,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627401,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303327,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 127045,
        "city": "Siwani",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614810,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444503,
        "city": "Washim",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 602108,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700138,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176082,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531162,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274303,
        "city": "Padrauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333702,
        "city": "Balaran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143518,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581187,
        "city": "Mallapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766110,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625520,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176031,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623804,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533260,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623314,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638102,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 734222,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 835201,
        "city": "Kolebira",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743425,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 492014,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 622507,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276205,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786155,
        "city": "JaytishNagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 711108,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382355,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 793003,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 456003,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 209217,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534196,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473110,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 486340,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 581423,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415308,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 607802,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506310,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416806,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 689649,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583155,
        "city": "Kurugodu",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 326039,
        "city": "Aklera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 314037,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691018,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 722165,
        "city": "Bishnupur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 690533,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 723168,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576126,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425004,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638110,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695307,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 209725,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 563162,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504202,
        "city": "Khanapur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441401,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 531081,
        "city": "Koruprolu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505471,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721166,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600071,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388270,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 628502,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 768118,
        "city": "Rairakhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670334,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641658,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 277503,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761217,
        "city": "Mohana",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591302,
        "city": "Khanapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630709,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623407,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 136118,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734501,
        "city": "Kalijhora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363110,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678551,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 461005,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 144043,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 312618,
        "city": "Pratapgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431117,
        "city": "Soegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442605,
        "city": "Gadchiroli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 635306,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624301,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 722138,
        "city": "Mirzapur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574164,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733103,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396191,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 792001,
        "city": "Tezu",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 176202,
        "city": "Palion",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676501,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641018,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176402,
        "city": "Surajpur jhikla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 460665,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 210507,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305624,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124141,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676509,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 690511,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110114,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 627104,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333029,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563160,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416506,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 609104,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413403,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 523166,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384570,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700126,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 462044,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 813205,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382045,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680682,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 271602,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562117,
        "city": "Kanakapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144301,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 225201,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133006,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 250621,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244105,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209312,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700119,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585411,
        "city": "Bhalki",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171202,
        "city": "Kotkhai",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785110,
        "city": "Majuli",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 688538,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 148035,
        "city": "Patran",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 765015,
        "city": "Tikiri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 611105,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 133007,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 804405,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533210,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783334,
        "city": "Golakganj",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 803120,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110050,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 396436,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 637301,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625122,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700079,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110092,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 521202,
        "city": "Nuzvid",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515124,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600117,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574114,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 277207,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621731,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 612104,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312603,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517234,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577102,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229311,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841401,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678017,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 825167,
        "city": "Gomoh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442705,
        "city": "Aheri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 246165,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 343027,
        "city": "Sanchore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721654,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603202,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571248,
        "city": "Madikeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203391,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844113,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 302041,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632527,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631003,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581301,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441102,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574327,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132036,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560049,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243509,
        "city": "Baheri",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 133205,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431511,
        "city": "Vasmat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 333516,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688561,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 700104,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 562101,
        "city": "Chikkaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679332,
        "city": "Nilambur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 682037,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 814160,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 456313,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 636142,
        "city": "Mettala",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 643282,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362520,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 626144,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612803,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560104,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 411060,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 721402,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 590001,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 380063,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 144000,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 145027,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583224,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370001,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691589,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 394601,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 825302,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754071,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151104,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629703,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143531,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 763002,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678015,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 502334,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 821124,
        "city": "Mohania",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 245301,
        "city": "Pilkhuwa",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679579,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506349,
        "city": "Narsampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721437,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636005,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635119,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273310,
        "city": "Bhathat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734422,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403101,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431514,
        "city": "Gangakher",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444129,
        "city": "Jalgaon Jamod",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 560009,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 563157,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622302,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673321,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 160023,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742178,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360022,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 411049,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 462028,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 455112,
        "city": "Dewas",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 605009,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700013,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574313,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 582204,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822121,
        "city": "Nagar Untari",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756112,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845501,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422007,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 361007,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 627858,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 834002,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 425111,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 506151,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493225,
        "city": "Tilda",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 607002,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 331022,
        "city": "Sardarshahr",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 306119,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585238,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802125,
        "city": "Malyabag",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500084,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505525,
        "city": "Choppadandi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425002,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416220,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413501,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518206,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583215,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392110,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 504209,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123301,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 828129,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262727,
        "city": "Maholi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125077,
        "city": "Odhan",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700026,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624702,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758026,
        "city": "SUKINDA",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246426,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841213,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761102,
        "city": "Hinjilicut",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 575012,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688505,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144025,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 387335,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 495006,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 248123,
        "city": "Chakrata",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 790114,
        "city": "Bhalukpong",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 370145,
        "city": "Bhachau",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 486440,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 182144,
        "city": "Ramban",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 757039,
        "city": "Raruan",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784513,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 465674,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 121101,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 333033,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845315,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577115,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144408,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244402,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303001,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735214,
        "city": "Alipurduar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560061,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 502101,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506143,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524001,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 811112,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509358,
        "city": "Amangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 245403,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587206,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 570024,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685608,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 678012,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 492008,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 623002,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 753000,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516006,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632512,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688013,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 522332,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209207,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261141,
        "city": "Maholi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431720,
        "city": "Gangakher",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394810,
        "city": "Hansot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 731240,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389340,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 387380,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 281002,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 496242,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 688511,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 142036,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607302,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244242,
        "city": "Sambhal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244928,
        "city": "Bilaspur-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388315,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 321023,
        "city": "Kaman",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 362002,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 614903,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224210,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391107,
        "city": "Dabhoi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 285223,
        "city": "Konch",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713315,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413623,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591262,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323601,
        "city": "Keshoraipatan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 462043,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 412407,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 690514,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847234,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 580006,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 387570,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 145105,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 476345,
        "city": "Sheopur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 450115,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 629809,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272203,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387610,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 412805,
        "city": "Mahabaleshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518360,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614618,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788109,
        "city": "Udarbond",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 624003,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 458771,
        "city": "Bhanpura",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 627354,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781022,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 425519,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 847421,
        "city": "Khutauna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841232,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685501,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629161,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232110,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843318,
        "city": "Runnisaidpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 476355,
        "city": "Sheopur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 582210,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192233,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 855113,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 440012,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 854333,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712409,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 828101,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 805106,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500048,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 602022,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 586116,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847412,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 736165,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 225413,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636406,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507316,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 737101,
        "city": "Gangtok",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 272195,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571106,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 767061,
        "city": "Loisingha",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586113,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285201,
        "city": "Konch",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673596,
        "city": "Pulpally",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 244236,
        "city": "Amroha",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560014,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 472246,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 502276,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841419,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721451,
        "city": "Belda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781005,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 322024,
        "city": "Bonli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 451555,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 440011,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 131409,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221505,
        "city": "Saidabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 173211,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757022,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 175132,
        "city": "Keylong",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600031,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534146,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144805,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 307024,
        "city": "Abu Road",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416202,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695128,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 852113,
        "city": "Madhepura",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143602,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 284405,
        "city": "Mahrauni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625556,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686637,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413222,
        "city": "Vairag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 852217,
        "city": "Sonbarsa-BH",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122508,
        "city": "Hodal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227301,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621803,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387001,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 632518,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493111,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 184144,
        "city": "Hiranagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815312,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 190024,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 201305,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273413,
        "city": "Kauriram",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581302,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122019,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 822116,
        "city": "Japla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431705,
        "city": "Hingoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 767040,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686011,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 627724,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144522,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 231220,
        "city": "Anpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413718,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 523370,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131024,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743332,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 563135,
        "city": "Srinivaspur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205247,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680686,
        "city": "Irinjalakuda",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 321201,
        "city": "Deeg",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303604,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522240,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 612903,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768218,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301705,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175050,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713338,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431107,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444117,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 391241,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 627115,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835101,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322034,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843334,
        "city": "Sheohar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609110,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140127,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691523,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 630606,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276139,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411412,
        "city": "Pune",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 723155,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425503,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576107,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227412,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586126,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 193402,
        "city": "Baramulla",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416513,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 622502,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401405,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 759141,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 472442,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 332036,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517126,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843110,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560064,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673528,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 848131,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757105,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581111,
        "city": "Hirekerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781038,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 803302,
        "city": "Sheonar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 723150,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 762104,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623315,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627421,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364005,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 676110,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 344001,
        "city": "Barmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413509,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 522234,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752078,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 471101,
        "city": "Khajuraho",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 761100,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 620101,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 232108,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591147,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802312,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695127,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332307,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758046,
        "city": "Karanjia",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 249408,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824211,
        "city": "Sherghati",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 503230,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160069,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713401,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 620022,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416403,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 122015,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 327601,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400106,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712103,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305003,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 134115,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 603402,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503165,
        "city": "Sirikonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712411,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585313,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713209,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700078,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641034,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534209,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494226,
        "city": "Kondagaon",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 630009,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 796070,
        "city": "Kolasib",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 609202,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422013,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627110,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312204,
        "city": "Kapasan",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852105,
        "city": "Simrahi Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628614,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606111,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173025,
        "city": "Paonta Sahib",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 481879,
        "city": "Dindori",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 741187,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524005,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784505,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 486882,
        "city": "Deosar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 572105,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680731,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 608201,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636404,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624103,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530004,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143423,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741254,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713148,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 755024,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151106,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177205,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688535,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 124504,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 322231,
        "city": "Karauli",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 505415,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 402114,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413519,
        "city": "Udgir",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441113,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 828309,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754137,
        "city": "Tirtol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676315,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144038,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 274402,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 608102,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125112,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 586142,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303506,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 770044,
        "city": "Lahunipara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531002,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394105,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 311012,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141105,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630318,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731204,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184151,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841412,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 247323,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695308,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583104,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621103,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403704,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 805138,
        "city": "Pakribarwan",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 171018,
        "city": "Suni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686029,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 786110,
        "city": "Silapathar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 182127,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682001,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 695023,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 803203,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711403,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 226018,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110062,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802133,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621212,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584102,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628503,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854347,
        "city": "Katihar",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 688556,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788725,
        "city": "Patharkandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 680741,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 828106,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 504293,
        "city": "Asifabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 816122,
        "city": "Pakur",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 756053,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 175032,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415403,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 575013,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403602,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 285126,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175034,
        "city": "Ghumarwin",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142048,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413711,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 734312,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522238,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768202,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757073,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504105,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500019,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382145,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721636,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415315,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 333517,
        "city": "Buhana",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 184142,
        "city": "Hiranagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 323024,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 190021,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 401601,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 535217,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676306,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400710,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 734503,
        "city": "Jholung",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 247672,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606301,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 304026,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635751,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177031,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695521,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 504218,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412213,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 414609,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 756024,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 207125,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532215,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533201,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365540,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144035,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721645,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360530,
        "city": "Jamjodhpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 382004,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 768233,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522008,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421305,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110015,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 415510,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 341507,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756181,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 798601,
        "city": "Mokokchung",
        "state": "Nagaland",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 401304,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 251002,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509204,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609114,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413603,
        "city": "Naldurg",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 281401,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743438,
        "city": "Swarupnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678641,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768234,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 307019,
        "city": "Pindwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841245,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416602,
        "city": "Kankavali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 580009,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532291,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625217,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685515,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 431122,
        "city": "Beed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500110,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 470337,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 680310,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700042,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382115,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 591315,
        "city": "Ugar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571444,
        "city": "Hanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415412,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 451449,
        "city": "Sendhwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 388205,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 643207,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752108,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695561,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 825320,
        "city": "Barki Saria",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143304,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 322254,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382346,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 210105,
        "city": "Chitrakoot",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 321601,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226026,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793101,
        "city": "Nongpoh",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 670303,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303104,
        "city": "Achrol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 194101,
        "city": "Leh",
        "state": "Ladakh",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 362229,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 811317,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322026,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500008,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 440104,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591211,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 760009,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 202281,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630405,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756124,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516213,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824201,
        "city": "Barachatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756055,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600015,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632405,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421601,
        "city": "Asangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 192210,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624215,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 275307,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133103,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 140307,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585104,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685595,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 323307,
        "city": "Rawatbhata",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759017,
        "city": "Bhuban",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246762,
        "city": "Nagina",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225416,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767023,
        "city": "Dunguripali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 242404,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 682508,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 576122,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370210,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 754217,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 493331,
        "city": "Baloda Bazar",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 144101,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 134112,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793121,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 607202,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274204,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506006,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 383215,
        "city": "Prantij",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144623,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125106,
        "city": "Ratia",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388307,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 422104,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415637,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 326530,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574179,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843105,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 241401,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454449,
        "city": "Manawar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 302044,
        "city": "Kalwar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247001,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301402,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 496334,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 332031,
        "city": "Balaran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752115,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518396,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686009,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341026,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444827,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 828114,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 412218,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 821106,
        "city": "Chainpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523333,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636804,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822119,
        "city": "Mahuadanr",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 458990,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 202124,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852201,
        "city": "Saharsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 203132,
        "city": "Khurja",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575009,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 480999,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 782439,
        "city": "Howraghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 768232,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 759145,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680104,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273005,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411028,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 785634,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 502303,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505462,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683109,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 605007,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805111,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621217,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431118,
        "city": "Soegaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 713331,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382324,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713502,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 575015,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 755021,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 442403,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 282003,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824129,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700009,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110010,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 627426,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141304,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 723104,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 812005,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 232104,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493195,
        "city": "Bhatapara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 583134,
        "city": "Kotturu",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713125,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 570011,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 823003,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635301,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484771,
        "city": "Jaisinghnagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 306303,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670601,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572119,
        "city": "Huliyar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 482050,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 600125,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 732210,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274505,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600126,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303501,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 609313,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416214,
        "city": "Bambavade",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 635128,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 233302,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370135,
        "city": "Bhachau",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 193223,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 638010,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 835210,
        "city": "Khunti",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 563156,
        "city": "Chintamani",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 205265,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480888,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 380020,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 800004,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 131402,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 855102,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577591,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762106,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431121,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 796730,
        "city": "Chawngte",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 785008,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 176047,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332712,
        "city": "Ajeetgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854317,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 573150,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134109,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416518,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 229210,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383460,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 226202,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628213,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306102,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440020,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682030,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577222,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783350,
        "city": "Gossaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 370203,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 228161,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 231301,
        "city": "ChakiaUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403527,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 847106,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 177204,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413708,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713152,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688544,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176023,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600049,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 786179,
        "city": "Margherita",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 464776,
        "city": "Udaipura",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 583216,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201304,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788831,
        "city": "Mahur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 458470,
        "city": "Neemuch",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 415205,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 732215,
        "city": "Farakka",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518462,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305814,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721649,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 344043,
        "city": "Siwana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754244,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272191,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571124,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415503,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 829207,
        "city": "Latehar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641048,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572122,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506168,
        "city": "Bhupalpally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533464,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722207,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507115,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712123,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442201,
        "city": "Talegaon Sp",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 487118,
        "city": "Tendukheda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 733101,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500660,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600052,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635654,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411000,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500361,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573217,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480997,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 626117,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 831017,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 762002,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642120,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144702,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575016,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626111,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761121,
        "city": "Jagannathprasad",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431003,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 523156,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626182,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767022,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799104,
        "city": "Amarpur TP",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 209504,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641201,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581403,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754109,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515511,
        "city": "Mudigubba",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 575002,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144024,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680323,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 110002,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 249401,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 445001,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627861,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500017,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334804,
        "city": "Kolayat",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 307501,
        "city": "Abu Road",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781138,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 532218,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741127,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 501141,
        "city": "Tandur-TL",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465685,
        "city": "Pachore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 516001,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841235,
        "city": "Mairwa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 462008,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 621712,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517426,
        "city": "Kuppam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680016,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 711114,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 333042,
        "city": "Balaran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424108,
        "city": "Chalisgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 854119,
        "city": "Kursela",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 614208,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620020,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 854102,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517391,
        "city": "B Kothakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843134,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 801303,
        "city": "Khodaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 401400,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 485778,
        "city": "Amarpatan",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 396055,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700085,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 370640,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713432,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412401,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 516237,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313601,
        "city": "Bhatewar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210128,
        "city": "Baberu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734220,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781068,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 624622,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685603,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 574229,
        "city": "Kadaba",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 335063,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176081,
        "city": "Baijnath",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571502,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 758020,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144031,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712707,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423403,
        "city": "Yeola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 811305,
        "city": "Jhajha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140126,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606754,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 304020,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854302,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756058,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160039,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591124,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 624220,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171302,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 387325,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 509347,
        "city": "Kalwakurthy",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 743319,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176038,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224284,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712138,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246724,
        "city": "Nagina",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361280,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 801109,
        "city": "Bihta",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 642114,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415536,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141422,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491996,
        "city": "Kawardha",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 151501,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 241403,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829143,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642126,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413608,
        "city": "Naldurg",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176306,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 166031,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531116,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502336,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583140,
        "city": "Bellary",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 632317,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604151,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799270,
        "city": "Kumarghat",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 383325,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 203155,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533262,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521366,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758083,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403711,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 243004,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689589,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581421,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 211023,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 670703,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332403,
        "city": "Bajore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781103,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 629803,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507208,
        "city": "Bonakal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680121,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 627501,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626053,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813106,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571422,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160067,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682036,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 576120,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140112,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625017,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678581,
        "city": "Agali",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 207248,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799210,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 440021,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754012,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721648,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841421,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 148006,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 335503,
        "city": "Bhadra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680123,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 641001,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 364230,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 785700,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 851203,
        "city": "Maheshkhunt",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442307,
        "city": "Hinganghat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560056,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 180001,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176066,
        "city": "Khundian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 276141,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518218,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562163,
        "city": "Doddaballapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414505,
        "city": "Pathardi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 798617,
        "city": "Mokokchung",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 110087,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 505307,
        "city": "Rudrangi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690110,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 494551,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 758029,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531026,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814142,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 127027,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133201,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764020,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263630,
        "city": "Bageshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415514,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574151,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628801,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231314,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641109,
        "city": "Madukkarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 570033,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 732142,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 800013,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 148005,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800002,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 452011,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 141309,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845435,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480223,
        "city": "Amarwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 380045,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 626108,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425505,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502110,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722144,
        "city": "Bishnupur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695508,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 143036,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262502,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110301,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 685551,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 535593,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262406,
        "city": "Nawabganj-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416313,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515651,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 487661,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 609305,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493229,
        "city": "Tilda",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 396045,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 388305,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 530002,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630501,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226029,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 613052,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 590019,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533103,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 515721,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560300,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209602,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 457893,
        "city": "Alirajpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781366,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 560073,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 575008,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 822212,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641654,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 173205,
        "city": "Baddi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641021,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396421,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 262523,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759039,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 794107,
        "city": "Williamnagar",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 591322,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370670,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 605051,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641041,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641605,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 272199,
        "city": "Menhdawal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624703,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 453001,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 490007,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695103,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 285204,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689677,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 637013,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643236,
        "city": "Kotagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121105,
        "city": "Palwal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122006,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 473670,
        "city": "Karera-MP",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 691527,
        "city": "Kottarakkara",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 410219,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388121,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 532312,
        "city": "Ichchapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229003,
        "city": "Gauriganj",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 370006,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 125111,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721657,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586109,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835306,
        "city": "Lohardaga",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571606,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 515435,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604501,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171001,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614708,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788127,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 431005,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 623701,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141116,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 230201,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 421506,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 394670,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 767026,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700157,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 227801,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176206,
        "city": "Shahpur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625515,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835233,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571431,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609702,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673018,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 825409,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 829108,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 465106,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 363750,
        "city": "Viramgam",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 841231,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250617,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623120,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761004,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 622209,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140306,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 423105,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431401,
        "city": "Parbhani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 847408,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679326,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641102,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757083,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695105,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624106,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515306,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 261302,
        "city": "Sidhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534237,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500090,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415604,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 302018,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 424001,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515671,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332704,
        "city": "Ajeetgarh",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 522004,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 523304,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847225,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 380028,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 224119,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515411,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829144,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 802117,
        "city": "Dinara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 608303,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844504,
        "city": "Mahnar Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690572,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 759040,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388225,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141109,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 365660,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 535547,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501403,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212656,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493445,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 614027,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736167,
        "city": "Sitai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581209,
        "city": "Navalgund",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571426,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785635,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 575022,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700019,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614211,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591516,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422221,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 676123,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110070,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 518220,
        "city": "Dhone",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 222162,
        "city": "Mariahu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621005,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 393017,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 201205,
        "city": "Modinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142040,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222201,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110063,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 523183,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 846007,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670343,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 768211,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 276207,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689511,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 243126,
        "city": "Faridpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571602,
        "city": "Krishnarajngr",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851213,
        "city": "Maheshkhunt",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680652,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 583121,
        "city": "Siruguppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182313,
        "city": "Reasi",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642006,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628552,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 211008,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 755005,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230501,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741158,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 224231,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625234,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 301709,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400020,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 531133,
        "city": "Chintapalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305001,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721436,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673624,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 172033,
        "city": "Anni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756051,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444109,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 342016,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690516,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 306704,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383327,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 741258,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522111,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313331,
        "city": "Badnaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612204,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685509,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 695589,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 533228,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192232,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678681,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 246734,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600069,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 246443,
        "city": "Joshimath",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700109,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 751020,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 803121,
        "city": "Rajgir",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500023,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 261303,
        "city": "Sidhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 481556,
        "city": "Linga",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 424303,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 425416,
        "city": "Nandurbar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 384430,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680303,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 284501,
        "city": "Lalitpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741175,
        "city": "Chapra",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 144209,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731124,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 402203,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 563115,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 193221,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560103,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626161,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 313804,
        "city": "Kherwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333043,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848128,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410520,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 505172,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623709,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671122,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 456007,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 534112,
        "city": "Nallajerla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325221,
        "city": "Kawai",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 509334,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524222,
        "city": "Udayagiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688523,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 496223,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 766001,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636811,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518543,
        "city": "Allagadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770015,
        "city": "Subdega",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 845458,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 630303,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396240,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577555,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425402,
        "city": "Amalner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 585413,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676121,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 620021,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 516114,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226201,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 333308,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384229,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 303348,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333025,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415728,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431722,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 586205,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 760007,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581346,
        "city": "Hangal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758034,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248002,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 147008,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600131,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560115,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 380019,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 524413,
        "city": "Vidyanagar",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585236,
        "city": "Afzalpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813118,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411003,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 248013,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271804,
        "city": "Bhinga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515301,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689533,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 623536,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642007,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626107,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400014,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 815353,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721601,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140063,
        "city": "Mohali",
        "state": "Punjab",
        "Zone": "N2"
       },
       {
        "pincode": 441409,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 494552,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 143102,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 793006,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 274502,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495220,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 495692,
        "city": "Dabhra",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 496220,
        "city": "Pathalgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 505101,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361350,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 185153,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 313321,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574145,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 795007,
        "city": "Senapati",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 491002,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 754156,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577202,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517125,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560052,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 277211,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731220,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632503,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486117,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 721516,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 322021,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534102,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 430007,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517563,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 691004,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 110122,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 673571,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 535527,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335502,
        "city": "Bhadra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623525,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762022,
        "city": "G. Udayagiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 473993,
        "city": "Pichhore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 413406,
        "city": "Vairag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 843014,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 764006,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700056,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518004,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 249122,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 711305,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 173215,
        "city": "Waknaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841204,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441117,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 491111,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 202282,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497447,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 493222,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 141126,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416203,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 445207,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 424318,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 208007,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273200,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 175106,
        "city": "Banjar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273001,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 471803,
        "city": "Tarana",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 847226,
        "city": "Basopatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495003,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 152107,
        "city": "Malout",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174032,
        "city": "Jukhala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627424,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410206,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 760020,
        "city": "Kantamal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416302,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757085,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152003,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535524,
        "city": "Kurupam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313342,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413624,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442603,
        "city": "Chamorshi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 768112,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695601,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 605402,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364145,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 402125,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 491221,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 492004,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 626601,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686023,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636112,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522001,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 244926,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788723,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 494225,
        "city": "Bastar",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 231216,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226031,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 284124,
        "city": "Lalitpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503174,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 391115,
        "city": "Dabhoi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 226020,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 629177,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613702,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623605,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761012,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518225,
        "city": "Dhone",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534005,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177005,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402122,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 804452,
        "city": "Masaurhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124113,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722152,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700060,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 572113,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573115,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524311,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160031,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 562110,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624618,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843328,
        "city": "Runnisaidpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 843315,
        "city": "Sonbarsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636303,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382026,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 581198,
        "city": "Byadagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140101,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 791114,
        "city": "Sagalee",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 441109,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 182312,
        "city": "Reasi",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 143411,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761011,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560058,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 531001,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 831013,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 474015,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 686533,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 391150,
        "city": "Nasvadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 209202,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 723147,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 273309,
        "city": "Brijmanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786153,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 629191,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506013,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413111,
        "city": "Mhaswad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 604103,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734012,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246481,
        "city": "Tharali",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803214,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574509,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576201,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673601,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 848132,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781349,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 422215,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754119,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632202,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642128,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396067,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 273009,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 190002,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 322252,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533215,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700033,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 125078,
        "city": "Odhan",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 473330,
        "city": "Ashok Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 534260,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673642,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 851116,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 370510,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 481337,
        "city": "Waraseoni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 614103,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841252,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841210,
        "city": "Raghunathpur-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 832303,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560088,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 494445,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 743271,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176044,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 587203,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 737136,
        "city": "Rangpo",
        "state": "Sikkim",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 636107,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678601,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 575025,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401505,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 504214,
        "city": "Chinnur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360470,
        "city": "Upleta",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 604502,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679312,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 824125,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360011,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680309,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403006,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 385011,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416231,
        "city": "Mudal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 630302,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621128,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143203,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767042,
        "city": "Titilagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577113,
        "city": "Mudigere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768111,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756142,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 341503,
        "city": "Degana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364315,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 631002,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271875,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573132,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522301,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123035,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415708,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 735209,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415504,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 786150,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 721170,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411039,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 445103,
        "city": "Arni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 281122,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741221,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534416,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802211,
        "city": "Malyabag",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383260,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400706,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 756080,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250344,
        "city": "Daurala",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673544,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 122233,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221502,
        "city": "Saidabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731102,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572220,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400608,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 212109,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757018,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605302,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226007,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 412206,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 843329,
        "city": "Sheohar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152128,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686102,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 581213,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 452023,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 535568,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303325,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574180,
        "city": "Surathkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202393,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841251,
        "city": "Hajipur",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 621104,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411029,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788002,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679501,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303106,
        "city": "Paota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609704,
        "city": "Peralam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495678,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522006,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508277,
        "city": "Mothkur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413319,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 734003,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712208,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670001,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 721519,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312025,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 181208,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229408,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 147101,
        "city": "Pehowa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 733202,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410510,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 522612,
        "city": "Macherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691020,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 584122,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843151,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410105,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 534000,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509205,
        "city": "Narayanpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632509,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147001,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533347,
        "city": "Chintoor",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 491888,
        "city": "Berla",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713203,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517643,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272127,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533124,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 764059,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686673,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174505,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686665,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 854117,
        "city": "Manihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781375,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 673584,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631303,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 455221,
        "city": "Chapda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 121002,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680017,
        "city": "Arimbur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636118,
        "city": "Mettala",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360421,
        "city": "Dhoraji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 172111,
        "city": "Pooh",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783324,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 206244,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 277209,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642108,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390014,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 731244,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686103,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 277401,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416101,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141110,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721141,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623527,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783390,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 524344,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504104,
        "city": "Bhainsa",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758043,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576108,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 761054,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 202155,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 797115,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 843114,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125101,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 146107,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 766117,
        "city": "Komana",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 451443,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 461221,
        "city": "Seoni Malwa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 689545,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 141000,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502312,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691505,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 517619,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307023,
        "city": "Pindwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627955,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202002,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425401,
        "city": "Amalner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 244002,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 202136,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700139,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761140,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411011,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 581365,
        "city": "Dandeli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680549,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 410209,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 767035,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600009,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621711,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 208022,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 734424,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 532455,
        "city": "Kotturu-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402126,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691332,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413110,
        "city": "Baramati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 332601,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 247769,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 846009,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 533104,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 361120,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 233304,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691560,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 462011,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 425438,
        "city": "Amalner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795134,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 444306,
        "city": "Khamgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 503213,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 485116,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 342023,
        "city": "Balesar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246148,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 361140,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 761001,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500026,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 852138,
        "city": "Supaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 172031,
        "city": "Nankhari",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768204,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 333705,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584127,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641111,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 483220,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 441905,
        "city": "Bhandara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 221309,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785681,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 413010,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141414,
        "city": "Khanna",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450117,
        "city": "Ashapur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 828121,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 151108,
        "city": "Bhadaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670308,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 561207,
        "city": "Bagepalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523213,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802103,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274506,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577549,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 170001,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531151,
        "city": "Araku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690508,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 782445,
        "city": "Hojai",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 202398,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 127025,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600109,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 613704,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491666,
        "city": "AmbagarhChowki",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742301,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423603,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 321025,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521001,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210100,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 460669,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 313211,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261404,
        "city": "Sitapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627951,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 575014,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768036,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603102,
        "city": "Tirukalukundram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754280,
        "city": "Cuttack",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 422206,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 688529,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627121,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 328038,
        "city": "Bari",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 134110,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627302,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 494122,
        "city": "Sukma",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 534411,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 242307,
        "city": "Tilhar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403107,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638211,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 800003,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623106,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466331,
        "city": "Nasrullaganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673522,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 209112,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462022,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 623566,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673639,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 530031,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413206,
        "city": "Karmala",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 208013,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144411,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623107,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524411,
        "city": "Vidyanagar",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835228,
        "city": "Simdega",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 575001,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 732141,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506252,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274301,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391346,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 825137,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 131103,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623530,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722160,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 484661,
        "city": "Umaria",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 689659,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585414,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388510,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 206126,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700035,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160027,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 125003,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 201002,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 493441,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 517425,
        "city": "Kuppam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533101,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534238,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762105,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 796751,
        "city": "Tlabung",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 848113,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263676,
        "city": "Ramnagar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768104,
        "city": "Binka",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621306,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414104,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413411,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584111,
        "city": "Devadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 842006,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712406,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735203,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689548,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577425,
        "city": "Hosanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425125,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 824213,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632406,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682015,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 301020,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394116,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 411016,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413225,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400053,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 690104,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 695607,
        "city": "Venjaramoodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 622406,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 275305,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721635,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782410,
        "city": "Jagiroad",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 445213,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 207404,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 845423,
        "city": "Jiwdhara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 690519,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621801,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626145,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734436,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110054,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 394405,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500482,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743377,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321203,
        "city": "Deeg",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400022,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 732209,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271309,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799007,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 506316,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143505,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 147021,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271317,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210431,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785644,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 431802,
        "city": "Bhokar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 670014,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 700147,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506342,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247663,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 307044,
        "city": "Abu Road",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680663,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 202129,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382240,
        "city": "Bavla",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 766102,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 390020,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 854357,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680582,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 360055,
        "city": "Jasdan",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 243727,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 283136,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229128,
        "city": "Salon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415517,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 140116,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 326518,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 486226,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742225,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 570013,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 782485,
        "city": "Hojai",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 460443,
        "city": "Sarni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 466116,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 794106,
        "city": "Mahendraganj",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 323301,
        "city": "Keshoraipatan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793002,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686508,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144505,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 412207,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 829202,
        "city": "Balumath",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230132,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370030,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 273209,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700096,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591297,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413109,
        "city": "Walchandnagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 751004,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695522,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273403,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222127,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736118,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312622,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583139,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 632106,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 263620,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673542,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 210426,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370630,
        "city": "Dayapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 852107,
        "city": "Madhepura",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 193198,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 391350,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 325207,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757051,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854332,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 611104,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 206247,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444723,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638005,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 203203,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673001,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 506371,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573149,
        "city": "Hassan",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 502371,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761120,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 445304,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 123003,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 603109,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 180013,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 642005,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628216,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110042,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 313038,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695527,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 625534,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431203,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 751024,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 421306,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400031,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 312623,
        "city": "Pratapgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803306,
        "city": "Sheonar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632514,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523262,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263643,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532290,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711204,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 276406,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515561,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852215,
        "city": "Simrahi Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212653,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689680,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700110,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143530,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 275101,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 620003,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 314027,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531033,
        "city": "Anakapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 395011,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 781150,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 202134,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226106,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400008,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 160004,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670705,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 209726,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 787057,
        "city": "Dhemaji",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 516259,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533229,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743385,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636810,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415019,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441912,
        "city": "Tumsar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713204,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175124,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686610,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 503301,
        "city": "Banswada",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400058,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 847223,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824237,
        "city": "Sherghati",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509351,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321303,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321410,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573121,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141114,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627754,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691551,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 125004,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516126,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630612,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585101,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415010,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591182,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229201,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534442,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801102,
        "city": "Bihta",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 135102,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695011,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 721151,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431002,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 560076,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 454773,
        "city": "Dhar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 173212,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301423,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 232112,
        "city": "ChakiaUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690510,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 393151,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 171005,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 482056,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 212206,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422303,
        "city": "Lasalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795150,
        "city": "Tadubi",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 533232,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609101,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843349,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734100,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534218,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312401,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755037,
        "city": "Dhamanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626565,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631302,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431502,
        "city": "Selu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 831008,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500061,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560110,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384230,
        "city": "Becharaji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400033,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 370625,
        "city": "Dayapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 824111,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521107,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591304,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 561202,
        "city": "Pavagada",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 463106,
        "city": "Berasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 626176,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152118,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638003,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224202,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571415,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626579,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679333,
        "city": "chungatra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 464993,
        "city": "Mandideep",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416534,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 232109,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768227,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535005,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844134,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711201,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 222132,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388105,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 636139,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 331505,
        "city": "Ratangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431302,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841218,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 482005,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 523223,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583276,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 184145,
        "city": "Samba",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 825313,
        "city": "Bagodar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413723,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 480338,
        "city": "Pandhurna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 585222,
        "city": "Sedam",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754028,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160106,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 755046,
        "city": "SUKINDA",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686014,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 505452,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802201,
        "city": "Sandesh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495688,
        "city": "Dabhra",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 635207,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382470,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400023,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 522649,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466117,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 494435,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 625003,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 131305,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 190015,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 223227,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 193222,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400097,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682314,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 505466,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835214,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413522,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 227205,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752001,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641666,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 570021,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396445,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 124401,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141003,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847111,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 180012,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 572102,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134108,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788803,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 670003,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 461990,
        "city": "Pipariya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 365220,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 676310,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506201,
        "city": "Jangaon",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670007,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 752055,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603401,
        "city": "Marakkanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 430005,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 302043,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524225,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563128,
        "city": "Chintamani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682014,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577138,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781018,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 173020,
        "city": "Paonta Sahib",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507125,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695317,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 563118,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509129,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721602,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 389310,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 127021,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 576106,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209831,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627124,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 261204,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636454,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401504,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638152,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230402,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686028,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144302,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531218,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577002,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224158,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586130,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493885,
        "city": "Abhanpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713321,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151107,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 603309,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630101,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 110047,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 713344,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504102,
        "city": "Bhainsa",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201206,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444909,
        "city": "Warud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382009,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 382006,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 385210,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 462020,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 689624,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679512,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 695583,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 140117,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591135,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176108,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177201,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224127,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 306114,
        "city": "Sojat",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110100,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 413726,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721514,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416316,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691010,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 751031,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 414107,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 422012,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 383110,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 679553,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 122102,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 811315,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160073,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 495224,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 591233,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782427,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 607106,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509327,
        "city": "Amangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412101,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 244241,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630306,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638103,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502032,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416307,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 131021,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 723121,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 440010,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 452017,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 796004,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 585310,
        "city": "Jewargi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621007,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423108,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 635109,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383225,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 180005,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 174308,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 782120,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 248009,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783323,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 574108,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571416,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 228101,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 307511,
        "city": "Reodar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 458553,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 396101,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 191132,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425303,
        "city": "Chopda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 643231,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342306,
        "city": "Mathania",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 451111,
        "city": "Sanawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 452002,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 735139,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442909,
        "city": "Aheri",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 586122,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673032,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577525,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 275105,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210204,
        "city": "Chitrakoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689668,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 637103,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410503,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 829106,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 630609,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796441,
        "city": "Mamit",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 502107,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391750,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 302021,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671125,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636015,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799285,
        "city": "Kamalpur",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 394520,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 181102,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403407,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 530010,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 490025,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 125122,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628722,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131302,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 642207,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230305,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742304,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 851131,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841501,
        "city": "Kuchaikote",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641031,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 638302,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303802,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621009,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625562,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473001,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 506102,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306304,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690101,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 335711,
        "city": "Gharsana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533432,
        "city": "Yerravaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384311,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 495555,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 764004,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 273402,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143106,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416321,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413213,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 225119,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534407,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412106,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 364522,
        "city": "Savarkundla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 679322,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 784176,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 670333,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 800026,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 491558,
        "city": "AmbagarhChowki",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 281204,
        "city": "Vrindavan",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 629189,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122504,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 794115,
        "city": "Garobadha",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 735229,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793001,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 473338,
        "city": "Ashok Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 361001,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 394352,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 415104,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695512,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742167,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690106,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 476115,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754032,
        "city": "Narasinghpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691333,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754208,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518347,
        "city": "Adoni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517403,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274305,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 331023,
        "city": "Rajgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531075,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144622,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370105,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 273302,
        "city": "Maharajganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 486892,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 132106,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503310,
        "city": "Yellareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143521,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 132140,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689665,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 201202,
        "city": "Modinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 825326,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 639107,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632601,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534111,
        "city": "Nallajerla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125133,
        "city": "Ratia",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572104,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486001,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 623524,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176093,
        "city": "Dheera",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 412114,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 143408,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 385330,
        "city": "Shihori",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 571201,
        "city": "Madikeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394150,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 454221,
        "city": "Kukshi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 515761,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384122,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 712416,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 211011,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 208005,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 241201,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221303,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509349,
        "city": "Kalwakurthy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741513,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 688587,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425326,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 680566,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 505475,
        "city": "Jammikunta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843321,
        "city": "Aurai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144520,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222107,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508278,
        "city": "Haliya",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413717,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521263,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751014,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742184,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444606,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 625052,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 752083,
        "city": "Daspalla",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621717,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788806,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 609306,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271310,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531082,
        "city": "Koruprolu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851120,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623529,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841508,
        "city": "Bhore",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689632,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 701102,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394635,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 342603,
        "city": "Gotan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680653,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 770017,
        "city": "Rajgangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403004,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 180009,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689521,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 384001,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 134107,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624615,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691003,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 203401,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415402,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 412804,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305207,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410107,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 535215,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767062,
        "city": "Birmaharajpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689551,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 444801,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682040,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756040,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125002,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 643102,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641032,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770039,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 462100,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 509216,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 451001,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 841446,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 814120,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 424003,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518395,
        "city": "Alur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401502,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 563130,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689503,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768028,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 601302,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 425406,
        "city": "Shindkheda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 201019,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 786692,
        "city": "Duliajan",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 110031,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 788709,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 495554,
        "city": "Akaltara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 632104,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690556,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 785697,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 175001,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 497114,
        "city": "Pathalgaon",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 332315,
        "city": "Laxmangarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 613009,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125006,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142035,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695008,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 460447,
        "city": "Sarni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 689543,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 582119,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624314,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690549,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 494331,
        "city": "keshkal",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742149,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400040,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 275103,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416309,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628901,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689630,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 122505,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 185234,
        "city": "Rajouri",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518302,
        "city": "Adoni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758076,
        "city": "Telkoi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600096,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743414,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741124,
        "city": "Chapra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531163,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274304,
        "city": "Padrauna",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 573133,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627657,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824102,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782121,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 614210,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451440,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 572120,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562121,
        "city": "Harohalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685571,
        "city": "Adimali",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 121005,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 769002,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759101,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 121102,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 733209,
        "city": "Dalkhola",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110013,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 695503,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572136,
        "city": "Pavagada",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825413,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 495553,
        "city": "Akaltara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 251310,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125049,
        "city": "Hansi",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509209,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400067,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 522005,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 326036,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 245304,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754296,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423103,
        "city": "Malegaon",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 471606,
        "city": "Khajuraho",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 389235,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 244715,
        "city": "Ramnagar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585226,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621204,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785665,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 628612,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263141,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491340,
        "city": "Bhatapara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 821303,
        "city": "Rohtas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 134152,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 795002,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 380004,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 629702,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679102,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 180007,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614613,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632605,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712102,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335021,
        "city": "Raisinghnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586119,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741151,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176217,
        "city": "Shahpur-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 401107,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 614030,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229802,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160040,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273406,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571401,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141413,
        "city": "Khanna",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333501,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759123,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591283,
        "city": "Chikodi",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 247771,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 443202,
        "city": "Dusrabid",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 392111,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 442908,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382421,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 491445,
        "city": "Dongargarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 473774,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431731,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560038,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 248010,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680122,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 713385,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 637505,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584135,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683106,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 490005,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 241203,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509320,
        "city": "Kalwakurthy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680003,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 695007,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 342015,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835217,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586215,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629160,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494332,
        "city": "Kanker",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 638502,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671123,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754010,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246487,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360370,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 824203,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416703,
        "city": "Talere",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 759116,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 493332,
        "city": "Baloda Bazar",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 285206,
        "city": "Konch",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686698,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 443201,
        "city": "Amdapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521156,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171206,
        "city": "Jubbal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 142055,
        "city": "Bhadaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781134,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 532401,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176055,
        "city": "Khundian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 391320,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 685587,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 365560,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 813228,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 247665,
        "city": "Roorkee",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 508205,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385010,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577427,
        "city": "Shikaripur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521134,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 822133,
        "city": "Garhwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360018,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 608702,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605202,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843162,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 505460,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505208,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600075,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 682023,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 636351,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621105,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333040,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 504203,
        "city": "Khanapur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 222161,
        "city": "Mariahu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641107,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 284129,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841405,
        "city": "Gopalganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635120,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620023,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700036,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212207,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410203,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 768103,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411107,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 625528,
        "city": "Bodinayakanur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 587316,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631152,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534402,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 690502,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 736181,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625540,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110502,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 208008,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 362150,
        "city": "Talala",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600059,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 613101,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151105,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 227802,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 736122,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412202,
        "city": "UrliKanchan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141305,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 223225,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148109,
        "city": "Barnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713513,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680542,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 410511,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 122208,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 410220,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521345,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362205,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413104,
        "city": "Baramati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 143113,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506333,
        "city": "Narsampet",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 835324,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416304,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 637201,
        "city": "Tiruchengode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411043,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 625529,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 800021,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 507305,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 233221,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518411,
        "city": "Nandikotkur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517352,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 445110,
        "city": "Digras",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416605,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 671541,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 181132,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 177401,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742180,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700054,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781316,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 630309,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847120,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 494776,
        "city": "Pakhanjur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522439,
        "city": "Macherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517536,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573122,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 497235,
        "city": "Bhatgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 382014,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 333053,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431130,
        "city": "Georai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 523371,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741126,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621305,
        "city": "Manapparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524203,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734201,
        "city": "Bijanbari",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670562,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500014,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384212,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 423301,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 262305,
        "city": "Pilibhit",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 846006,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 845459,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603201,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 474020,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 572135,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574201,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713518,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250205,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741248,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500762,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 455001,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 464774,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 612505,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 642122,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221001,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 244925,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396350,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 641016,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122227,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 111112,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711416,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572132,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142060,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627716,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388306,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 509217,
        "city": "Shadnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 611110,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502125,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822120,
        "city": "Kandi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521170,
        "city": "Madhira",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603303,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380013,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 711109,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272151,
        "city": "Rudhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607101,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591248,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365645,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577321,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147006,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637303,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209729,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227106,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 638656,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741152,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 816115,
        "city": "Sahebganj",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 306301,
        "city": "Bilara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757093,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799290,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 342018,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396130,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 563124,
        "city": "Bagepalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 282005,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843327,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591305,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630561,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642127,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152112,
        "city": "Malout",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676556,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 741318,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 401401,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 700135,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 209867,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221104,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713165,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 313013,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 757052,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 224195,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577430,
        "city": "Sagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110005,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 782010,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 768002,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 152023,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 561201,
        "city": "Magadi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 461114,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 396460,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686021,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 396031,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 621010,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171102,
        "city": "Darlaghat",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124139,
        "city": "Jhajjar",
        "state": "Haryana",
        "Zone": "N2"
       },
       {
        "pincode": 341520,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 676510,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415101,
        "city": "Bambavade",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500069,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334306,
        "city": "Bikaner",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 176091,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 135133,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742308,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 637402,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495557,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 160013,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 787053,
        "city": "Dhakuakhana",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 466651,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 816101,
        "city": "Barharwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 815355,
        "city": "Kundahit",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 685620,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 322238,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673309,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577127,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680564,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 758021,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 243641,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636354,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303704,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522318,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770013,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176053,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 364720,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 140201,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534145,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685565,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500036,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799286,
        "city": "Kamalpur",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 643206,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210425,
        "city": "Muskara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758025,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631553,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754001,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635303,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415613,
        "city": "Ganpatipule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 803101,
        "city": "Bihar Sharif",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761045,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639114,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600019,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627131,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688554,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 496551,
        "city": "Baramkela",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 733134,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 457002,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 173208,
        "city": "Darlaghat",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685615,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 124146,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676309,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 392003,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 462045,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 241121,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396040,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 788726,
        "city": "Patharkandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 224133,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221010,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 389352,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 580027,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 172116,
        "city": "Pooh",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 753127,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614804,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 192102,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 570029,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 622001,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801112,
        "city": "Bihta",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685505,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 482003,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 643007,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 389130,
        "city": "Limkheda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 827302,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507169,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322224,
        "city": "Bonli",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 713172,
        "city": "Panagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 601203,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625107,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 324009,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273162,
        "city": "Brijmanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800029,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382860,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 766027,
        "city": "Muniguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387345,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 670651,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 285205,
        "city": "Konch",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678592,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506163,
        "city": "Rayaparthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173202,
        "city": "Dharampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755003,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151005,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500015,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 770042,
        "city": "Koira",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624601,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679588,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396427,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 735231,
        "city": "Jholung",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 458669,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 782135,
        "city": "Puranaigudam",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 263137,
        "city": "Bhimtal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764063,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641015,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416603,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 680595,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 673592,
        "city": "Sultan Bathery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396051,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 485771,
        "city": "Maihar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 690010,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 642129,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 341031,
        "city": "Merta",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273155,
        "city": "Anandnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848101,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761028,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825315,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600065,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 829114,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415709,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 396580,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 854311,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786158,
        "city": "JaytishNagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 628712,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410410,
        "city": "Lonavala",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 686016,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 362269,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 638052,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229881,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691001,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756129,
        "city": "Tihidi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 244235,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244302,
        "city": "Sambhal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 832107,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606804,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 223104,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 284406,
        "city": "Mahrauni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 445216,
        "city": "Pusad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 403518,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 452015,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 570014,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 441924,
        "city": "Bhandara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 752014,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584140,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503111,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382345,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 626003,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411033,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577244,
        "city": "Sagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679304,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754218,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 532457,
        "city": "Kotturu-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413214,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574248,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689643,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686692,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843109,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 497116,
        "city": "Ambikapur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 851111,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639101,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761208,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 207120,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212658,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562122,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301411,
        "city": "Tijara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606702,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533261,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 450337,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 609310,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243303,
        "city": "Shahabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 833217,
        "city": "Noamundi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425203,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521123,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143533,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678573,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 503302,
        "city": "Yellareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585423,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530051,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 394235,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 825402,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500486,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 501121,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505405,
        "city": "Sircilla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770025,
        "city": "Sundargarh",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 132145,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 325001,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416236,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 509385,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799289,
        "city": "Aambasa",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 391440,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 444713,
        "city": "Daryapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 535126,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786613,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 802130,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 576102,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574265,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606203,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411010,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 182311,
        "city": "Reasi",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425428,
        "city": "Shirpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 629102,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361011,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 560044,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 140604,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 504205,
        "city": "Jannaram",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523369,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522002,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160028,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141033,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175040,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 335038,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 233229,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 328037,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 802204,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784144,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 572141,
        "city": "Pavagada",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676519,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628211,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491995,
        "city": "Kawardha",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 531126,
        "city": "Tuni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502130,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 458558,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 686564,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 305406,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 723103,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 473770,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 711101,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262722,
        "city": "Dhaurahara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382021,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 712413,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518166,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680305,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 786101,
        "city": "Tengakhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 533008,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643209,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431135,
        "city": "Sillod",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 231312,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678554,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 522657,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788805,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 384003,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 143532,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582123,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174017,
        "city": "KalolHP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695616,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 414603,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 731222,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786145,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 695003,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 731224,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841203,
        "city": "Raghunathpur-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 282009,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 335804,
        "city": "Pilibanga",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312022,
        "city": "BassiRJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802178,
        "city": "Arrah",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 413304,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271826,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 393030,
        "city": "Hansot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 301416,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852126,
        "city": "Sonbarsa-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321607,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673305,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 487221,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 785614,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 571128,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 133105,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262411,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 226022,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585412,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209746,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110607,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 444402,
        "city": "Mangrulpir",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 212216,
        "city": "Saray Ankil",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624204,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147111,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803206,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 848102,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507210,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700123,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 313901,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571108,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306401,
        "city": "Pali",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 822122,
        "city": "Panki",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532462,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431143,
        "city": "Georai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 123504,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305817,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262551,
        "city": "Didihat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 509201,
        "city": "Achampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577140,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302027,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534186,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 207126,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 202170,
        "city": "Sikandra Rao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400611,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 329090,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743289,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212641,
        "city": "Sirathu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 822102,
        "city": "Daltonganj",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 516362,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517113,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758028,
        "city": "Ghatgaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500038,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 422102,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627304,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626205,
        "city": "Sattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785005,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 496118,
        "city": "Pathalgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 625512,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 483113,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 413706,
        "city": "Rahuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695313,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523226,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332303,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262702,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 223224,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591303,
        "city": "Ugar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122211,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 181123,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761025,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642130,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606703,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501143,
        "city": "Tandur-TL",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521158,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572111,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362510,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 682052,
        "city": "Aluva",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 762010,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574111,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841507,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695131,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 691522,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 483775,
        "city": "Vijayraghavgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 122223,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600114,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 203402,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312612,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462015,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 493116,
        "city": "Tilda",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 500068,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532221,
        "city": "Palasa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852216,
        "city": "Saharsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754143,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 305628,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636305,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273211,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680511,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 440025,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 229135,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785106,
        "city": "Majuli",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 441806,
        "city": "Sadak Arjuni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 203209,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517571,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313025,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 302010,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605010,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583124,
        "city": "Kudligi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623202,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573118,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431131,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 733210,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 800111,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388550,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 160024,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 816118,
        "city": "Shikaripara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752109,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221313,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759104,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 800022,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691554,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802216,
        "city": "Malyabag",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246723,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 602021,
        "city": "Tiruvallur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785101,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 160016,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 825335,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415003,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 442003,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 756132,
        "city": "Chandbali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785687,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 346004,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 733130,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 131310,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627453,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781101,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 402304,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695038,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 530009,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691508,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 364565,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 440017,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 246726,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175012,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 723144,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403202,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 301412,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176103,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 242225,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 584129,
        "city": "Kowthal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229405,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508204,
        "city": "Huzur Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614619,
        "city": "Manamelkudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 601102,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673593,
        "city": "Sultan Bathery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 630210,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 314038,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493228,
        "city": "Baloda Bazar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 690552,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 248196,
        "city": "Chakrata",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625117,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 202118,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 415111,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 735235,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 802302,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 185131,
        "city": "Rajouri",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 303905,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515611,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177501,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396065,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224206,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495686,
        "city": "Champa",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 321022,
        "city": "Kaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522213,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 516411,
        "city": "Chagalamarri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 482020,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 281503,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 521214,
        "city": "Vissannapetaa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689125,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 210129,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721404,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754226,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680103,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 365535,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 422607,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721242,
        "city": "Kamarpukur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686038,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 431004,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 148107,
        "city": "Barnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 211002,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781305,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 401105,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 733153,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625001,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388131,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 416536,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 444701,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 486886,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 639102,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673029,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 212205,
        "city": "Sirathu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796772,
        "city": "Chawngte",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 521402,
        "city": "Bonakal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695306,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 388450,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 516107,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761043,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609108,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784105,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 171210,
        "city": "Nerwa",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415202,
        "city": "Mandangad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176324,
        "city": "Bharmour",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272271,
        "city": "Menhdawal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 458001,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 757050,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 620007,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522258,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142011,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691519,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756134,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784177,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 148017,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 590011,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630005,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571122,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585333,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385007,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 123503,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678501,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 305412,
        "city": "Nasirabad",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854208,
        "city": "Purnia",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 571438,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244003,
        "city": "Sambhal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151103,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503108,
        "city": "Yellareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788005,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 847231,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 464770,
        "city": "Udaipura",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 783314,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 563121,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402116,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 322702,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571126,
        "city": "Gundlupet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110033,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 841428,
        "city": "Gopalganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335523,
        "city": "Nohar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695130,
        "city": "Kallikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600010,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 212011,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691528,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 175048,
        "city": "Thunag",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632204,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517621,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522311,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176083,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423402,
        "city": "Yeola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574101,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700028,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394170,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 683502,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 176219,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741255,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 732213,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752094,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 751008,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 422101,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 700061,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 241501,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609401,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505001,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 473781,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 795132,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679584,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 246155,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600121,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403111,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 785636,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 134105,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 804425,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 383410,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 502290,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464668,
        "city": "Bareli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 321206,
        "city": "Nagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 461122,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 686642,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 385130,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 302037,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516269,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679533,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 495335,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 643105,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624209,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174401,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 344023,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160012,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521331,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521255,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384325,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 743122,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828128,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691303,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 421602,
        "city": "Igatpuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 403718,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 509130,
        "city": "Atmakur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301019,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515201,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788152,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 574148,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522549,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534126,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382780,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 621210,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843317,
        "city": "Sonbarsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 487337,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 813206,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638311,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382422,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 412208,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673617,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416814,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 312621,
        "city": "Pratapgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522515,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847108,
        "city": "Phulparas",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534250,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 360045,
        "city": "Amreli",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 785109,
        "city": "Bokakhat",
        "state": "Assam",
        "Zone": "NE"
       },
       {
        "pincode": 143303,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250221,
        "city": "Daurala",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518222,
        "city": "Dhone",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457333,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 341512,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686037,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 262001,
        "city": "Pilibhit",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271801,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713363,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184101,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783331,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 134003,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210206,
        "city": "Chitrakoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 492009,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577553,
        "city": "Jagaluru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521139,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 360576,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 683542,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 201300,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625205,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614801,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 452010,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 824114,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782428,
        "city": "Kampur Town",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 524142,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121013,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700063,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 734406,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415606,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 611108,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522018,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500105,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 453555,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 172110,
        "city": "Pooh",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721432,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 208011,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 805121,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 530020,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505473,
        "city": "Bejjanki",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 607103,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723127,
        "city": "Balarampur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627359,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585322,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691515,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 767048,
        "city": "Patnagarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 682303,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 224190,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387435,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 802215,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 222181,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712417,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444708,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303326,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 275306,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321024,
        "city": "Nagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212213,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845454,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 232103,
        "city": "ChakiaUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523241,
        "city": "Podili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606611,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303006,
        "city": "Dudu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303907,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688540,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600090,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 523281,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385520,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686504,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 813207,
        "city": "Sangrampur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500004,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 695316,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 343048,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 421405,
        "city": "Thane",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 533440,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335024,
        "city": "Raisinghnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591318,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680307,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 504001,
        "city": "Adilabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770020,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410101,
        "city": "Karjat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517299,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431710,
        "city": "Dharmabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 803108,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 346002,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585416,
        "city": "Basavakalyan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416008,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743613,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151006,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263634,
        "city": "Bageshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 177112,
        "city": "Chintpurni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518346,
        "city": "Alur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388239,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 676109,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843101,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209871,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627603,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362630,
        "city": "Manavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 392026,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416531,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 600042,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 786146,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 841256,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 486775,
        "city": "Rampur Naikin",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 795138,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 723149,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628617,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530028,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431703,
        "city": "Hingoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 637209,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 769014,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 636812,
        "city": "Denkanikottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425102,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682319,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 261131,
        "city": "Sitapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230121,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301403,
        "city": "Khairthal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841205,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700027,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 572216,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829117,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761027,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722180,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415303,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 632402,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641404,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444311,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577417,
        "city": "Sagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700082,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 804435,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627817,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141104,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209304,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203396,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768001,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 124416,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 587125,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591127,
        "city": "Ramdurg",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402120,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 741185,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504310,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581334,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473662,
        "city": "Karera-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 721634,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516172,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803118,
        "city": "Bihar Sharif",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 314029,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 791111,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305816,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509219,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273304,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 445210,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 500060,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688009,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 151508,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 685535,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 441907,
        "city": "Tumsar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 532440,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171010,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841305,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638466,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799010,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 143511,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151102,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 233308,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 585329,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622501,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803221,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 224126,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784001,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 591213,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796001,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 614616,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285125,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786152,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 180011,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 335802,
        "city": "Hanumangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627751,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 610111,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464672,
        "city": "Silwani",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781313,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 630311,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516163,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695029,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 152129,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387620,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691520,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 275203,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686651,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 247656,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574110,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 389365,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 606901,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505325,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171218,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 590004,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229406,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689112,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 326515,
        "city": "Chaumahla",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425509,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 494001,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 686145,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 743194,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396510,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 574279,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263632,
        "city": "Bageshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425310,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176313,
        "city": "Nurpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 146106,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800008,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 396007,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 413582,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500066,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 441613,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721232,
        "city": "Ghatal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176041,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 391510,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 221504,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 224176,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 450001,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 497331,
        "city": "Patna-CH",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 711001,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 342027,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712148,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683555,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 456441,
        "city": "Alote",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 721446,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700115,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713367,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 562158,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 443102,
        "city": "Malkapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 271126,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413409,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 587205,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414304,
        "city": "Parner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 742137,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502103,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591113,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 180006,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 530012,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160061,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689126,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 782429,
        "city": "Hojai",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 125104,
        "city": "Mandi Dabwali",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423605,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382352,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413524,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 854115,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759122,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 847203,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507304,
        "city": "Bonakal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 488051,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 176305,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143603,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 824115,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695586,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 146115,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848301,
        "city": "KalyanpurBR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 805101,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534341,
        "city": "Gopalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160103,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628219,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627303,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 232330,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673014,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 743351,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841227,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735122,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 829126,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686603,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 754013,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742185,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226027,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 506221,
        "city": "Alair",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322202,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244504,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 273161,
        "city": "Anandnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584170,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413105,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 532404,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843324,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 457885,
        "city": "Alirajpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 277210,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505304,
        "city": "Yellareddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532409,
        "city": "Ranastalam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843145,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 600072,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679572,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 787058,
        "city": "Dhemaji",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 760011,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 470339,
        "city": "Shahgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 620024,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 332042,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442004,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400094,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 671531,
        "city": "Kanhangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635854,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411061,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 415305,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 191113,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361101,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 411023,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 243630,
        "city": "Dataganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732144,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534315,
        "city": "Polavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788168,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 442501,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638183,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 753004,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691311,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 796421,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 612902,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534338,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506343,
        "city": "Mulugu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799142,
        "city": "Santirbazar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 132118,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841202,
        "city": "Dighwara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 821111,
        "city": "Kudra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 307526,
        "city": "Abu Road",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 670581,
        "city": "Alakode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788006,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 422110,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209205,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 325216,
        "city": "Kelwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246747,
        "city": "Dhampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441213,
        "city": "Pune",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 132103,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815313,
        "city": "Tisri",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 828305,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799022,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 306912,
        "city": "Sumerpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768040,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262203,
        "city": "Pilibhit",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140402,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 851219,
        "city": "Sahebpur Kamal",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360490,
        "city": "Upleta",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 782446,
        "city": "Lanka",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 274306,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638001,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 759148,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695610,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 123303,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785015,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 431718,
        "city": "Degloor",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 396201,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224229,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273003,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532403,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143110,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673607,
        "city": "Areacode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 848210,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 462002,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 344026,
        "city": "Kalyanpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627720,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272205,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574165,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581113,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 305402,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222145,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503306,
        "city": "Banswada",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722174,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 392220,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 563159,
        "city": "Chintamani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 855115,
        "city": "Bahadurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609307,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638451,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177209,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362625,
        "city": "Manavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 328027,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673023,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 142054,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 146110,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396125,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 505330,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683574,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641606,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396325,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 389190,
        "city": "Jhalod",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 711323,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721301,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636030,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143401,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470771,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 231219,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464113,
        "city": "Berasia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 814124,
        "city": "Shikaripara",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 572202,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642004,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580030,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382443,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 673027,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 261136,
        "city": "Laharpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782102,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 700150,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 311601,
        "city": "Mandalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143301,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 493888,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 482008,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 384002,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 680544,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 787060,
        "city": "Jonai",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 524323,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175138,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679308,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 845437,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609807,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678007,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 679564,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628903,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712233,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 671317,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 247774,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 392004,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 283112,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572215,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322241,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 152114,
        "city": "Malout",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581230,
        "city": "Hubli",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 605109,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 312208,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630565,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209310,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562201,
        "city": "Magadi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757046,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581360,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442406,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591226,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364120,
        "city": "Talaja",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 123414,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627431,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686535,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 445201,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 621722,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140406,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688547,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670017,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444304,
        "city": "Nandura",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 533461,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127046,
        "city": "Siwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841404,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752006,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362715,
        "city": "Kodinar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 575021,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 190012,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 173001,
        "city": "Nahan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802237,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144409,
        "city": "Phillaur",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 207241,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 622404,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781360,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 416221,
        "city": "Rashiwade Bk",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 277501,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391244,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 833214,
        "city": "Jagannathpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423401,
        "city": "Yeola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176101,
        "city": "Dheera",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626129,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629051,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581121,
        "city": "Alnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243006,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621018,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688558,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457887,
        "city": "Alirajpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 609314,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560036,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 313329,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635116,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160022,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 465118,
        "city": "Polay Kalan",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 803213,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 233300,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761104,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422201,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 623806,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431205,
        "city": "Georai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 422202,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 327022,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321026,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507119,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327603,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799251,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 393041,
        "city": "Dediapada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 574228,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803110,
        "city": "Barh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 303310,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202526,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577116,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382320,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 742303,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125053,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632012,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 801104,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505470,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848129,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783120,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 423208,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 261203,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271003,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 770034,
        "city": "Rajgangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627127,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629152,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768108,
        "city": "Deogarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 562130,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686506,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 782490,
        "city": "Bokajan",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 793021,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 754027,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721157,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144212,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313207,
        "city": "Kapasan",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450661,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 421104,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 847112,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362140,
        "city": "Talala",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 629172,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382023,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 441906,
        "city": "Bhandara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 380007,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 518350,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516361,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141307,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 671320,
        "city": "Kanhangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 531148,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390021,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 735216,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 759015,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591110,
        "city": "Saundatti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142043,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424310,
        "city": "Sakri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 333023,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695006,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 474001,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 508245,
        "city": "Yacharam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678571,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 110006,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 416305,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686545,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 825325,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 821004,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416104,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263637,
        "city": "Someshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 524316,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383305,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 753007,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691329,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444809,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 126152,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413314,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 274308,
        "city": "Padrauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632401,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344031,
        "city": "Dhorimana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402303,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431137,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621209,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 737116,
        "city": "Mangan",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 731144,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700108,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 683561,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756034,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444914,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689507,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756164,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600044,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230136,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621804,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500002,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 424311,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 382463,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 471105,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 571424,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571437,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400010,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 314404,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 639117,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573219,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274603,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534339,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413705,
        "city": "Rahuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682306,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 274404,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515341,
        "city": "Gorantla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457114,
        "city": "Alote",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 630552,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 821301,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176036,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160071,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 342606,
        "city": "Gotan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680014,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 262804,
        "city": "Mohammadi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686006,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 221207,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141202,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586214,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695572,
        "city": "Kallikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 733151,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673016,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 585427,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 245101,
        "city": "Hapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635753,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362266,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 222136,
        "city": "Jalalpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222146,
        "city": "Jalalpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143306,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517127,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384360,
        "city": "Satlasana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 332302,
        "city": "Fatehpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497119,
        "city": "Ramanujganj",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781004,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 174309,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110125,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 202131,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402401,
        "city": "Murud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 695541,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600092,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 602023,
        "city": "Tiruvallur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516214,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382003,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 612201,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711202,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 276401,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637107,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 800027,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711328,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274405,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637304,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573220,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401305,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754250,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509135,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678572,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600094,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 766029,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 206242,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533352,
        "city": "Nellipaka",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752082,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689518,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 570012,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 110115,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680551,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 230403,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785010,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 782003,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 609609,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226001,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 380062,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 604410,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742306,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785112,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 303712,
        "city": "Chomu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382825,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 851101,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531111,
        "city": "Chintapalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639104,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626512,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335701,
        "city": "Anupgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 823005,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574259,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244413,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522101,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 791123,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 845419,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518405,
        "city": "Nandikotkur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226501,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144224,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515401,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502228,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521137,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 769011,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638702,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689122,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627802,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205262,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462034,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 676308,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 609312,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626122,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635122,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122013,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788161,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 495009,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 799211,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 392025,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 799004,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 249124,
        "city": "Tehri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731139,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206255,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403806,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 700095,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581201,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141112,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 302028,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 483332,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 390001,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 639111,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533233,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423107,
        "city": "Shirdi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502284,
        "city": "Hyderabad",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 781034,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 821115,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 392001,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 142630,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 800019,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 814146,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 492101,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 533006,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451335,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 263150,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175046,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 482053,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 622103,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636904,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465695,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785672,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 494449,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 761111,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415512,
        "city": "Rahimatpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628221,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680317,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 229207,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403201,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416502,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 284204,
        "city": "Mauranipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516439,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735301,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682019,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 842101,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591263,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801110,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 843104,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625531,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 452004,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 503180,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232119,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583226,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574234,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813204,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144002,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 784114,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 517280,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243125,
        "city": "Faridpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382250,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 844127,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 821310,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629101,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441106,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721424,
        "city": "Belda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626116,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486661,
        "city": "Sidhi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 445203,
        "city": "Digras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 532128,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177104,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854318,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 461661,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 516444,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365601,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 313905,
        "city": "Sarada",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411022,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 585213,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313603,
        "city": "Bhatewar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 484116,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 523104,
        "city": "Singarayakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630006,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389220,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 425420,
        "city": "Amalner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 600011,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 586127,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416503,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 314406,
        "city": "Simalwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682021,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 845422,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 142042,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628622,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721627,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 191101,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221109,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 688546,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 274207,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600107,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629162,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221209,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202141,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609804,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841506,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 360007,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 754009,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678593,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 172201,
        "city": "Rampur Bushahr",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332026,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110037,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 396385,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577434,
        "city": "Sagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637502,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388180,
        "city": "Tarapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 110017,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 623539,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813202,
        "city": "Sangrampur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212507,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495001,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 504109,
        "city": "Bhainsa",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410301,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 135105,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 586204,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785111,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 504204,
        "city": "Chinnur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 834012,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 580010,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802120,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638752,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412212,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 501510,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 794104,
        "city": "Phulbari",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 825407,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614710,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323616,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585328,
        "city": "Bhalki",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362550,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 586103,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721258,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110083,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 574169,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785666,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 581339,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143021,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741202,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 181206,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535006,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762026,
        "city": "Boudh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 127030,
        "city": "Loharu",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144014,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761014,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605301,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311028,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670721,
        "city": "Pulpally",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 272170,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 501359,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632010,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689645,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 623520,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752012,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 764070,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 843120,
        "city": "Sahebganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713339,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 496113,
        "city": "Lailunga",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754130,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623515,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591223,
        "city": "Ugar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781039,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 641112,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462066,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 722205,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624202,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591242,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143118,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441504,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 421203,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364004,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 712402,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804430,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 580020,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 343042,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515812,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735305,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841422,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400401,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 274203,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313202,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 172026,
        "city": "Anni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 609113,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392002,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 821308,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403408,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 741511,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246167,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 241303,
        "city": "Madhoganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841212,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679514,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 331024,
        "city": "Fatehpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382442,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 385535,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 250104,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110064,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 412411,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 535220,
        "city": "Garividi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752011,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695544,
        "city": "Nedumangad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144601,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263147,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "Zone": "N2"
       },
       {
        "pincode": 391745,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 533239,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628601,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342010,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175051,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 152113,
        "city": "Giddarbaha",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 304005,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571213,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 477333,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 743249,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444405,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581104,
        "city": "Hangal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500088,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 261208,
        "city": "Laharpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 639005,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785686,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 224122,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581106,
        "city": "Byadagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560048,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560242,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517325,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801117,
        "city": "Bihta",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 221105,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673020,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 360577,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 304803,
        "city": "Deoli-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 458474,
        "city": "Neemuch",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 222137,
        "city": "Mariahu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363745,
        "city": "Viramgam",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 460001,
        "city": "Betul",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 160008,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 583213,
        "city": "Harpanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283126,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785645,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 174302,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209743,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382340,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 314001,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 414201,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416544,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 284002,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700094,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 829109,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 326520,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 484440,
        "city": "Kotma",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 691006,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 627002,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743429,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160007,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305203,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783312,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 713168,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515282,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764085,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 671551,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 573120,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281003,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 784112,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 781307,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 606603,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686019,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 632105,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 193202,
        "city": "Sopore",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515801,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635815,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685585,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400162,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 493555,
        "city": "Saraipalli",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 249201,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635125,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 500025,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 734001,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641028,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 521230,
        "city": "Mylavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313611,
        "city": "Dhariyawad",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 136035,
        "city": "Pehowa",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 751025,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627352,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 142034,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627006,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805130,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584125,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583241,
        "city": "Bellary",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 261121,
        "city": "Maholi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363642,
        "city": "Morbi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 275205,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623102,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 796770,
        "city": "Chawngte",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 454111,
        "city": "Sardarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 181205,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 332304,
        "city": "Ratangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752038,
        "city": "Gopalapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 145021,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 370660,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 762023,
        "city": "Puranakatak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 802127,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743233,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 443002,
        "city": "Buldhana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442901,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 630201,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689111,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 586210,
        "city": "Kolhar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591215,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673515,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400079,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 829135,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631101,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751015,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 384310,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 733215,
        "city": "Dalkhola",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 208025,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534456,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682028,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 425126,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 486004,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 638051,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534002,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609102,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380011,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 524343,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628908,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577006,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713162,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416406,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500028,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414602,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627651,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473249,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 670310,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 471202,
        "city": "Khajuraho",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 812003,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 636402,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212631,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743363,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670006,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416232,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 676506,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 146114,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416125,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 531020,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 212203,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756130,
        "city": "Tihidi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416505,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712401,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403112,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584203,
        "city": "Manvi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573117,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689104,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 736203,
        "city": "Kumargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226014,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415012,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 387210,
        "city": "Tarapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 609808,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673121,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 148002,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686531,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 621805,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480357,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 174029,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532458,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 842003,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144518,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 392013,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 563138,
        "city": "Srinivaspur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502291,
        "city": "Sadashivpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509350,
        "city": "Kosgi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322240,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262728,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151205,
        "city": "Kotkapura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741123,
        "city": "Chapra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679504,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 395021,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 787061,
        "city": "Silapathar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 380060,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 414604,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638105,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522438,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700153,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 682042,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 146104,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670325,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 522257,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500062,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 262124,
        "city": "Puranpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560125,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508114,
        "city": "Ramannapeta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151504,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 452003,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 301026,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491229,
        "city": "Manpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 124101,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572137,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754246,
        "city": "Garjanpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 283111,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271124,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560035,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754213,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722175,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363423,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 110040,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680701,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 785621,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 695099,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 835208,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 686104,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 524221,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361162,
        "city": "Kalavad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 401606,
        "city": "Talasari",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 801507,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 851205,
        "city": "Khagaria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847104,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123034,
        "city": "Mahendragarh-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244924,
        "city": "Rampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682051,
        "city": "Aluva",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 246731,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 671326,
        "city": "Parappa",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 782126,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 506164,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182200,
        "city": "Bhaderwah",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637408,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743375,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534448,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125060,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722177,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516359,
        "city": "Chagalamarri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641113,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243001,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 494336,
        "city": "Charama",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 274801,
        "city": "Khadda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759102,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781171,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 607105,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493118,
        "city": "Bhatapara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 788123,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 802315,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110057,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 854306,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 508211,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140401,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 207505,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388544,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 440027,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413217,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743505,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518508,
        "city": "Velgodu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327034,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 454001,
        "city": "Dhar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 848139,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621310,
        "city": "Manapparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263126,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411042,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 249001,
        "city": "Tehri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700092,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673311,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273157,
        "city": "Brijmanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 762030,
        "city": "Kantamal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 190001,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 493114,
        "city": "Tilda",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 441108,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841302,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 573226,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 535160,
        "city": "S Kota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431606,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 484224,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754284,
        "city": "Salipur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 509401,
        "city": "Achampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524405,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370230,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 210506,
        "city": "Muskara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614629,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742175,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825311,
        "city": "Barkagaon",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524315,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232341,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522647,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143538,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 453777,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 603403,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125121,
        "city": "Barwala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530044,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680694,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641010,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401100,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 229801,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 132107,
        "city": "Safidon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585403,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641064,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176057,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400111,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 723131,
        "city": "Manbazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441909,
        "city": "Bhandara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 132123,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495690,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 176211,
        "city": "Nurpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521228,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 225301,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605103,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680507,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635652,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613053,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 332707,
        "city": "Ajeetgarh",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682016,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413569,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 586217,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533285,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384265,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 229208,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303103,
        "city": "Paota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679521,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415618,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 122216,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 683589,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506169,
        "city": "Bhupalpally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627111,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416623,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 768102,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 785656,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 507120,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302006,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 731147,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623110,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799287,
        "city": "Kamalpur",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 416301,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 472005,
        "city": "Tikamgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 230130,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464226,
        "city": "Vidisha",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 494334,
        "city": "Kanker",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 134002,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 312403,
        "city": "Bari Sadri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 385350,
        "city": "Rapar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 415601,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 587207,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416415,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 573114,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230141,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416401,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 465220,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 250404,
        "city": "Mawana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225407,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560089,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 422205,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518463,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311204,
        "city": "Asind",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321606,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 685507,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 791109,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 688564,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 458668,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 523167,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757103,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 400614,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 575003,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 759131,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144418,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623703,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811301,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271605,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222202,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627857,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509218,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712224,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679516,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 782401,
        "city": "Jorabat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 209121,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246428,
        "city": "Gairsain",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431512,
        "city": "Vasmat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 136134,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400075,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431141,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305929,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802221,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 723102,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581103,
        "city": "Alnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413404,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 813203,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384330,
        "city": "Satlasana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 570025,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 612610,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673009,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 758013,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 224228,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141120,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411017,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400088,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 174027,
        "city": "Ghumarwin",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785682,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 140113,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713373,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686511,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515581,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361002,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 577524,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507122,
        "city": "Yellandu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221403,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175007,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 636110,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673024,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 207501,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362530,
        "city": "Girgadhada",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 271127,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 173104,
        "city": "Haripurdhar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 782462,
        "city": "Diphu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 782136,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 761124,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606123,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 608704,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416704,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 177045,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 763004,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695608,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 204211,
        "city": "Sikandra Rao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230404,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400080,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 122230,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743389,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691011,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 431807,
        "city": "Bhokar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621008,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174103,
        "city": "Baddi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 722209,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712311,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 473332,
        "city": "Ashok Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 520006,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577451,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695132,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670603,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 831004,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 209301,
        "city": "Akbarpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788115,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 175126,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131306,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759146,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 483773,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 683104,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 508279,
        "city": "Tungaturthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690535,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507158,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224157,
        "city": "Faizabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581321,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416540,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 518432,
        "city": "Nandikotkur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673647,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 224188,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242402,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 465339,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 756009,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416409,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 798620,
        "city": "Zunheboto",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 328041,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 733127,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621218,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 502270,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734004,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415213,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 455115,
        "city": "Dewas",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 480990,
        "city": "Seoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 762013,
        "city": "Puranakatak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560059,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 815357,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534303,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743234,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 573131,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614820,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415617,
        "city": "Ganpatipule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628251,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388340,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 631605,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274408,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400039,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 177220,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560001,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 500046,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 623317,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387365,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 562102,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573119,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 190011,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713425,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 182204,
        "city": "Kishtwar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841311,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 322214,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560051,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680594,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 626143,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625305,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230001,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228172,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515661,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 561228,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172001,
        "city": "Rampur Bushahr",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 362001,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 792103,
        "city": "Namsai",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 465001,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 385510,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 335061,
        "city": "Raisinghnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629253,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604503,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722107,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 306504,
        "city": "Pindwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679334,
        "city": "chungatra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 626138,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573218,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416019,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 635111,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500003,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 402104,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 477445,
        "city": "Lahar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 783336,
        "city": "Gossaigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 143515,
        "city": "Sri Hargobindpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635702,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785623,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 765026,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758017,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 242303,
        "city": "Dataganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636501,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628217,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695081,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 762016,
        "city": "Sonepur-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 515164,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 611111,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757084,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505002,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 639006,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 787001,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 443401,
        "city": "Nandura",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 798626,
        "city": "Noklak",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 607803,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764076,
        "city": "Umerkote",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721435,
        "city": "Belda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700067,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670144,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 688555,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689616,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 563102,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209651,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229307,
        "city": "Gauriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110059,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 504206,
        "city": "Luxettipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306709,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742173,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683517,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583201,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583123,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502108,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741174,
        "city": "Katwa",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 224129,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 248147,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635201,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782482,
        "city": "Bokolia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 389152,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416311,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678704,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425108,
        "city": "Chopda",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 421504,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 244226,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626137,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 827011,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533238,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391530,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 520003,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230140,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143502,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584104,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127306,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670015,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679536,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382750,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 752091,
        "city": "Daspalla",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444105,
        "city": "Karanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 753141,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 148019,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585417,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224116,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 485115,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 689625,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 623518,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722162,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600098,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122414,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695506,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 333041,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 343025,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743387,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396108,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721128,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505476,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767001,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632312,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678009,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 248158,
        "city": "Chakrata",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140507,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431007,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 533250,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517370,
        "city": "B Kothakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243401,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638301,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413607,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 283121,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574103,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134012,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641043,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442916,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 688531,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 140108,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 245408,
        "city": "Hapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721431,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 203409,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800010,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625020,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384261,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521124,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802183,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682511,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 148102,
        "city": "Bhadaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141117,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122050,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700014,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560111,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680018,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176209,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313903,
        "city": "Sarada",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 770035,
        "city": "Birmitrapur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 501202,
        "city": "Mominpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495446,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 627756,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400601,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 506319,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311026,
        "city": "Asind",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721149,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 831009,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 631601,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673124,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 414202,
        "city": "Ashti",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 212501,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686509,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 272129,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641061,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263645,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313338,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 383205,
        "city": "Prantij",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721123,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627428,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494228,
        "city": "Kondagaon",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 732122,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574150,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813233,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781310,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 607109,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683571,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 177207,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202130,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625019,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636903,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506010,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848226,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332043,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781011,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 506366,
        "city": "Mogullapally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 753021,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 733155,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 246474,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642111,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533254,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321617,
        "city": "Mahwa",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 826007,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506318,
        "city": "Thirumalagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577146,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686024,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174024,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 477222,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 700084,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 232326,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422103,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273015,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 804428,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 491665,
        "city": "AmbagarhChowki",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 630212,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 181008,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752037,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591107,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444002,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 516152,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311407,
        "city": "Shahpura",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627752,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741316,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 445109,
        "city": "Ghatanji",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 711410,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721172,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584103,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686679,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 848502,
        "city": "Mohiuddin Nagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403705,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 600036,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625123,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689516,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 461115,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 388308,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 722143,
        "city": "Mejia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415309,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 341022,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403517,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 792130,
        "city": "Khonsa",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 456771,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 383341,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 231309,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441110,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431810,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 609803,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335040,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682013,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144023,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 313801,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491441,
        "city": "Rajnandgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 422204,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 322030,
        "city": "Bonli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148103,
        "city": "Barnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411048,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 151301,
        "city": "Talwandi Sabo",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614702,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682510,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 797117,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 494224,
        "city": "Bastar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 532432,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532443,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733102,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384241,
        "city": "Harij",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 380022,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 232331,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563134,
        "city": "Srinivaspur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505481,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829208,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 400074,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 822131,
        "city": "Hariharganj",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756029,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825330,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 491444,
        "city": "Khairagarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 332041,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802217,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 224001,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 760002,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 277122,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624052,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 624610,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680575,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847105,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414004,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 384272,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522611,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 461446,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 458330,
        "city": "Jawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700090,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 425003,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 784160,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 801106,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 722176,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 480554,
        "city": "Junnardeo",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 496554,
        "city": "Baramkela",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 403808,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788830,
        "city": "Mahur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679562,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 361347,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 302034,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508101,
        "city": "Alair",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124001,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 572225,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535204,
        "city": "Bhogapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201102,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 233230,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585302,
        "city": "Aland",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110085,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680001,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 382450,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 480667,
        "city": "Seoni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 520012,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 471201,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 632604,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321028,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131001,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 732214,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341301,
        "city": "Khatu Khurd",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224166,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503212,
        "city": "Navipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403701,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176040,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621012,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508208,
        "city": "Nereducharla",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 752113,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 802163,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 365665,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 711311,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621208,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462031,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 534211,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176075,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609602,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560074,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 641104,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574102,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175143,
        "city": "Manali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673314,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 630001,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134011,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788931,
        "city": "Umrangso",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 624007,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243123,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741235,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 683586,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 412413,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209101,
        "city": "Akbarpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636117,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516223,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762015,
        "city": "Sonepur-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 678104,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 785689,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 766103,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 535213,
        "city": "Bhogapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 452014,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 180015,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 423206,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 395004,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 695605,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 333001,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 590008,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 521356,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303209,
        "city": "Jaipur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 627361,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626133,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521227,
        "city": "Vissannapetaa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221717,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722153,
        "city": "Mejia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560080,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 271304,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391770,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 581196,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 245401,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 451447,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 344027,
        "city": "Kalyanpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425101,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 504304,
        "city": "Boath Buzurg",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572125,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571425,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246149,
        "city": "Kotdwara",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713409,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781308,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 382013,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 227806,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394720,
        "city": "Ahwa",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 246001,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 855107,
        "city": "Kishanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403530,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 636807,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396235,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 743195,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628904,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272181,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160002,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 440031,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431508,
        "city": "Mantha",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788101,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 495691,
        "city": "Dabhra",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 764048,
        "city": "Malkangiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 804453,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507209,
        "city": "Wyra",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691012,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 123411,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626001,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394517,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 534437,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462036,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 415313,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 251203,
        "city": "Khatauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741256,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 753003,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 331306,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176033,
        "city": "Nagrota Surian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 636012,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609404,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524004,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673105,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 755009,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854337,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303304,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227805,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227304,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532264,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680641,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176043,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600119,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 185121,
        "city": "Poonch",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521003,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621312,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250345,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 192129,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560030,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532501,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465337,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 743515,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 637501,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 225415,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411050,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 276127,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758002,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 630206,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 173234,
        "city": "Waknaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 576221,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524412,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813104,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673103,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 152101,
        "city": "Giddarbaha",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522426,
        "city": "Macherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506146,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695602,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 233001,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583211,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205130,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 476554,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695525,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627010,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 146116,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612802,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731130,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574189,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521323,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828209,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 171008,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621203,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628309,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756045,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 834003,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713218,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 443301,
        "city": "Mehkar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 365456,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 639105,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784161,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 305922,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361220,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 302012,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742189,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 516293,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110505,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 495549,
        "city": "Akaltara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 635117,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678505,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 284419,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 638661,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 760005,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342801,
        "city": "Kalyanpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 284203,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 482007,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 341511,
        "city": "Merta",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 325214,
        "city": "Itawa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571601,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123027,
        "city": "Mahendragarh-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521101,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 643223,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413725,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 623004,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271201,
        "city": "Itia Thok",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423110,
        "city": "Chandwad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 314022,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642119,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422401,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 403003,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 560057,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 628653,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441001,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712414,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 331504,
        "city": "Ratangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 191121,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577233,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631105,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629181,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802301,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 497111,
        "city": "Pathalgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 678702,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 411027,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 844103,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 302004,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824127,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742122,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721461,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415409,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 242005,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 570016,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576282,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638313,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630312,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110089,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 390003,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 305926,
        "city": "Badnaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403204,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 631213,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534247,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731214,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712248,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 854109,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 174303,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494010,
        "city": "Bastar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 415710,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 643234,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786151,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 363030,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 841402,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 604505,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403719,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 363031,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 383030,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522414,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796186,
        "city": "Thenzawl",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 389122,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 587330,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 284122,
        "city": "Lalitpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764075,
        "city": "Pappadahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411031,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 175014,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532522,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756035,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431009,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 205267,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741159,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 586120,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679591,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110014,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 491338,
        "city": "Bemetara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 793112,
        "city": "Cherrapunji",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 690548,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 753010,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509001,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562103,
        "city": "Chikkaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412108,
        "city": "Pirangut",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 783333,
        "city": "Tamarhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305205,
        "city": "Nasirabad",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 206002,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700024,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627355,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723143,
        "city": "Balarampur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 843117,
        "city": "Aurai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 248198,
        "city": "Herbertpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224184,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581206,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522315,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508213,
        "city": "Suryapet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639206,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400705,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712407,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 173030,
        "city": "Nahan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246488,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535573,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742135,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571211,
        "city": "Virajpet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321614,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 484002,
        "city": "Shahdol",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 522509,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 515814,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394210,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 632052,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691326,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 211020,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 814116,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 605203,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691330,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 829113,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248171,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695582,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 209881,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 314026,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848205,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500076,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 462004,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 518501,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713156,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144306,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301030,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 324003,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 638660,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285196,
        "city": "Orai",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503223,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686033,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583212,
        "city": "Hagaribommanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 485774,
        "city": "Maihar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 177024,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388230,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 173214,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534123,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535558,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803124,
        "city": "Bihar Sharif",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 416204,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 360450,
        "city": "Upleta",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 370627,
        "city": "Dayapar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 518553,
        "city": "Chagalamarri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532148,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713336,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523320,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416411,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400602,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 609303,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421403,
        "city": "Murbad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 574237,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152132,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 277216,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518001,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721656,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 790105,
        "city": "Tawang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 301044,
        "city": "Alwar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 695547,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 133203,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621704,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627351,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384305,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713341,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506347,
        "city": "Mulugu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362710,
        "city": "Kodinar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 276126,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627854,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786159,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 246763,
        "city": "Najibabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577301,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624501,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 608901,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676312,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 803201,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 793103,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 796891,
        "city": "Lawngtlai",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 764037,
        "city": "Koraput",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 671534,
        "city": "Parappa",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757029,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631102,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301028,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523110,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 815315,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577124,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627773,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313703,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 460663,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 783388,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 457661,
        "city": "Jhabua",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 515402,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766013,
        "city": "Jaipatna",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 412311,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 140103,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444910,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271307,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 496331,
        "city": "Jashpur Nagar",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 821104,
        "city": "Kudra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 796321,
        "city": "Champhai",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 690102,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 140110,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480102,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 743235,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226302,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614204,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 345024,
        "city": "Phalsund",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227202,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533237,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731242,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628703,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721434,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110104,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 523356,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 769043,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 625108,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585287,
        "city": "Shahapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144421,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 796810,
        "city": "Sangau",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 583204,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334021,
        "city": "Gharsana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691533,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627954,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712702,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 146105,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480771,
        "city": "Katangi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 110103,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680623,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 383006,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 636808,
        "city": "Palacode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723163,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 229126,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845438,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500594,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591104,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571478,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576231,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227308,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629707,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212662,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 602117,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 586123,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585259,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 245405,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583221,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263624,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630204,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533235,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532579,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814151,
        "city": "Dumka",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781364,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 753014,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609811,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144628,
        "city": "Sultanpur Lodhi",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521131,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721155,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160036,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 587117,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637213,
        "city": "Velur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504217,
        "city": "Mancherial",
        "state": "Telangana",
        "Zone": "S1"
       },
       {
        "pincode": 841418,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638673,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 184148,
        "city": "Hiranagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262202,
        "city": "Bisalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 816119,
        "city": "Barharwa",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 509410,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 430006,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 393025,
        "city": "Dediapada",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 233225,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829209,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110608,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 250403,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673595,
        "city": "Sultan Bathery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 203002,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586129,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600051,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 132101,
        "city": "Samalkha",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758040,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785692,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 362720,
        "city": "Kodinar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400017,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 208023,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203389,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531034,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811202,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 263001,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560094,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 570006,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627101,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695611,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 334003,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 209734,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636458,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361004,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 673543,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 524312,
        "city": "Udayagiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307031,
        "city": "Pindwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415640,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721124,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 224159,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 203403,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 184141,
        "city": "Samba",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202127,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785683,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 122012,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 250222,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835206,
        "city": "ChainpurJH",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 844502,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 466111,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 509311,
        "city": "Narayanpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 827012,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 533293,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574224,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370204,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 761037,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 331506,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249145,
        "city": "Tehri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516505,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362560,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 801115,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506348,
        "city": "Parkal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639109,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 222165,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124105,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411230,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673004,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415115,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 790102,
        "city": "Seppa",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 524003,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 829119,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712617,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387530,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 127026,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802352,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 768220,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403601,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 306106,
        "city": "Sojat",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160050,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 523315,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689678,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700141,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 305201,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626166,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671318,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 411014,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 756032,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627412,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415021,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 313024,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 404404,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 244502,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673620,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 486890,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110036,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 223106,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802218,
        "city": "Dinara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574166,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711315,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577501,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271841,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144804,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210301,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160037,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695004,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 465683,
        "city": "Pachore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700001,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 629169,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604407,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712223,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534015,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 627430,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680013,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 583137,
        "city": "Harpanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581303,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515286,
        "city": "Amarapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243751,
        "city": "Babrala",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 274509,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134104,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440014,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 753001,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 363621,
        "city": "Wankaner",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 145025,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 231218,
        "city": "Anpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741222,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691502,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 678002,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 508377,
        "city": "Haliya",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632516,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 454749,
        "city": "Dhar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 445102,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 486123,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 629602,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416410,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 393050,
        "city": "Dediapada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 207123,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332025,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160048,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 192201,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 223221,
        "city": "Nizamabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761212,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685537,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 327027,
        "city": "Ghatol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 206246,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274401,
        "city": "Kushinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 572139,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847427,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 484776,
        "city": "Beohari",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 733145,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614705,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676108,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 364485,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635305,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585367,
        "city": "Aland",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402108,
        "city": "Pen",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209868,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577204,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464114,
        "city": "Maksudangarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 502257,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 607001,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 454335,
        "city": "Kukshi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 484551,
        "city": "Pali Birsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 734014,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 731127,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 759023,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535502,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523181,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 753025,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 192303,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 572129,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741224,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 643005,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828107,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 690506,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629401,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676302,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 643002,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502285,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577421,
        "city": "Siddapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533450,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843107,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 813105,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 827003,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712136,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 244713,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 132001,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 343002,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523265,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679505,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 173223,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177038,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276288,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533263,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457775,
        "city": "Petlawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 606213,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670650,
        "city": "Kuthuparamba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506170,
        "city": "Bhupalpally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141415,
        "city": "Khanna",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332602,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491223,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 416524,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712234,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673510,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 844501,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793116,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 742162,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250606,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577558,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518343,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325224,
        "city": "Kelwara",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 626134,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503123,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416437,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 679578,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500484,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 131102,
        "city": "Samalkha",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847227,
        "city": "Khutauna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577411,
        "city": "Sringeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415706,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 848142,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627107,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743127,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 249410,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 480110,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 141009,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 518422,
        "city": "Atmakur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486887,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 572211,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172025,
        "city": "Anni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175052,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628214,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629171,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232325,
        "city": "Gahmar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410202,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574203,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563122,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560071,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416516,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 486007,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700144,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509131,
        "city": "Atmakur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248160,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574260,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562109,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401609,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 457993,
        "city": "Jhabua",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 629801,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221712,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174306,
        "city": "Una-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228141,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 855116,
        "city": "Thakurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627713,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841224,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341551,
        "city": "Didwana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767003,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670641,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 603001,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 241305,
        "city": "Madhoganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 173024,
        "city": "Sarahan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502294,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620012,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585317,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742223,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700132,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 481996,
        "city": "Bichhiya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 788106,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 767025,
        "city": "Patnagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522316,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382742,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 560068,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573103,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271601,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415726,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673511,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 250110,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 734401,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 242042,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 764041,
        "city": "Koraput",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 464337,
        "city": "Khurai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 144624,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625535,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822125,
        "city": "Ranka",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623108,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224161,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244712,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360375,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577416,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415610,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 784146,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 465679,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 791125,
        "city": "Likabali",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 485447,
        "city": "Nagod",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 493196,
        "city": "Bhatapara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 515722,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688548,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742157,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 284201,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522329,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609501,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631502,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788098,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 283103,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 613007,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 331701,
        "city": "Rajgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509324,
        "city": "Kalwakurthy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110509,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 686536,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 768200,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209111,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389154,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 742131,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246738,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 790003,
        "city": "Bomdila",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 413007,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392020,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141401,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 787023,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 249407,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 327032,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394125,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 784101,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 756030,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 811213,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 462029,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 533433,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394155,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 711316,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712139,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 843103,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415628,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 509207,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678591,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 824116,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756079,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682000,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636906,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614201,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625527,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365440,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 385001,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500072,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743287,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534006,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742000,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577513,
        "city": "Jagaluru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591247,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 570031,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691552,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 141004,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 630004,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828102,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 630307,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 606751,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141002,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425523,
        "city": "Phaltan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 385421,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 733156,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 570007,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781306,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 534449,
        "city": "Dwaraka Tirumala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627008,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689627,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 614707,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171209,
        "city": "Theog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757091,
        "city": "Jashipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500097,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577120,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466554,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 764043,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110120,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 127022,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273002,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752018,
        "city": "Nirakarpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 225001,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641604,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 752101,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609109,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456776,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 388210,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 229418,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 151503,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360060,
        "city": "Jasdan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 500082,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781382,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 312606,
        "city": "Jawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 670315,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 591254,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700018,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 132114,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 401403,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521324,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396165,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 731236,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 481105,
        "city": "Malanjkhand",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 500006,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 127307,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671128,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403531,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 493113,
        "city": "Bhatapara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 756021,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560075,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508233,
        "city": "Kodad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782413,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 363427,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 143534,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577589,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824120,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 283204,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 263160,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 151401,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502249,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362037,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 627901,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854303,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 414401,
        "city": "Karjat-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500030,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571129,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388715,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 396530,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 731104,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301418,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630105,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 530032,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 607205,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 145101,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506356,
        "city": "Mogullapally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323614,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312402,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 492112,
        "city": "Basna",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 768223,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 759001,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413212,
        "city": "Indapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743297,
        "city": "Helencha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678545,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 484114,
        "city": "Shahdol",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 440026,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 761213,
        "city": "Rayagada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 223223,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413308,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 202523,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563113,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630314,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 791002,
        "city": "Yingkiong",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 583113,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110609,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 384215,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 132116,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403603,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571811,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700098,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 768037,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 485081,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 387635,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224153,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416118,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 302013,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673102,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 509353,
        "city": "Makthal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845429,
        "city": "Jiwdhara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 572213,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572101,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486115,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 680101,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416312,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 221602,
        "city": "Madhuban",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815303,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824210,
        "city": "Imamganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700051,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 495685,
        "city": "Sakti",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 415529,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 508002,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 471318,
        "city": "Shahgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 580022,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229122,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761101,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 202399,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413252,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 734225,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713326,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854334,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734427,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 508255,
        "city": "Chandur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400605,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 174026,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 390011,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 641302,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246123,
        "city": "Paithani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686691,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768018,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415302,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 362256,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 442919,
        "city": "Chamorshi",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 382712,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 132037,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210423,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 723129,
        "city": "Manbazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712303,
        "city": "Jamalpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 173022,
        "city": "Nahan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754034,
        "city": "Badamba",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712705,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581396,
        "city": "Mallapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505480,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521286,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609103,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560032,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 764045,
        "city": "Malkangiri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679125,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 785007,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 500010,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 561203,
        "city": "Doddaballapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 480449,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 402306,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 383335,
        "city": "Bayad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713140,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517292,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 110008,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 441919,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577001,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678020,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 757049,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828125,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110507,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 400109,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 261301,
        "city": "Sidhauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403726,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 733201,
        "city": "Dalkhola",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442308,
        "city": "Hinganghat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 481449,
        "city": "Katangi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 768011,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125050,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 794005,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 232114,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686510,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 497231,
        "city": "Bhatgaon",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 151502,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533406,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384350,
        "city": "Satlasana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 833218,
        "city": "Noamundi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388360,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 384160,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 461223,
        "city": "Seoni Malwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 332410,
        "city": "Sikar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 625501,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 450119,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 631304,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515551,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502307,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 335026,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 700303,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 584143,
        "city": "Manvi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441216,
        "city": "Gondia",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 799250,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 470232,
        "city": "Rehli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 141103,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 325225,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246424,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624316,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530047,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583278,
        "city": "Kushtagi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 321202,
        "city": "Deeg",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480105,
        "city": "Sausar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 470125,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 503144,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500107,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 723151,
        "city": "Hura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 711106,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695305,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 384276,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 515863,
        "city": "Rayadurgam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 610107,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134201,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689703,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 410205,
        "city": "Nagothane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 249403,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246455,
        "city": "Tharali",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 827015,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 794101,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 470335,
        "city": "Banda-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 262310,
        "city": "Tanakpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788734,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 573164,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 722206,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583279,
        "city": "Kushtagi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413226,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 365620,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 126116,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382721,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 176059,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 342602,
        "city": "Bilara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507303,
        "city": "Sathupally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226019,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 124517,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 855114,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 201201,
        "city": "Modinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441914,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 517551,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712106,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600078,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389120,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 832106,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206131,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712152,
        "city": "Jamalpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623538,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516432,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845411,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741166,
        "city": "Chapra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733154,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 387320,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 394220,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 229310,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523109,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387515,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522614,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583154,
        "city": "Siruguppa",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627117,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501507,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400114,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364001,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 143413,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629180,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 742224,
        "city": "Dhulian",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638506,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606002,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410062,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 802311,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523117,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585444,
        "city": "Bhalki",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144630,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757037,
        "city": "Karanjia",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584113,
        "city": "Devadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691021,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 175018,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605004,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 175129,
        "city": "Patlikuhal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752046,
        "city": "Pipili",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332039,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 455048,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 788030,
        "city": "Udarbond",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 562128,
        "city": "Ramanagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799264,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 623706,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742403,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638504,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209401,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670567,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 151003,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221208,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 610001,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504103,
        "city": "Bhainsa",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522050,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160045,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 737111,
        "city": "Geyzing",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 247671,
        "city": "Laksar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732124,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 770070,
        "city": "Rajgangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394325,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689672,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 505503,
        "city": "Mahadevpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828127,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 493211,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 444312,
        "city": "Khamgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 332313,
        "city": "Laxmangarh-RJ",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 521121,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637302,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124106,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781377,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 828112,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690532,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507202,
        "city": "Madhira",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503124,
        "city": "Yellareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205142,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788009,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 533236,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700031,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 172103,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 816102,
        "city": "Barharwa",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 764052,
        "city": "Balimela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635901,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515159,
        "city": "Mudigubba",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767060,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176045,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 148100,
        "city": "Bhadaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 833203,
        "city": "Jagannathpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721145,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 127309,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524308,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400011,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 224189,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 471625,
        "city": "Khajuraho",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 389160,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 360070,
        "city": "Gondal",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 723213,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628306,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 791103,
        "city": "Boleng",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 504295,
        "city": "Asifabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631301,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392161,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 759107,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571186,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 797111,
        "city": "Wokha",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 483222,
        "city": "Sihora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 641022,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227005,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 263651,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402112,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521144,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523263,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311013,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221310,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524310,
        "city": "Udayagiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503188,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202150,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574210,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689582,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 277506,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632404,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637207,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852122,
        "city": "Murliganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 613406,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 313014,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411406,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 621652,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221709,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454435,
        "city": "Sardarpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 627005,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 452007,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 224149,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 466221,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 224230,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410102,
        "city": "Karjat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 676561,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332703,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686667,
        "city": "Koothattukulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572128,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131002,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 385575,
        "city": "Tharad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 583231,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422615,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 683504,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 632521,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392030,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 517350,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534447,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110094,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 500267,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614018,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713161,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683511,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 785670,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 761041,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600112,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581186,
        "city": "Dandeli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 497226,
        "city": "Surajapur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 641668,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 151211,
        "city": "Mandi Dabwali",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531149,
        "city": "Araku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680572,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802119,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845408,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686677,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 302001,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415301,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392150,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686007,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 362255,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 570004,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 147005,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 794105,
        "city": "Garobadha",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 713214,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847212,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 586112,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786624,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 630566,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600001,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 490010,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 828123,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 272193,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627806,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486446,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 503175,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 563127,
        "city": "Mulbagal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325602,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695505,
        "city": "Neyyattinkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 251201,
        "city": "Khatauli",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415108,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 243504,
        "city": "Milak",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413002,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144819,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686523,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 209728,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755014,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209866,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403501,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 524227,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614622,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415639,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 243638,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462033,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 497335,
        "city": "Baikunthpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 735208,
        "city": "Jaigaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 209306,
        "city": "Rasulabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500252,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673541,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847303,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422109,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743164,
        "city": "Naihati",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 362020,
        "city": "Bhesan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 333035,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387315,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 781015,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 685538,
        "city": "Mundakayam",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 535001,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142053,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364002,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 736157,
        "city": "Mathabhanga",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505102,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160060,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712708,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403604,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 751027,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847305,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 391347,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 793150,
        "city": "Jowai",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 575011,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686577,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176028,
        "city": "Nagrota Surian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571218,
        "city": "Virajpet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623001,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273007,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 181201,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581170,
        "city": "Sirsi",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 400092,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 742147,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 768221,
        "city": "Bamra",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522022,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500063,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522201,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680610,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621313,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722154,
        "city": "Mirzapur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632011,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121107,
        "city": "Hodal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786602,
        "city": "Duliajan",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 591301,
        "city": "Alnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627755,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713365,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 395018,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 212664,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682004,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 841247,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600087,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533007,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641665,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591219,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442706,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 464665,
        "city": "Bareli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110606,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 852139,
        "city": "Triveninganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581324,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517504,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 507134,
        "city": "Nellipaka",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523334,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306022,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637410,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581341,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802161,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673631,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 631401,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227814,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621703,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630610,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605502,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683520,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144039,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301703,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 501101,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143503,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 770076,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342022,
        "city": "Phalsund",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144513,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632006,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 363655,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 160062,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571477,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 507201,
        "city": "Madhira",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502319,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571440,
        "city": "Kollegala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639003,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635703,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713520,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122217,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741505,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 822124,
        "city": "Bisrampur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680724,
        "city": "Chalakudy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768029,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517004,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764055,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 503218,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382711,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 370427,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 562137,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122005,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521325,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505186,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303010,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635601,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507306,
        "city": "Sathupally",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712613,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 732126,
        "city": "Itahar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141412,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690540,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 691602,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144515,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 737126,
        "city": "Namchi",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 321414,
        "city": "Kherli",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 753051,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110068,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 422008,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110113,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 628714,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799113,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 493101,
        "city": "Tilda",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 241126,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 443308,
        "city": "Dusrabid",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 601206,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 332406,
        "city": "Bajore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585315,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533240,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516106,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262524,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222110,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680503,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 249161,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 631204,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581207,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 792102,
        "city": "Chowkham",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 629193,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574212,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 831014,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360006,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 846001,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 451669,
        "city": "Sendhwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 360510,
        "city": "Bhanvad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 533462,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754230,
        "city": "Aul",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 768212,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250103,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 501158,
        "city": "Tandur-TL",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327023,
        "city": "Ghatol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 204216,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533126,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 797107,
        "city": "Pfutsero",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 303005,
        "city": "Dudu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441208,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 734426,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431528,
        "city": "Parli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 814144,
        "city": "Rangalia",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670643,
        "city": "Kuthuparamba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 752063,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804417,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560062,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574219,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 147007,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110077,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 628201,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636502,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141418,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605752,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671552,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781343,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 229206,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400090,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 628952,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802206,
        "city": "Sandesh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 642103,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431721,
        "city": "Mahagaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 673507,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 853207,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415509,
        "city": "Mhaswad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795011,
        "city": "Moirang",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523373,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734434,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533340,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 401610,
        "city": "Talasari",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560018,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 445302,
        "city": "Pandharkawada",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 206241,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175025,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783345,
        "city": "Dotma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 612101,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851139,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 796014,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 673318,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457003,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 273412,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134005,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690557,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 175101,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271204,
        "city": "Itia Thok",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641045,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 608306,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843326,
        "city": "Pupri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250611,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680620,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686036,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621851,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495448,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144011,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 629501,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636103,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 231311,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263136,
        "city": "Bhimtal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 793014,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 641039,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841437,
        "city": "Bhore",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758023,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621302,
        "city": "Manapparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303509,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571421,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517351,
        "city": "B Kothakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248195,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535273,
        "city": "Gajapatinagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394530,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 812009,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783315,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 481665,
        "city": "Mandla",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 753015,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 630214,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431126,
        "city": "Beed",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 691301,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802226,
        "city": "Bikramganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122215,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743200,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632001,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736168,
        "city": "Sitai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144021,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 784182,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 160021,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 835202,
        "city": "Bero",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506332,
        "city": "Narsampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334404,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 332316,
        "city": "Balaran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 794111,
        "city": "Williamnagar",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 700091,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509376,
        "city": "Achampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713405,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515672,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574325,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 391340,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 828206,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788163,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 532213,
        "city": "Pathapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444202,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 575023,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 393133,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 591235,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231206,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581305,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281001,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 835204,
        "city": "Bundu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700045,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160058,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413531,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576112,
        "city": "Yellare",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755013,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 757043,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752027,
        "city": "Tangi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221101,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144103,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 452056,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 453331,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 624801,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638111,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762014,
        "city": "Boudh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 132039,
        "city": "Assandh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396433,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 422304,
        "city": "Lasalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 470772,
        "city": "Hatta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 323603,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845318,
        "city": "Dhaka",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144511,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 201008,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721651,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571404,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 284402,
        "city": "Mahrauni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785691,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 630108,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756122,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606701,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522203,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626515,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121015,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 283145,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786103,
        "city": "Tengakhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 391168,
        "city": "Chhota Udaipur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 122209,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 364560,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 844124,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 753132,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 224208,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625523,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 206451,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415206,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689583,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413255,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441205,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 802202,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735210,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 364730,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 604305,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221402,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176090,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585325,
        "city": "yedrami",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600105,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 690512,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 783134,
        "city": "Dudhnoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 171103,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783373,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 517505,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388543,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 140901,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 769009,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673516,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 402308,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 759025,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 271901,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151505,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 307510,
        "city": "Abu Road",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391345,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 451448,
        "city": "Sendhwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 690538,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 304024,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521340,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121705,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 422608,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 793110,
        "city": "Pynursla",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 786623,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 534343,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688504,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 222113,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 457112,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 246761,
        "city": "Dhampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523211,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281104,
        "city": "Jalesar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 326035,
        "city": "Khanpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415408,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 301426,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 799001,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 276001,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 454660,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 573211,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721456,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691559,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416002,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 505174,
        "city": "Peddapalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394221,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 431213,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 735215,
        "city": "Jaigaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673632,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413401,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 735102,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388421,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 503002,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 380025,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 321612,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505498,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231207,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500027,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 722136,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603127,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743291,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783127,
        "city": "Fekamari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 821105,
        "city": "Mohania",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 762001,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585437,
        "city": "Basavakalyan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802203,
        "city": "Sandesh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415519,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 841223,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828119,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 401303,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 411053,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 209862,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691509,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 123508,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "Zone": "N2"
       },
       {
        "pincode": 695411,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 243726,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584128,
        "city": "Sindhanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743301,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534313,
        "city": "Gopalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364510,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 835215,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414105,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 784002,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 571234,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334201,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676313,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 534266,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613205,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581322,
        "city": "Siddapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382428,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 784523,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 282007,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781329,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 721167,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603308,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209206,
        "city": "Ghatampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521212,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629166,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686663,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 575152,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 835207,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825421,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621207,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321405,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735218,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 365545,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 743398,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713167,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712245,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713305,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614721,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 679305,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 673316,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 787056,
        "city": "Gogamukh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 303702,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332716,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212306,
        "city": "Koraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713428,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735217,
        "city": "Alipurduar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600082,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144602,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743294,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516380,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444706,
        "city": "Daryapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 624010,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581359,
        "city": "Yellapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735350,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 192211,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 576119,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 423101,
        "city": "Chandwad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521153,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691573,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 210003,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423701,
        "city": "Vaijapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 814122,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700065,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506222,
        "city": "Thirumalagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734204,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585308,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577123,
        "city": "Sringeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262527,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583235,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 787054,
        "city": "Narayanpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 518006,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403103,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515822,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225121,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321602,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415523,
        "city": "Phaltan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 852137,
        "city": "Triveninganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 284126,
        "city": "Talbahat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505185,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416423,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 765013,
        "city": "Tikiri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 451551,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 854305,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 251202,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586115,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828130,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700159,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342301,
        "city": "Phalodi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250010,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591123,
        "city": "Ramdurg",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639106,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785009,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 673306,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756038,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413713,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584138,
        "city": "Kowthal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689695,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 606714,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 301707,
        "city": "Tijara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313322,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 464221,
        "city": "Ganj Basoda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673021,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 507128,
        "city": "Bhadrachalam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504272,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244231,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382730,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 643204,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516131,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515154,
        "city": "Mudigubba",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495550,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 759120,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 813221,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 361013,
        "city": "Kalavad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 606753,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 233234,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754221,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 757025,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636017,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 212304,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 445211,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 762012,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271840,
        "city": "Bhinga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249306,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500041,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400049,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 613002,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382044,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 754141,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384271,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 441112,
        "city": "Savner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 735228,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600083,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415715,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415629,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 683563,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 533002,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311201,
        "city": "Deoli-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848506,
        "city": "Mohiuddin Nagar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 509412,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148111,
        "city": "Sunam",
        "state": "Punjab",
        "Zone": "N2"
       },
       {
        "pincode": 388460,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 742113,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560033,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 755045,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639110,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712703,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560086,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700106,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394330,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400021,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 621132,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761010,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783380,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 301036,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505445,
        "city": "Choppadandi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416217,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 629301,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600056,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 402208,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 783348,
        "city": "Bilasipara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 192212,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 443303,
        "city": "Mehkar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682305,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636201,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229304,
        "city": "Gauriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384290,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 503245,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305622,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784110,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 682315,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 695042,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641662,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523121,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226021,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577131,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148001,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743349,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785703,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 495113,
        "city": "Ratanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 182128,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175033,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 451115,
        "city": "Sanawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 208014,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670327,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 324004,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144521,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506313,
        "city": "Rayaparthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573165,
        "city": "Sakleshpur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524415,
        "city": "Vidyanagar",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637003,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713515,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533296,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 422403,
        "city": "Igatpuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 586101,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533284,
        "city": "Addateegala",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 306305,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785701,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 591230,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342024,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802165,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144206,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524320,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 341516,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560102,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583275,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396406,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 609117,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786173,
        "city": "Digboi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 385515,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 743286,
        "city": "Swarupnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 210504,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752025,
        "city": "Tangi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761109,
        "city": "Badagada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 601303,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685552,
        "city": "Nedumkandam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457226,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 534208,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 805135,
        "city": "Khizasarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 473226,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 636010,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 365435,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141416,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 204102,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755026,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 561208,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403106,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576213,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502321,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495449,
        "city": "Ratanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 134102,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416208,
        "city": "Rashiwade Bk",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621214,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141101,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413507,
        "city": "Kaij",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 756002,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522019,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614202,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431142,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 331301,
        "city": "Bahal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638153,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682317,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 638401,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416234,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 325202,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581344,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 481101,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 741302,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734425,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341030,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581340,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680689,
        "city": "Chalakudy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 331302,
        "city": "Taranagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415719,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 509235,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 244221,
        "city": "Amroha",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682012,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 798625,
        "city": "Longleng",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 695604,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621113,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781338,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 742168,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609810,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643220,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623533,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516434,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680563,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 148030,
        "city": "Sunam",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 394218,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 121104,
        "city": "Nuh",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682031,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 631006,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670306,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 586212,
        "city": "Muddebihal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524402,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580024,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 638461,
        "city": "Thalavadi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321416,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440022,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 641406,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 695562,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743126,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758001,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722163,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523101,
        "city": "Singarayakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576220,
        "city": "Byndoor",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721665,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782480,
        "city": "Bokajan",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 301002,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783131,
        "city": "Fekamari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 413219,
        "city": "Mandrup",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 423607,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757042,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781123,
        "city": "Boko",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 334303,
        "city": "Bajju",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641008,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741140,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 769012,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382330,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 473995,
        "city": "Pichhore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 393105,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522112,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641040,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 722182,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382001,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 331029,
        "city": "Taranagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844131,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 323022,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480887,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 841101,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623203,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333026,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462023,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713340,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 494444,
        "city": "BijapurCH",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 222141,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 414503,
        "city": "Shevgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628656,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305623,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815314,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624001,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143201,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757074,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591309,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 395012,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 444104,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 804403,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144222,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845460,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415702,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 804419,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144525,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 331502,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212413,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110095,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 638009,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 465693,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 686617,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585210,
        "city": "Sedam",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515271,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415211,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581304,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621015,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686002,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 670561,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 834011,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506145,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416508,
        "city": "Halkarni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 362267,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 303504,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 226030,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 151207,
        "city": "Kotkapura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303805,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262905,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688566,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 786182,
        "city": "Margherita",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 415119,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560095,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 732127,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734421,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 210205,
        "city": "Chitrakoot",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562138,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143508,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783349,
        "city": "Bilasipara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 515621,
        "city": "Kambadur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 146109,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626130,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 423106,
        "city": "Manmad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 759100,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700002,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272190,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521229,
        "city": "Mylavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630555,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 804408,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 491225,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 425506,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 788010,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 131101,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 816109,
        "city": "Sahebganj",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 228115,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 636202,
        "city": "Mettala",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492006,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 343026,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 281405,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532322,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518598,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 842005,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628620,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683519,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 582101,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680543,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 175008,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 124102,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761108,
        "city": "Badagada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754081,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 301604,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841460,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 671124,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713426,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521117,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574266,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722140,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713421,
        "city": "Jamalpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688014,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341029,
        "city": "Nokha",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332027,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143044,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 445209,
        "city": "Pusad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 607102,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689506,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303904,
        "city": "Dudu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 762017,
        "city": "Kantamal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 470235,
        "city": "Kesali",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 793009,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 828109,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 229209,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517326,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802304,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691318,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 147301,
        "city": "Mandi Gobindgarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670312,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 678612,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 524234,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625009,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 246448,
        "city": "Rudraprayag",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713146,
        "city": "Memari",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841435,
        "city": "Mairwa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 762025,
        "city": "Boudh",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 517102,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686105,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 380043,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 582112,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151208,
        "city": "Kotkapura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 136044,
        "city": "Assandh",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 770048,
        "city": "Koira",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248110,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691013,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 152116,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577516,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685562,
        "city": "Adimali",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 534261,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 206003,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 457773,
        "city": "Petlawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 585292,
        "city": "Sedam",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160017,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 212201,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 787031,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 209860,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754294,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414112,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678594,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 743121,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 530049,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 638476,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632054,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679351,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 396107,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431115,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713154,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 275302,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508256,
        "city": "Chandur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415207,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 624708,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 788150,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 626004,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851210,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506103,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327024,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573101,
        "city": "Belur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480551,
        "city": "Junnardeo",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 743610,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623003,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848112,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 805127,
        "city": "Sirdala",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591128,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751023,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 425504,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 683576,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680012,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 458110,
        "city": "Manasa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 679573,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847239,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 172107,
        "city": "Reckong Peo",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621705,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802219,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712601,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591131,
        "city": "Khanapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751003,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 464228,
        "city": "Sironj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 121012,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591228,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803204,
        "city": "Chandi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761055,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 393150,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577112,
        "city": "Sringeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676103,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176216,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673621,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713407,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400607,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364241,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 176049,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743412,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506005,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230002,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394641,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 571101,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691530,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577007,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 365610,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 246171,
        "city": "Rudraprayag",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493338,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 673302,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 412801,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 844111,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441603,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 612603,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174001,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313022,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585415,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852127,
        "city": "Sonbarsa-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700003,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531021,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203001,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848130,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 251308,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416632,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 444728,
        "city": "Daryapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144633,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391110,
        "city": "Dabhoi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 563125,
        "city": "Chintamani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683103,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 829128,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523345,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743428,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517640,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606115,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124111,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441614,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 227407,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829130,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523335,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382745,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 440004,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695102,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 250626,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 613302,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515531,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673591,
        "city": "Sultan Bathery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 766011,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 304802,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517592,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224183,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560065,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 272152,
        "city": "Bansi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361170,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271903,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612103,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622303,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416003,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 174013,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415807,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686652,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577201,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172029,
        "city": "Kumarsain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400076,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583152,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613401,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416601,
        "city": "Kankavali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 734123,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 848204,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444506,
        "city": "Risod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144517,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321001,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203395,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679338,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 712405,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410516,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 608401,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421505,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 786188,
        "city": "Jairampur",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 620016,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 284003,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 412803,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621201,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712246,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521333,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425408,
        "city": "Shindkheda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 752031,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335707,
        "city": "Gharsana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721125,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444404,
        "city": "Digras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 835301,
        "city": "Bero",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413736,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 334001,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 736135,
        "city": "Dinhata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680681,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174035,
        "city": "KalolHP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515321,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689650,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742236,
        "city": "Farakka",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 492011,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 627420,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731235,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262901,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176064,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841226,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676554,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 335039,
        "city": "Raisinghnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271305,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415521,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 605501,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841414,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 517001,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 207244,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110029,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 691588,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 584133,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670565,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 281502,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143604,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585402,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627760,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691601,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 679571,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425201,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 244223,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 573216,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766026,
        "city": "Jaipatna",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 313002,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 148025,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623307,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396020,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 624211,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533431,
        "city": "Yerravaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394365,
        "city": "Navapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 794110,
        "city": "Rongjeng",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 629201,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388170,
        "city": "Tarapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 784116,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 110053,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 246172,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586227,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736123,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212303,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742139,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 844126,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 283205,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713420,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 323604,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741167,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 732128,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509326,
        "city": "Achampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799046,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 713211,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509340,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785618,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 851217,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 174301,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587102,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262521,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341012,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678761,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 362014,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 848207,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623512,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325215,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627414,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 173217,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 685607,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 412001,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431008,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 480111,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 843316,
        "city": "Runnisaidpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574227,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462024,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742150,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 742171,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 813225,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 391810,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 843123,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 611112,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686517,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413516,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 786621,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 643238,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148020,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722158,
        "city": "Mejia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176200,
        "city": "Palion",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670693,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 364470,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400702,
        "city": "Jasai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 561210,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828111,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480003,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 533102,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 637208,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201013,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 152121,
        "city": "Fazilka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713380,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518221,
        "city": "Dhone",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396424,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678623,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 231205,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847238,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 751011,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110028,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 695022,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 685539,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 244415,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 846002,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396209,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 755011,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670337,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 803109,
        "city": "Pavapuri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370115,
        "city": "Bhachau",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 382716,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 670646,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 811101,
        "city": "Barbigha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691501,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 676311,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679301,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515766,
        "city": "Kambadur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712415,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 485112,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 134151,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 274149,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444103,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 690547,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 795133,
        "city": "Moirang",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 516391,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344703,
        "city": "Barmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361006,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 212308,
        "city": "Koraon",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628210,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762011,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444814,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 522613,
        "city": "Macherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614904,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641653,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 455116,
        "city": "Dewas",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 610207,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144008,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 795115,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 410501,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 362551,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 470004,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 226017,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 126110,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441305,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581208,
        "city": "Ranebennur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323304,
        "city": "Rawatbhata",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516421,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493992,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 382011,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 624219,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843320,
        "city": "Pupri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782108,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 262311,
        "city": "Sitarganj",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243406,
        "city": "Nawabganj-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642153,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683512,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577532,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781368,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 676552,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 364270,
        "city": "Palitana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577556,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 523270,
        "city": "Kondapi",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678510,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 231224,
        "city": "Anpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591245,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689696,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 143517,
        "city": "Sri Hargobindpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534316,
        "city": "Gopalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380061,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 518122,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713158,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502313,
        "city": "Narsapur-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621109,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390018,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400105,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 791102,
        "city": "Pasighat",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 637403,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451411,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 799207,
        "city": "Khowai",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 822134,
        "city": "Garhwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 181111,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721144,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 222112,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 277201,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517401,
        "city": "Venkatagirikota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532405,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122214,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 636009,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122213,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678553,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 623544,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491661,
        "city": "Rajnandgaon",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 515556,
        "city": "Kadiri",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585109,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761029,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571476,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517520,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 643225,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577147,
        "city": "Belur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 721641,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176094,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793109,
        "city": "Pynursla",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 181141,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462026,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 734213,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721406,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 380059,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 577514,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848201,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743339,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 854104,
        "city": "Kursela",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845303,
        "city": "Dhaka",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670005,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 193501,
        "city": "Bandipora",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713408,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382475,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 441203,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 741101,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 387350,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 110510,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 613705,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712504,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 741203,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621730,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621707,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571446,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413514,
        "city": "Ahmadpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 758032,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144032,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 524421,
        "city": "Naidupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441908,
        "city": "Pauni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 583105,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 531127,
        "city": "Tuni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690574,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174031,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402103,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 247002,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560024,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609608,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176054,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 180003,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160082,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600062,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 147003,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 123028,
        "city": "Narnaul",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629004,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 643243,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682018,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 501401,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600004,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576235,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383251,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 249202,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679303,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 852128,
        "city": "Madhepura",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416521,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 392040,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 754008,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 151507,
        "city": "Ratia",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712503,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628619,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585324,
        "city": "Chincholi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783310,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 795142,
        "city": "Ukhrul",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 424305,
        "city": "Sakri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 143537,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 314804,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246429,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571212,
        "city": "Virajpet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241204,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341513,
        "city": "Merta",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609607,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731126,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441920,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 700160,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363039,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 824221,
        "city": "Imamganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695124,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396570,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783392,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 363415,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416212,
        "city": "Mudal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 785678,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 332710,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521326,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534324,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532203,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 761019,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 125120,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229316,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422002,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 768215,
        "city": "Bamra",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394211,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 474011,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 486331,
        "city": "Deotalab",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 413302,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521125,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230502,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 831020,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824209,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679574,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 766019,
        "city": "Jaipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 285134,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621802,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603313,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639203,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370610,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 110107,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 627860,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416223,
        "city": "Mudal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 221201,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222175,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743251,
        "city": "Helencha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 192121,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678589,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600003,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 759117,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 306503,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 272302,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 322201,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465449,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 628402,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443104,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 224226,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761210,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743704,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515774,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415516,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394250,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 209733,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 679515,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515133,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313015,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 193121,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 233305,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 326501,
        "city": "Bhawani Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691526,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 610102,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391210,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 784174,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 243122,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577537,
        "city": "Challakere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679310,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 515641,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743136,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160074,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 848302,
        "city": "KalyanpurBR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581211,
        "city": "Ranebennur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422402,
        "city": "Igatpuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 504297,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678512,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 143415,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625005,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585323,
        "city": "Shahapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841237,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680732,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713330,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 587121,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388001,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 224172,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609405,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631005,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335001,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680602,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 835231,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 770018,
        "city": "Rajgangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400059,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 608002,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577135,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624213,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535183,
        "city": "Pendurthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493770,
        "city": "Dhamtari",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 173207,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387520,
        "city": "Matar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 306001,
        "city": "Marwar Junction",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362136,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 394690,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 628721,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 342037,
        "city": "Mathania",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680611,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444308,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721643,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382810,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 212622,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413517,
        "city": "Udgir",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 813209,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 465667,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 160044,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144528,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301405,
        "city": "Khairthal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412405,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 312001,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494442,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522603,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504251,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713124,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627953,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394730,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 516247,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 276208,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 504313,
        "city": "Jainoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142024,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160043,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 570008,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 175134,
        "city": "Nagwain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 815351,
        "city": "Jamtara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641013,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626131,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508266,
        "city": "Haliya",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670346,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 305206,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671329,
        "city": "Kanhangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 224212,
        "city": "Dostpur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 506015,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 770011,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 472221,
        "city": "Jatara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 342003,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 711317,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847422,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 250623,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691557,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413132,
        "city": "Indapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 442202,
        "city": "Talegaon Sp",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 422011,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 689109,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 224221,
        "city": "Amroha",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229302,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444403,
        "city": "Mangrulpir",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686676,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 711206,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 209825,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505451,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 735308,
        "city": "Siliguri",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 614014,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313021,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735212,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362226,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 722157,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 277205,
        "city": "Bariya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388320,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 586208,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221113,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 611106,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688584,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 606755,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605111,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 445309,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 222180,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242401,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441804,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 821107,
        "city": "Kochas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534312,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574129,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688537,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 229203,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403712,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 766116,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 474002,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 642132,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632501,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391250,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 613006,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495669,
        "city": "Champa",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781302,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 431702,
        "city": "Hingoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 624804,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 120002,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413702,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627252,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630211,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500064,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 126111,
        "city": "Assandh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700120,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246764,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 450771,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 570005,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 500873,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680736,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 452019,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 490036,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 841239,
        "city": "Mairwa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624403,
        "city": "Natham",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620018,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626110,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825411,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713150,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403520,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 485003,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 721429,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824236,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591116,
        "city": "Saundatti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412102,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 473990,
        "city": "Pichhore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 442507,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691306,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 262520,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587156,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632058,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813129,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560002,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176301,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 470003,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 460449,
        "city": "Sarni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 385526,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 441222,
        "city": "Sindewahi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413525,
        "city": "Kaij",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638462,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847429,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 411006,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 283202,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680015,
        "city": "Arimbur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624414,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784147,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 390009,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 127310,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721163,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673586,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 683587,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500112,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 402107,
        "city": "Pen",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 603105,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734404,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721626,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673643,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 601205,
        "city": "Gummudipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 483225,
        "city": "Sihora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 534222,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484001,
        "city": "Shahdol",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 570026,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683573,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144044,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431521,
        "city": "Parbhani",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 493554,
        "city": "Basna",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 393120,
        "city": "Jhagadia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585309,
        "city": "Shahapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643212,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751001,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713132,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576001,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 607107,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788025,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 521180,
        "city": "Nandigama",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 133202,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 520001,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 401503,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 530024,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 393001,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 496197,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 507002,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680703,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 520013,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 638058,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754215,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 629001,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 844112,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 456001,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 824123,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 444407,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305815,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 205147,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362015,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 721607,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 783307,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 312202,
        "city": "Kapasan",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505162,
        "city": "Peddapalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500079,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 767028,
        "city": "Khaprakhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782481,
        "city": "Howraghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 263153,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 392215,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577520,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273202,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 202723,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 230301,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313016,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410216,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 203392,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614631,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639118,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786010,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 517429,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391120,
        "city": "Nasvadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 302042,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713153,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721444,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783128,
        "city": "Fekamari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 676513,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 791112,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 455003,
        "city": "Dewas",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 829145,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 503308,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757087,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733252,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713147,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571479,
        "city": "Maddur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 587126,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342033,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416701,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 335022,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 476229,
        "city": "Sabalgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 342303,
        "city": "Mathania",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690569,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 176102,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400012,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841442,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531161,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756087,
        "city": "Dantan",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 605701,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576105,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630562,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283122,
        "city": "Kirauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712602,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786003,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 591201,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 793011,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 304021,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413602,
        "city": "Naldurg",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 612602,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523189,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722178,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 481226,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 134118,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431102,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415701,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416108,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 465331,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 712418,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 326037,
        "city": "Aklera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637210,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 185132,
        "city": "Rajouri",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695125,
        "city": "Neyyattinkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413107,
        "city": "Mhaswad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 733123,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303003,
        "city": "Viratnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110061,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 411047,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 509104,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 485001,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 685563,
        "city": "Kuthumkal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 160030,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743352,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828306,
        "city": "Gomoh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639108,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688012,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416420,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110023,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 689675,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 203405,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 631052,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635802,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444905,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144803,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465445,
        "city": "Nalkheda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 360026,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 803122,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581316,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803303,
        "city": "Sheonar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735136,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628208,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680704,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 442107,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 516330,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574267,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151111,
        "city": "Rampura Phul",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516105,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 452009,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 770033,
        "city": "Birmitrapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 243505,
        "city": "Milak",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786160,
        "city": "Doom Dooma",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 305901,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508004,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785620,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 151206,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586211,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623101,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756138,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757032,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689681,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 828117,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 783122,
        "city": "Dudhnoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 493559,
        "city": "Sarangarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 331802,
        "city": "Ratangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 205141,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450445,
        "city": "Burhanpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 680027,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 445205,
        "city": "Mahagaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 221006,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441301,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 335703,
        "city": "Srivijaynagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700151,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680501,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 506003,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 464671,
        "city": "Bareli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 673636,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 841207,
        "city": "Dighwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825318,
        "city": "Domchanch",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413303,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271871,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 232328,
        "city": "Gahmar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 311030,
        "city": "Raila",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767018,
        "city": "Birmaharajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743250,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680693,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 401208,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754214,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721452,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 136156,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516339,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486441,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 416811,
        "city": "Talere",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 302026,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522202,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176058,
        "city": "Surajpur jhikla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712706,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 822129,
        "city": "Nagar Untari",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 503102,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637015,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680520,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 844505,
        "city": "Hazrat Jandaha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 520014,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 733158,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504216,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625518,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506344,
        "city": "Tadvai",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 345025,
        "city": "Phalsund",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626112,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851127,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752103,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522435,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686543,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 245406,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 573136,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 816110,
        "city": "Barharwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695513,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 769006,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591121,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202137,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 283135,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143111,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301033,
        "city": "Alwar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 587135,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143302,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571237,
        "city": "Madikeri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391156,
        "city": "Chhota Udaipur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 177041,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 233307,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495689,
        "city": "Sakti",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 144415,
        "city": "Phillaur",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781009,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 534318,
        "city": "Polavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533408,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160020,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673011,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 581120,
        "city": "Byadagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140413,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480334,
        "city": "Pandhurna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 224151,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 482011,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 248140,
        "city": "Doiwala",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 851117,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571187,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382265,
        "city": "Dholka",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 533428,
        "city": "Addateegala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 639120,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210341,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 208015,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671542,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781378,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 521133,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802160,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 621220,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845304,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509406,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431518,
        "city": "Kaij",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 784010,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 576224,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144201,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582120,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625513,
        "city": "Bodinayakanur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 148024,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625701,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 307043,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 483990,
        "city": "Raipura",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 843106,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688002,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 500075,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 711414,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 476001,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 629804,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403519,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 132022,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249194,
        "city": "Uttarkashi",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 483001,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 442305,
        "city": "Hinganghat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638312,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177026,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400099,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 185155,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591146,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689674,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670597,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 208020,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560034,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 470000,
        "city": "Khurai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 151110,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783338,
        "city": "Golakganj",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 483053,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 313011,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 603107,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462021,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 222003,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332715,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 576101,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685619,
        "city": "Kuthumkal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 249192,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 145024,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360440,
        "city": "Dhoraji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 275204,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756043,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 613303,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 242220,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571111,
        "city": "Gundlupet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844509,
        "city": "Mahnar Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396321,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 243702,
        "city": "Milak",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670010,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 682026,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 524152,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306023,
        "city": "Marwar Junction",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 473554,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 721101,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175029,
        "city": "Chachyot",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 140506,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689546,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 517330,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686122,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 250106,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689615,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583131,
        "city": "Harpanahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581349,
        "city": "Mundgod",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533289,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 122018,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637406,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723126,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505210,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678615,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 636140,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 262410,
        "city": "Nawabganj-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743004,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635302,
        "city": "Harur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680552,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 585312,
        "city": "Chittapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506317,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303315,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 492003,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 466448,
        "city": "Bareli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 629176,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332402,
        "city": "Bajore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637104,
        "city": "Sankari",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691514,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444102,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442917,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743405,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673651,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 422010,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 691507,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742179,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532190,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623517,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313906,
        "city": "Jhadol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754120,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 766108,
        "city": "Sinapali",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 486550,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 301031,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361305,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 785609,
        "city": "Bokakhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 577211,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700039,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110004,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 757016,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394315,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 845103,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 324006,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627358,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533577,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689578,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 485221,
        "city": "Jaitwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 483105,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 670602,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 244255,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605106,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486111,
        "city": "Raipur Karchulion",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 441601,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 250609,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609205,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620010,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 641025,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848220,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 591264,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854201,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 486881,
        "city": "Deosar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 182315,
        "city": "Ramban",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506355,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785619,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 160034,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493776,
        "city": "Dhamtari",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 845415,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 284128,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847302,
        "city": "Pupri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754136,
        "city": "Tirtol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670511,
        "city": "Nileshwar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 461001,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 766036,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 502114,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700000,
        "city": "Kolkata",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 364515,
        "city": "Savarkundla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 231213,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560039,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 242221,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175037,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 604404,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500102,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 490004,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 835219,
        "city": "Ormanjhi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 281303,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370675,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 734214,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 227815,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134103,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 613104,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250101,
        "city": "Baghpat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431506,
        "city": "Selu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110038,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 148031,
        "city": "Sunam",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678508,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 470001,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 415405,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 533004,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388325,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 246440,
        "city": "Gairsain",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847401,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143410,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225125,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522302,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751007,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 284125,
        "city": "Talbahat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384292,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 610114,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801502,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 210432,
        "city": "Muskara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571117,
        "city": "Kollegala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500083,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721604,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212217,
        "city": "Sirathu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250341,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 484770,
        "city": "Jaisinghnagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 845468,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 584132,
        "city": "Sindhanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516431,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638055,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335501,
        "city": "Bhadra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689541,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788160,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 752100,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757079,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 243725,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224120,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502205,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571249,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562104,
        "city": "Chikkaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400104,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841240,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 494333,
        "city": "Charama",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 641026,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635114,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250013,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799155,
        "city": "Santirbazar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 509301,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713216,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274704,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712250,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 181202,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571130,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 641005,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416115,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 601204,
        "city": "Gummudipoondi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713325,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506331,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 484334,
        "city": "Kotma",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 444605,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638181,
        "city": "Velur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400018,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 301022,
        "city": "Viratnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400015,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577503,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574324,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383462,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 110032,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 721447,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 136135,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400003,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400202,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754112,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 210505,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271202,
        "city": "Itia Thok",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605758,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531040,
        "city": "Pedabayalu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302035,
        "city": "Jaipur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 768004,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 421005,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416013,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 382449,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 306306,
        "city": "Sojat",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212302,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271506,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563119,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673301,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 734103,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250003,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413802,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 261505,
        "city": "Mohammadi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844503,
        "city": "Mahnar Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766012,
        "city": "Kesinga",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 225304,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503206,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415704,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388201,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431124,
        "city": "Ambajogai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 612105,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 481335,
        "city": "Waraseoni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 442504,
        "city": "Sironcha",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 335771,
        "city": "Gharsana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 334202,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134111,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 643232,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 455002,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 413710,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442904,
        "city": "Chimur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 676319,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 626188,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802158,
        "city": "Jagdishpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690555,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713383,
        "city": "Mejia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 636309,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313205,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361010,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 695009,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 591287,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403729,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 678502,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 632107,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 797110,
        "city": "Chumukeidma",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 321632,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 638455,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 302003,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845502,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603209,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 395001,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 795148,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 523170,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531035,
        "city": "Anakapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851110,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535128,
        "city": "Garividi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201302,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 486335,
        "city": "Amiliya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 466112,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 328022,
        "city": "Bari",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 184143,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 504296,
        "city": "Kaghaznagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591102,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124022,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 211019,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 755041,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 464990,
        "city": "Mandideep",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673501,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 271872,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 821002,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303329,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 613001,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 488446,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 756020,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577122,
        "city": "Mudigere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 387375,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 440013,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 631004,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560054,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712515,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415110,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 363430,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 444512,
        "city": "Washim",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 754223,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 813121,
        "city": "Punsia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841246,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583227,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591156,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560085,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 825103,
        "city": "Tandwa",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 628304,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630102,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784509,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 609809,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695144,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679103,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 531036,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383245,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 815354,
        "city": "Jamtara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825316,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 637407,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752028,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 209870,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384465,
        "city": "Vijapur",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 142044,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515408,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509126,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176107,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303313,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785684,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 470673,
        "city": "Hatta",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 630502,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176214,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 411125,
        "city": "Pirangut",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 829123,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 146021,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673637,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 390024,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 246174,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607402,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 248007,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 177021,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203408,
        "city": "Hapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480886,
        "city": "Lakhnadon",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 281305,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686571,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 829210,
        "city": "Khalari-JR",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743299,
        "city": "Swarupnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 852219,
        "city": "Udakishanganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609116,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678534,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 485114,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 782460,
        "city": "Diphu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 712158,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 605102,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382005,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 311301,
        "city": "Asind",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741102,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415208,
        "city": "Mandangad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 509203,
        "city": "Tadoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221112,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781129,
        "city": "Boko",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 577448,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142050,
        "city": "Faridkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 604406,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515404,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388140,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 845401,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673578,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 515775,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 590018,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 325209,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 787032,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 172022,
        "city": "Rampur Bushahr",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507165,
        "city": "Wyra",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382870,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 425306,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 632057,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843351,
        "city": "Sheohar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586138,
        "city": "Bijapur",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 843325,
        "city": "Sheohar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274302,
        "city": "Sewarhi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441911,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 440007,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 786006,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 458228,
        "city": "Ratangarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 679592,
        "city": "Valanchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332318,
        "city": "Laxmangarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678533,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 521109,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416609,
        "city": "Kankavali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176087,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224211,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 823002,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 370445,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 752070,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686641,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507004,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 855108,
        "city": "Bahadurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734226,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403504,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 380018,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 574238,
        "city": "Panja",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246721,
        "city": "Bijnor",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803117,
        "city": "Rajgir",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384012,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 813214,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125076,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605006,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686640,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400002,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 462037,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 456004,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 524131,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515767,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600028,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670503,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624710,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 110601,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 713346,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793010,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 829206,
        "city": "Latehar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 261405,
        "city": "Sitapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412105,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 680515,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 365650,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 685514,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689660,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 277306,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743318,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847122,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110055,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 464258,
        "city": "Ganj Basoda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 451442,
        "city": "Thikri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 400037,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712412,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560087,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 845456,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636113,
        "city": "Mettala",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731252,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 173221,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 587302,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 591342,
        "city": "Gokak",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 731122,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643001,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577432,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 582202,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 471001,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 395007,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 517418,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 228171,
        "city": "Dostpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670314,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560021,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 782123,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 736204,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422301,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502246,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302022,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560012,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 758052,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 854338,
        "city": "Birpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626177,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 821114,
        "city": "Goh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221307,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362011,
        "city": "Dhoraji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 788710,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 630305,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391245,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271122,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380210,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 341319,
        "city": "Khatu Khurd",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364490,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700154,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 835223,
        "city": "Simdega",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533437,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752056,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761040,
        "city": "Asika",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 193122,
        "city": "Baramulla",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246147,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670731,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700140,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761031,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303603,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641601,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 756182,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522309,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500096,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799253,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 604304,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332401,
        "city": "Balaran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685566,
        "city": "Kuthumkal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 191112,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 442905,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560100,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591216,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721148,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670358,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 143419,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685506,
        "city": "Kattappana",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 722139,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624616,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757015,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 201314,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360583,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 781137,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 782138,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 609603,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626113,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 207003,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402207,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577521,
        "city": "Jagaluru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 575017,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 491107,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 521322,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522663,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682007,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 277302,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415526,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415501,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 282002,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411008,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 441501,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 494441,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 574504,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 232339,
        "city": "Gahmar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606304,
        "city": "Virudhachalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 481880,
        "city": "Dindori",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 516110,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305008,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 380050,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 431531,
        "city": "Gangakher",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209407,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621301,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396360,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 571443,
        "city": "Hanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132046,
        "city": "Assandh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494114,
        "city": "Konta",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 453112,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 201020,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712146,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184104,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686556,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680570,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413301,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 274001,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500058,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632318,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752061,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 604207,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603319,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403203,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400603,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 621004,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 793107,
        "city": "Cherrapunji",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679586,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 678622,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 450331,
        "city": "Burhanpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 143535,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600129,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403714,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 125051,
        "city": "Ratia",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571219,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835216,
        "city": "Khunti",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 202126,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221304,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627761,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503145,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686669,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 175136,
        "city": "Patlikuhal",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 796471,
        "city": "Zawlnuam",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 413541,
        "city": "Latur",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 735225,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389140,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 609118,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625018,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415716,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 384285,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 502220,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631552,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583284,
        "city": "Yelburga",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 482002,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 700075,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160046,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507116,
        "city": "Manuguru",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415537,
        "city": "Phaltan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 846005,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 390002,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 678651,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 635126,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388713,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 517526,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144029,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768203,
        "city": "Jharsuguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785616,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 781102,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 712514,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847307,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415203,
        "city": "Mandangad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 454774,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 425407,
        "city": "Shindkheda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 603304,
        "city": "Marakkanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796008,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 192306,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382455,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 231307,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 363002,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 573225,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431501,
        "city": "Partur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431206,
        "city": "Jafrabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 606108,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362240,
        "city": "Madhavpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 262554,
        "city": "Munsyari",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690559,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176317,
        "city": "Sundla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 815302,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 742406,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176056,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636108,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 207401,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364760,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802313,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721102,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 210433,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229129,
        "city": "Salon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 152032,
        "city": "Muktsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425524,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 412406,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 402105,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110046,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680666,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 799006,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 360080,
        "city": "Jasdan",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 283123,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507167,
        "city": "Wyra",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462012,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 670673,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 360040,
        "city": "Jasdan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783369,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743603,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341304,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584112,
        "city": "Devadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756114,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 843323,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721150,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141107,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382650,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 480107,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 389155,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783393,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 456335,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 481998,
        "city": "Bichhiya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 832103,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431105,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 524301,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828402,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627007,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680619,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 177025,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628621,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 813222,
        "city": "Kahalgaon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686582,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686013,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 632014,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 584115,
        "city": "Lingasugur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384340,
        "city": "Satlasana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 637001,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 842004,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431602,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574223,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733140,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535523,
        "city": "Kurupam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 676320,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641038,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 833220,
        "city": "Seraikella",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 561211,
        "city": "Gauribidanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303009,
        "city": "Dudu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625302,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271831,
        "city": "Bhinga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533307,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226303,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560077,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227302,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462041,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577144,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799103,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 313904,
        "city": "Sarada",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516005,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 282010,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 635810,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 251315,
        "city": "Khatauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722166,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 631205,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591117,
        "city": "Saundatti",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442204,
        "city": "Talegaon Sp",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 414001,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 151002,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 796081,
        "city": "Kolasib",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 332032,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221405,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783125,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 306705,
        "city": "Falna",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431806,
        "city": "Bhokar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689699,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176029,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 824143,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 222138,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503219,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690525,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413580,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 393004,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 403526,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 679307,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847306,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766002,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 613201,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 845451,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 816117,
        "city": "Maheshpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609402,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757082,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 848121,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441802,
        "city": "Sakoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 533294,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591227,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241202,
        "city": "Sandila",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680052,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 173101,
        "city": "Rajgarh-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 323602,
        "city": "Keshoraipatan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 274508,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680524,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 370002,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 212107,
        "city": "JariBazar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 365460,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 711103,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 576104,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414302,
        "city": "Parner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 363623,
        "city": "Wankaner",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 690509,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 843153,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 831012,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 676551,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 313323,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221009,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600093,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 402201,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577447,
        "city": "Shikaripur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680683,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 756085,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160049,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641044,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680654,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 442301,
        "city": "Hinganghat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 641036,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 603048,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431517,
        "city": "Ambajogai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 722172,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431201,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689592,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621120,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144806,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515004,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 401203,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 690558,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 230144,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388254,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 636805,
        "city": "Palacode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382440,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 638008,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243201,
        "city": "Baheri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444003,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 535521,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248126,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221206,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623504,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227015,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 223101,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 232116,
        "city": "Mughal Sarai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 311403,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591340,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530022,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 811106,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628718,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680699,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 533435,
        "city": "Yerravaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614905,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 804454,
        "city": "Masaurhi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679535,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635113,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622422,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 509103,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370205,
        "city": "Anjar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 524126,
        "city": "Naidupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 251311,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 475661,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 623319,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403503,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 490009,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 370155,
        "city": "Rapar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 637503,
        "city": "Tiruchengode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576217,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504201,
        "city": "Chinnur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416552,
        "city": "Halkarni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 484113,
        "city": "Pendra",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 500055,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627765,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764051,
        "city": "Balimela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 798611,
        "city": "Kiphire",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 441122,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 143093,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642102,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144013,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 811302,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 602027,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364150,
        "city": "Talaja",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 110096,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 182142,
        "city": "Chenani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 243204,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572103,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124412,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141123,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 490012,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 784162,
        "city": "Narayanpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 626121,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224138,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 486884,
        "city": "Singrauli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 614601,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532421,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413606,
        "city": "Omerga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 390016,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 814119,
        "city": "Shikaripara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534202,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518123,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370110,
        "city": "Anjar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522013,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 623308,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841433,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 502331,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761015,
        "city": "Mohana",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680508,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 770072,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721663,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250622,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124112,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680588,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507003,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689594,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 535501,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303002,
        "city": "Achrol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627452,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 324021,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441915,
        "city": "Tumsar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743268,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123110,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 242308,
        "city": "Tilhar",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 795124,
        "city": "Moirang",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 246128,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721642,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572134,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574104,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686520,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396060,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585321,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121003,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 321412,
        "city": "Kherli",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 572114,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334602,
        "city": "Lunkaransar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534462,
        "city": "Chintalpudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691529,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 695574,
        "city": "Kallikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670611,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 802123,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635105,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788121,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305012,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 460553,
        "city": "Amla",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 847103,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124514,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686576,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502256,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 181224,
        "city": "Katra",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244245,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 683541,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502305,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758087,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 391240,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 517291,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122502,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577502,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431110,
        "city": "Gangapur-MH",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 122228,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824112,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 141301,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 602025,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517236,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230302,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222131,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 174028,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 735221,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577219,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535581,
        "city": "Gajapatinagaram",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683578,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 612108,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 421307,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 172023,
        "city": "Rampur Bushahr",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160029,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 132054,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 461335,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 262302,
        "city": "Pilibhit",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673635,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 676523,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 212657,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 606109,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 335041,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 365421,
        "city": "Dhasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 388710,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 841509,
        "city": "Raghunathpur-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206129,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 608304,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741201,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416405,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 415112,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 231215,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518323,
        "city": "Adoni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394101,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 143007,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689514,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 608001,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515123,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713122,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 481001,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 793007,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 721136,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 393020,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 136026,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 764047,
        "city": "Kalimela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 612001,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 181143,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400089,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695603,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754290,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 451337,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 422209,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 411037,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388336,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 521301,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441221,
        "city": "Sindewahi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 210428,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673580,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 755061,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690568,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 671121,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742159,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700008,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521104,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721127,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734105,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 835225,
        "city": "Tamar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 277123,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 855106,
        "city": "Thakurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821309,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362220,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 585431,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721628,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531118,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585212,
        "city": "Afzalpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246483,
        "city": "Joshimath",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229010,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587128,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642112,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509228,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 690526,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 690529,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 733132,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695101,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 160052,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 828307,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 524201,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509105,
        "city": "Kollapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814145,
        "city": "Dumka",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685609,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415570,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 388560,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 683547,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 688533,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 141402,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628004,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 362310,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 110121,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 700053,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176026,
        "city": "Nagrota Surian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 562126,
        "city": "Kanakapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464334,
        "city": "Ganj Basoda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 303703,
        "city": "Chomu",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 584136,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811104,
        "city": "Barbigha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700059,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312601,
        "city": "Nimbahera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627118,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123103,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781372,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 562149,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152026,
        "city": "Muktsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 275301,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402205,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 587116,
        "city": "Kolhar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609502,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243501,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229301,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 486338,
        "city": "Deotalab",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 577531,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678006,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 805108,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 802213,
        "city": "Dinara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678598,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413508,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 276203,
        "city": "Kerakat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249204,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262721,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396521,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 120001,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721662,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531114,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673573,
        "city": "Thamarassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721131,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825336,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416607,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 582209,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 475005,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 722179,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 735137,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 450881,
        "city": "Nepanagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 610206,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141315,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 307029,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313031,
        "city": "Jhadol",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 263128,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226004,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621111,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140102,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700142,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 387002,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 341514,
        "city": "Degana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 854335,
        "city": "Birpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 759128,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 440002,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 232340,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752024,
        "city": "Tangi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 590020,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 800017,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 454331,
        "city": "Kukshi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 573141,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628105,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243124,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743396,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623207,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505505,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600048,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396006,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 533221,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685561,
        "city": "Adimali",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 421205,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 625525,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560067,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 570009,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 847425,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 673323,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416110,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431716,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 854113,
        "city": "Manihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642104,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 834004,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416608,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 530021,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678544,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754101,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 473551,
        "city": "Shivpuri",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 829134,
        "city": "Kedla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713324,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700129,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 201204,
        "city": "Modinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 180010,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824101,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400029,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 250004,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759124,
        "city": "Chhendipada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627436,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 222109,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388370,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 531201,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333310,
        "city": "Neem ka Thana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 786171,
        "city": "Digboi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 203394,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110403,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 581363,
        "city": "Dandeli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742148,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 390006,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400701,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843331,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 283124,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403509,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 851114,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752085,
        "city": "Daspalla",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 815318,
        "city": "Rajdhanwar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788165,
        "city": "Lalapur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 603302,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500264,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 767029,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500065,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632301,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690507,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 212128,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532201,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620027,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 531084,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628203,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713368,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606112,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 382333,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 626154,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 609304,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 471311,
        "city": "Malhera",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 307020,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480331,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 413203,
        "city": "Karmala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 322205,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689571,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 210203,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535218,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 194104,
        "city": "Leh",
        "state": "Ladakh",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641009,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577518,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416510,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 632102,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440008,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583132,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307026,
        "city": "Abu Road",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712410,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176201,
        "city": "Palion",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574107,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741196,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403513,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 142025,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 127029,
        "city": "Bahal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 421303,
        "city": "Kudus",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574243,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 474007,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 493661,
        "city": "Abhanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 395003,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 841221,
        "city": "Dighwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670143,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 802118,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 671315,
        "city": "Kanhangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 533445,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281402,
        "city": "Vrindavan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230138,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713430,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415508,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413737,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 451220,
        "city": "Maheshwar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 621729,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 402403,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 387330,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 756125,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623104,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176601,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700114,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 136117,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382018,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 174030,
        "city": "Dulchehra",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 421999,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670105,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 602101,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673525,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624309,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533202,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600100,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600104,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 624307,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712615,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 248159,
        "city": "Herbertpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494665,
        "city": "Bhanupratappur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 206249,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754210,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 273306,
        "city": "Bhathat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574116,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799262,
        "city": "Dharmanagar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 488058,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 505184,
        "city": "Manthani",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142058,
        "city": "Dharamkot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785006,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 827009,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679538,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 763001,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722102,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 231310,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847123,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 515455,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 532407,
        "city": "Ranastalam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389382,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 464884,
        "city": "Begumganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 635812,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 174012,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122105,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 225408,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415724,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431116,
        "city": "Vaijapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571315,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413530,
        "city": "Nilanga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521321,
        "city": "Pedapadu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577224,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628252,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 363641,
        "city": "Morbi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 444808,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 181121,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605003,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 824235,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783335,
        "city": "Agomani",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 791110,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 768105,
        "city": "Rairakhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 244717,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741163,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 768115,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713342,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413310,
        "city": "Mhaswad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 822127,
        "city": "Mahagama",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673012,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 574118,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 770052,
        "city": "Koira",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508234,
        "city": "Kusumanchi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682008,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502247,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396466,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 212218,
        "city": "Saray Ankil",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224143,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 248116,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713173,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614906,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686035,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 443106,
        "city": "Buldhana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576223,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 331305,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384165,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 641037,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 490027,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 571436,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313204,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222203,
        "city": "Mariahu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627120,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 602002,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799205,
        "city": "Teliamura",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627719,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632506,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 487110,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 444609,
        "city": "Amravati",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 682304,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 802155,
        "city": "Jagdishpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 852101,
        "city": "Udakishanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 145023,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577426,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370415,
        "city": "Mundra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 394110,
        "city": "Kosamba",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 471405,
        "city": "Malhera",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 302020,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 495442,
        "city": "Ratanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 570017,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522502,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770023,
        "city": "Rajgangpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517507,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 245411,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247554,
        "city": "Deoband",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 765019,
        "city": "Muniguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571118,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127312,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574113,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741508,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678019,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 176225,
        "city": "Sihunta",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502311,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122108,
        "city": "Nuh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497339,
        "city": "Baikunthpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 344024,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686516,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743290,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 328001,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 787026,
        "city": "Gogamukh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 734102,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 490013,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 284401,
        "city": "Talbahat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110603,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 273207,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 231313,
        "city": "Manda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 401202,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 534328,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140417,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 731129,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335528,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 403005,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 781315,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 181204,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144401,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 276137,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785663,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 770031,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425409,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 583153,
        "city": "Bellary",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 721450,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 321633,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683562,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689611,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621311,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678013,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 421507,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 396195,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 743347,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 472010,
        "city": "Tikamgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 781381,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 175019,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754021,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 226000,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761119,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571110,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712301,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733246,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 770024,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 202390,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 603108,
        "city": "Thiruporur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700073,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782001,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 494221,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 500091,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577114,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686568,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 160019,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 848203,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639116,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 343030,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360325,
        "city": "Amreli",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 388540,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 244921,
        "city": "Bilaspur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756115,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743372,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680673,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 412304,
        "city": "Supe",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 641202,
        "city": "Madukkarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626201,
        "city": "Sattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400708,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 127035,
        "city": "Hansi",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759106,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712105,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 736206,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 312000,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752020,
        "city": "Nirakarpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 711104,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 393002,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 641407,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755025,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679523,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 401404,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 424004,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 676317,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 142033,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 805110,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 632113,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 853202,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781024,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 723132,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 462010,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 143422,
        "city": "Goindwal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 193108,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741315,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642105,
        "city": "Valparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394241,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 425120,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 311021,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711413,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641401,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 711301,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 531023,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752106,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144216,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389150,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 530017,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 793115,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 695502,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 470002,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 241407,
        "city": "Pihani",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533251,
        "city": "Narsapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627654,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686636,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 471111,
        "city": "Harpalpur-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 454345,
        "city": "Kukshi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 821101,
        "city": "Mohania",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625538,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144404,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 304001,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400016,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 201004,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515415,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683521,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 642115,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416219,
        "city": "Mudal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110602,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 271865,
        "city": "Nanpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 826004,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 212111,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600007,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 562160,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516150,
        "city": "Rajampet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462038,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 583236,
        "city": "Yelburga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642133,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321611,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 173222,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416213,
        "city": "Bambavade",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303305,
        "city": "Bassi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530006,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635110,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638402,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209201,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844121,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 472446,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 203141,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854107,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416206,
        "city": "Kale",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271830,
        "city": "Nanpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228120,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 272178,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 828122,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122014,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571434,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630002,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388515,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413720,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 585305,
        "city": "Chincholi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721161,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700116,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 533434,
        "city": "Samarlakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396120,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 754157,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 607203,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505469,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627652,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731131,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160047,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 314035,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 135103,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221008,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686573,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 562123,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691534,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742187,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600103,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 424005,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843122,
        "city": "Saraiya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782125,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 440018,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 712305,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841504,
        "city": "Raghunathpur-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425507,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 496445,
        "city": "Saraipalli",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 396194,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 762018,
        "city": "Kantamal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 851118,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522303,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628302,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 853205,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767032,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141316,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415124,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416103,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 228159,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133001,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 723164,
        "city": "Purulia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 333021,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440009,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 522510,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229841,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415212,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 627128,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271303,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144509,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591306,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444608,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 250620,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627551,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281201,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274705,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 313301,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624308,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229419,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785698,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 607807,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641118,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385543,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 731216,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781337,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 563137,
        "city": "Malur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209214,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 124010,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623604,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382861,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 764044,
        "city": "Boipariguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 768109,
        "city": "Riamal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332030,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638657,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781303,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 835203,
        "city": "Bero",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 389370,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 793104,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523286,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585217,
        "city": "Afzalpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440019,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416143,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 813213,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756028,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625536,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743345,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781127,
        "city": "Boko",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 741170,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 851132,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 639113,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283130,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144606,
        "city": "Sultanpur Lodhi",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 620013,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581361,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 812010,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141007,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 182141,
        "city": "Chenani",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605013,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805125,
        "city": "Sirdala",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673532,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 721253,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626005,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 248001,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768225,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 389380,
        "city": "Limkheda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 712426,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 586220,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573201,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641304,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686578,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781132,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 176030,
        "city": "Khundian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695028,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 670507,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382620,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 631606,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803119,
        "city": "Bihar Sharif",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695025,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 497224,
        "city": "Kusmi",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 326514,
        "city": "Chaumahla",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 751010,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508116,
        "city": "Bhuvanagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 477116,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 843124,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144042,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 825321,
        "city": "Tandwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532168,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382760,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 831002,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 638007,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629305,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641603,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303109,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 494347,
        "city": "keshkal",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 382327,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 792131,
        "city": "Longding",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 623404,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678531,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 360560,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 401605,
        "city": "Kudus",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 601207,
        "city": "Gummudipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626132,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 460554,
        "city": "Amla",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 722201,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574301,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400086,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 711308,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505472,
        "city": "Husnabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362725,
        "city": "Kodinar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 522036,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362120,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 623205,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721126,
        "city": "Lutunia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 344037,
        "city": "Phalsund",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700044,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 833105,
        "city": "Chakradharpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 335002,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623406,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431808,
        "city": "Dharmabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754108,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301401,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413102,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 458389,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 495677,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 580011,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231001,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627902,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415805,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 573112,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680005,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689542,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 171003,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394305,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 635805,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686607,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 573135,
        "city": "Channarayapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212503,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229305,
        "city": "Gauriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605756,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400613,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 711322,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 848133,
        "city": "KalyanpurBR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 785643,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 841222,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442907,
        "city": "Bhadravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 845449,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679123,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523301,
        "city": "Martur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229123,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176052,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360002,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 481116,
        "city": "Malanjkhand",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 145026,
        "city": "Dinanagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635818,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 486003,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 515231,
        "city": "Gorantla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425107,
        "city": "Chopda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271802,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799145,
        "city": "Manu bazar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 627859,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670319,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689654,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 497101,
        "city": "Ambikapur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 629155,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443206,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571216,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614019,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534156,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523108,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761141,
        "city": "Galleri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606402,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614809,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210010,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382728,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 841411,
        "city": "Marhowarah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721658,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 227408,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 488220,
        "city": "Panna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 233224,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614623,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614013,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224232,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424207,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 244923,
        "city": "Bilaspur-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416210,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678688,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 152031,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 151210,
        "city": "Malout",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431154,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442902,
        "city": "Bhadravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682034,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 401207,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 802102,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 520010,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 361330,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635819,
        "city": "Ambur",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 506175,
        "city": "Parkal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 146113,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625221,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 770051,
        "city": "Lahunipara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518401,
        "city": "Nandikotkur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221107,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 829133,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 146103,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581325,
        "city": "Dandeli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573128,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394350,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 411220,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 490001,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 472338,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 361130,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 476332,
        "city": "Sabalgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 521390,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781341,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 573127,
        "city": "Sakleshpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416122,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638664,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741232,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 193201,
        "city": "Sopore",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686597,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 563101,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 142002,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 450332,
        "city": "Nepanagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 193123,
        "city": "Uri",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690541,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 688542,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689705,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 623208,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635202,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531031,
        "city": "Anakapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679329,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 208016,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845107,
        "city": "Valmiki Nagar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560010,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 423104,
        "city": "Manmad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 624104,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585326,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690528,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 402302,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500040,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534432,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125005,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721664,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403510,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416121,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713322,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 496450,
        "city": "Sarangarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 441201,
        "city": "Pauni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 125021,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444505,
        "city": "Washim",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 210125,
        "city": "Baberu",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572218,
        "city": "Huliyar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671533,
        "city": "Parappa",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400083,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 504346,
        "city": "Utnoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574123,
        "city": "Mangalore",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 700021,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 788164,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 413324,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 731103,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734216,
        "city": "Pokhriabong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841440,
        "city": "Gopalganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627103,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384131,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721425,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673653,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 277305,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680506,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680604,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 734321,
        "city": "Tista Bazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689653,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 466115,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 233227,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 722161,
        "city": "Mirzapur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623513,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682313,
        "city": "Kanjiramattom",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 272001,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 391760,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 622401,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722146,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230139,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 855105,
        "city": "Katihar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712513,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206120,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134113,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 211007,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759024,
        "city": "Kamakhyanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752015,
        "city": "Pipili",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 492013,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 146112,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133104,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 813227,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 482006,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 201011,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721652,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 301712,
        "city": "Khairthal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712258,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 688506,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 313602,
        "city": "Bhatewar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782447,
        "city": "Lumding",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 754035,
        "city": "Athagarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627814,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691007,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 769004,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 226023,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 610106,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534451,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 769041,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585102,
        "city": "Gulbarga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713372,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 829101,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679101,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 731255,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230142,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625708,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629159,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 497451,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 441206,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591307,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584101,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413216,
        "city": "Akkalkot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273017,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802316,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623612,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 389351,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 754142,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 457552,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 600021,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576103,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 442001,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743245,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 613204,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431713,
        "city": "Bhokar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 501203,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532460,
        "city": "Palakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 184202,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 281403,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226005,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632314,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262532,
        "city": "Berinag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605757,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736159,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431604,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673504,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583233,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176304,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342307,
        "city": "Ghator",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743124,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383450,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 324010,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 577213,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784153,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 679503,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628209,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411007,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 731123,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431803,
        "city": "Kinwat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 563123,
        "city": "B Kothakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691539,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641402,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 242001,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623409,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847235,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 814153,
        "city": "Dumka",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 604203,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492007,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 793018,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 757092,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 604408,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695575,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 465335,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700047,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523214,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123302,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422501,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 781125,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 577214,
        "city": "Shikaripur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517417,
        "city": "Punganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628104,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414502,
        "city": "Shevgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209503,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700066,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122219,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625012,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 502295,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 586128,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412303,
        "city": "Saswad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 828207,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413721,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 625119,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182126,
        "city": "Udhampur",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413563,
        "city": "Udgir",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 500001,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 518596,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600113,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630710,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396238,
        "city": "Silvassa-DNH",
        "state": "Dadra and Nagar Haveli",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 753071,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756165,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686584,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 504002,
        "city": "Adilabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788011,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 582205,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508224,
        "city": "Tungaturthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624402,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768031,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506144,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783337,
        "city": "Dotma",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 851134,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 736132,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743503,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636016,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 231305,
        "city": "Chunnar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841206,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506167,
        "city": "Jangaon",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847304,
        "city": "Bharwara",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509352,
        "city": "Makthal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177211,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786184,
        "city": "Chabua",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 392105,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 630408,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731101,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825301,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 301001,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829124,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 490028,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 301021,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 148101,
        "city": "Barnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742132,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 607005,
        "city": "Cuddalore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711408,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676553,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 800005,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522259,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382705,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 636302,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160065,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 363510,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 626139,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768034,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 762109,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757106,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 741253,
        "city": "Gopalnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781141,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 360025,
        "city": "Chotila",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 713517,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384207,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 412412,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 488448,
        "city": "Pawai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 841208,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631207,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752019,
        "city": "Nirakarpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583135,
        "city": "Kudligi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606704,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413118,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 483330,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 622506,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576211,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144503,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847204,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743424,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176095,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 723152,
        "city": "Balarampur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177028,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 229415,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581326,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221711,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721443,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754104,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695588,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 582116,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 208024,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712149,
        "city": "Pandua",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122008,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627770,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712205,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 639119,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 390013,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 845440,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 311011,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606102,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230127,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754289,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679314,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 721303,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341306,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 321406,
        "city": "Weir",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271208,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 471313,
        "city": "Shahgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 176310,
        "city": "Chamba",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 307803,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246749,
        "city": "Najibabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642154,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391243,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 682312,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 224235,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491335,
        "city": "Bemetara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 171217,
        "city": "Haripurdhar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411041,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 736160,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804451,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 795107,
        "city": "Kangpokpi",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 394550,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 201308,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754030,
        "city": "Badamba",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583217,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322027,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246178,
        "city": "Srinagar-UK",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743331,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143305,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 211006,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799015,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 781007,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 361141,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 475003,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 285128,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721454,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 456006,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 690517,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144519,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 580115,
        "city": "Dharwad",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 754135,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 302011,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 313026,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 828104,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 323021,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742136,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 229502,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 394212,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 389330,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 400026,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 493344,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 121007,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793022,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 788727,
        "city": "Patharkandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 481332,
        "city": "Waraseoni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 695528,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581308,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307513,
        "city": "Reodar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691566,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560109,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600116,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591237,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403717,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 345023,
        "city": "Pokhran",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756110,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 410406,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 503186,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 301706,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509381,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176027,
        "city": "Nagrota Surian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606305,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 304022,
        "city": "Chaksu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 142022,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 636115,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 563126,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627106,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456009,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 813116,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 517235,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843302,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 331518,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 816116,
        "city": "Rajmahal",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431714,
        "city": "Loha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415802,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 766106,
        "city": "Komana",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 452008,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 848503,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 742302,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673123,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 403724,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 741403,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 175121,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144007,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 833219,
        "city": "Seraikella",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305404,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612002,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571174,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835205,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176215,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844129,
        "city": "Buxar",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 636503,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573123,
        "city": "Shanivarasanthe",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712121,
        "city": "Hooghly",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 795103,
        "city": "Kakching",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 201010,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 181124,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 735304,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 613202,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673645,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585422,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627908,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581315,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431129,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 312002,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712424,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411044,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686674,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721430,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396171,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 621651,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 395345,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 828142,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683575,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 327021,
        "city": "Ghatol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690523,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 490021,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 606105,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 332312,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110041,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 689584,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 229124,
        "city": "Salon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143536,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803232,
        "city": "Rajgir",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 671321,
        "city": "Kasaragod",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 380014,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 613008,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443404,
        "city": "Nandura",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841432,
        "city": "Mirganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144205,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 142046,
        "city": "Bagha purana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743329,
        "city": "Canning",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600014,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416007,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 517319,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613701,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580025,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 211016,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534166,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686631,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 303011,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364006,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 637014,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629188,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577566,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335073,
        "city": "PadampurRJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500021,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143008,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431646,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 201001,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 516501,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560081,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627718,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 477557,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 721137,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781320,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 414701,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441202,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 494222,
        "city": "Bastar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 799130,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 638501,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560020,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 764003,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507129,
        "city": "Nellipaka",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722134,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 501201,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416229,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841243,
        "city": "Mairwa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 344601,
        "city": "Barmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802111,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517390,
        "city": "B Kothakota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507170,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423602,
        "city": "Kopargaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 388240,
        "city": "Anand",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 791104,
        "city": "Pasighat",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 152127,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416230,
        "city": "Kale",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 591282,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224117,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517416,
        "city": "Palamaner",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680615,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 530046,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517588,
        "city": "Tada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 174023,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 451331,
        "city": "Khargone",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 583239,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507160,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 223226,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 334022,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752090,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695318,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400112,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415310,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273010,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 241001,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756127,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177111,
        "city": "Chintpurni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 244410,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 572227,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534455,
        "city": "Jangareddygudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754023,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 275201,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244411,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110035,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 680702,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680603,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 491003,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 722137,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686020,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 522263,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177001,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226102,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 401302,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 504231,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 753008,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 500047,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683523,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 333305,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 620009,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141206,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 175016,
        "city": "Baijnath",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575020,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382010,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 834010,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560066,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 364710,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 243633,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 769013,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 585307,
        "city": "Chincholi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533483,
        "city": "Addateegala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612202,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262902,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412305,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 768033,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 135003,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 327602,
        "city": "Kushalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638116,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670662,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 614617,
        "city": "Manamelkudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643253,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 331025,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 741157,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841403,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585441,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415525,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 243002,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 152028,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721505,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388545,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 472331,
        "city": "Jatara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 502301,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828108,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 508206,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641669,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700161,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629153,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731201,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 784190,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 678706,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 643270,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754238,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342004,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 678621,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 828115,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 590009,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 425444,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628851,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733125,
        "city": "Kaliyaganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680522,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 205301,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 302000,
        "city": "Jaipur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 140407,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416112,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 227305,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574119,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385505,
        "city": "Deesa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 522235,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781136,
        "city": "Boko",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 802210,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577134,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229215,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 474008,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 533223,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604154,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695309,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572201,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613403,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503322,
        "city": "Nizamabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743232,
        "city": "Helencha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507204,
        "city": "Bonakal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752022,
        "city": "Tangi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400085,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 516454,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530035,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670701,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 385310,
        "city": "Dhanera",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 518465,
        "city": "Alur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364250,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 326519,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509153,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641020,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 271215,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852124,
        "city": "Saharsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 603002,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 509202,
        "city": "Shadnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534340,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688001,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 582212,
        "city": "Navalgund",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 755033,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754005,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743246,
        "city": "Bongaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713123,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591312,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 787051,
        "city": "North Lakhimpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 242227,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 680721,
        "city": "Chalakudy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 638453,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768003,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754100,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441105,
        "city": "Savner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 483440,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 608501,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302039,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 523112,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605110,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403731,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 783354,
        "city": "Dotma",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 832101,
        "city": "Chakulia",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 148034,
        "city": "Dhuri",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505490,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785107,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 695615,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 641042,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271861,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 127308,
        "city": "Loharu",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621108,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784526,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 805236,
        "city": "Wazirganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 142031,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382230,
        "city": "Dholka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 637401,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504299,
        "city": "Babasagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 223105,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673640,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636003,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848504,
        "city": "Mohiuddin Nagar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743221,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262725,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571442,
        "city": "Kollegala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670018,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 181103,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531028,
        "city": "Chodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673634,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415621,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 782440,
        "city": "Kampur Town",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 741234,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160070,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 484117,
        "city": "Anuppur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 768107,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700158,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 829110,
        "city": "Peterbar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 494230,
        "city": "keshkal",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 140109,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515425,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854101,
        "city": "Kursela",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143513,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686030,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 638115,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 394111,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521151,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627254,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 796101,
        "city": "Kolasib",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 638751,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 753009,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689626,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641007,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632510,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629156,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 832302,
        "city": "Chakulia",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206302,
        "city": "Kishni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410217,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 411025,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 843144,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 587113,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 465689,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 783135,
        "city": "Fekamari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 423201,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 683592,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 732150,
        "city": "Itahar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271870,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591122,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606206,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581412,
        "city": "Yellapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202397,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261502,
        "city": "Dhaurahara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495454,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 341506,
        "city": "Didwana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614630,
        "city": "Manamelkudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683544,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 452018,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 140118,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250501,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628613,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403715,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670622,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 627801,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 586206,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175122,
        "city": "Nagwain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400050,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695606,
        "city": "Venjaramoodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 323025,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741314,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641105,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814111,
        "city": "Maheshpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 393145,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 301024,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785673,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 143108,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403512,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 225404,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494777,
        "city": "Pakhanjur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 741405,
        "city": "Ranaghat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413515,
        "city": "Ahmadpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 822123,
        "city": "Bisrampur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110066,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 770002,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 328031,
        "city": "Bari",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416114,
        "city": "Shirala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 826005,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752077,
        "city": "Kalapathar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754006,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 501323,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 742172,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 737134,
        "city": "Rangpo",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 793017,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 263138,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122234,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415714,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 620602,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 458468,
        "city": "Jawad",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 700081,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 832110,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360531,
        "city": "Jamjodhpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 206124,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522003,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144311,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691511,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 757107,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 207301,
        "city": "Jalesar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394270,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 412410,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 848118,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642204,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 453332,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 413518,
        "city": "Udgir",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 332701,
        "city": "Ajeetgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641608,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784171,
        "city": "Behali",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 574253,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 146101,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574239,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391330,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 736171,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 465333,
        "city": "Shujalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 811201,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441107,
        "city": "Savner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 516396,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496107,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 451559,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110101,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "TRUE",
        "Zone": "N1"
       },
       {
        "pincode": 144512,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415106,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 804421,
        "city": "Tekari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410508,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382260,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 847426,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731125,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577530,
        "city": "Honnali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143519,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531173,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503235,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731215,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712310,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 451661,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 643104,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614022,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843111,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621003,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414203,
        "city": "Ashti",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 201306,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534461,
        "city": "Chintalpudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 497446,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 606808,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342012,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 852220,
        "city": "Udakishanganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841439,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680691,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 445303,
        "city": "Wani",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 791119,
        "city": "Yazali",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 122218,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632603,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382630,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 362221,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700101,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534245,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123305,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571312,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 460661,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 587122,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382017,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 632115,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 333036,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521311,
        "city": "Gannavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743437,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382000,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 276138,
        "city": "Jiyanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686101,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 322001,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571617,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444904,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 151202,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 173213,
        "city": "Solan",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507168,
        "city": "Wyra",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841244,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625201,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 731203,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621211,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751017,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711111,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 755049,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 243722,
        "city": "Babrala",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246495,
        "city": "Rudraprayag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 688545,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 414005,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 122001,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 576115,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425103,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713213,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576121,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721171,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 442502,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574161,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757101,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623519,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676515,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 301407,
        "city": "Khairthal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 181104,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635806,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574202,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682003,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 209402,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 281203,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 485881,
        "city": "Amarpatan",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144510,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700068,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 515212,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 825106,
        "city": "Gomoh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 607006,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743135,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 783376,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 402404,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560107,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 583234,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 475220,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 131027,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 203201,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767016,
        "city": "Sonepur-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 626202,
        "city": "Sattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384240,
        "city": "Harij",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416612,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 678707,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 500049,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 370150,
        "city": "Bhachau",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802128,
        "city": "Rajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125038,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383210,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144410,
        "city": "Phillaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 622304,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741162,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421312,
        "city": "Kudus",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 460004,
        "city": "Betul",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 457335,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 518380,
        "city": "Alur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202521,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854325,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 683513,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721438,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841409,
        "city": "Mohammadpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 243502,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382405,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 577557,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344705,
        "city": "Sheo",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413253,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 202414,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685503,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 522402,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302032,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625703,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410513,
        "city": "Rajgurunagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 228145,
        "city": "Dostpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 797103,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 609802,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591316,
        "city": "Ugar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131408,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 395016,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 781031,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 425202,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 723201,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444804,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 494111,
        "city": "Sukma",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 676305,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686587,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 784508,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 331028,
        "city": "Malsisar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581210,
        "city": "Hirekerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530003,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 465116,
        "city": "Polay Kalan",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 628101,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389158,
        "city": "Dahod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635002,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212661,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630310,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176065,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627811,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571311,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 758078,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 679531,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 577528,
        "city": "Jagaluru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584201,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627130,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688526,
        "city": "Ezhupunna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 493221,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 581175,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246725,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700124,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 276302,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333020,
        "city": "Buhana",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 424119,
        "city": "Chalisgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 301035,
        "city": "Laxmangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848115,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 628006,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 423111,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 391443,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 770012,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382325,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 768019,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 764073,
        "city": "Umerkote",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670694,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 230122,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 485002,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 505302,
        "city": "Sircilla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496225,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 756133,
        "city": "Chandbali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421502,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 623608,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160056,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754206,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788119,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 180017,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788816,
        "city": "Kalain",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 342901,
        "city": "Gotan",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577203,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625010,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413006,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754282,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518345,
        "city": "Yemmiganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686027,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 211014,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 733214,
        "city": "Islampure",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431113,
        "city": "Sillod",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 503207,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515803,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591256,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122210,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 848160,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756086,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 574314,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383230,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 125007,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 793106,
        "city": "Ranikor",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 517101,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 682029,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 851135,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767045,
        "city": "Dunguripali",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 274702,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 134114,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 811103,
        "city": "Barbigha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756042,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688508,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 487114,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 263663,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841426,
        "city": "Bhore",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 147102,
        "city": "Patran",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342008,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676107,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713378,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516390,
        "city": "Pulivendula",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494556,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 581411,
        "city": "Mundgod",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127031,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394335,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 207302,
        "city": "Jalesar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209739,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533290,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600115,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 424109,
        "city": "Manmad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 723142,
        "city": "Raghunathpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854202,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603203,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781008,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 516155,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680616,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572124,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333303,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500005,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486666,
        "city": "Madwas",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 743508,
        "city": "Kolkata",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 228119,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400604,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743337,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743513,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394421,
        "city": "Kosamba",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 534301,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784507,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 402113,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273013,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 401506,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670642,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 584116,
        "city": "Devadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464670,
        "city": "Bareli",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 322245,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571316,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688532,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 125037,
        "city": "Hansi",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384220,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 770016,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 461228,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 670103,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 811105,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734221,
        "city": "Pokhriabong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140125,
        "city": "Anandpur Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741156,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 450991,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 271904,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761117,
        "city": "Polasara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 587317,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174320,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 136042,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 612302,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403402,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 629203,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 206251,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577428,
        "city": "Shiralakoppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691009,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689693,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 757100,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522415,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 393021,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 210201,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 245205,
        "city": "Gajraula",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629252,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444702,
        "city": "Dharni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 221701,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332603,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581234,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689676,
        "city": "Ranni",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 384411,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 622515,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 731241,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415404,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 243003,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 344706,
        "city": "Dhorimana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303507,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 416109,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 228142,
        "city": "Dostpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627727,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416532,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 423204,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 760003,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413709,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713429,
        "city": "Manteswar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756135,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 576117,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711102,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144106,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312201,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 241122,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341519,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175006,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 530011,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700128,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632005,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334005,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 174507,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788819,
        "city": "Haflong",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 271403,
        "city": "Nawabganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276140,
        "city": "Jiyanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 638456,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209745,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521241,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 563139,
        "city": "Malur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384206,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 413532,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 563114,
        "city": "Bangarapet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400110,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 136030,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600080,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 624613,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799115,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 247664,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 826006,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 731247,
        "city": "Nanoor",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 855101,
        "city": "Bahadurganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841420,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 221719,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413502,
        "city": "Barshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441702,
        "city": "Sadak Arjuni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 573126,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506165,
        "city": "Tadvai",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642203,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364330,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 423210,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581450,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829125,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509380,
        "city": "Kothakota",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231211,
        "city": "Mirzapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695584,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577436,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382255,
        "city": "Botad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 686563,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 495683,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 854105,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 136132,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577243,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572143,
        "city": "Hiriyur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388335,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 752023,
        "city": "Tangi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415729,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110072,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 757030,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761013,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686143,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 321407,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 769001,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 535522,
        "city": "Parvathipuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679587,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 144526,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637409,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757033,
        "city": "Rairangpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 248125,
        "city": "Herbertpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690536,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 786147,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 500094,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 284127,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670674,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 276135,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480553,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 788728,
        "city": "Patharkandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 241402,
        "city": "Hardoi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380010,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 175004,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673508,
        "city": "Kuttiady",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689664,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 606902,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606202,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 733142,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591221,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177103,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630313,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758019,
        "city": "Telkoi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 451010,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 575007,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712125,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 612901,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585220,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695040,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 425001,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515211,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670582,
        "city": "Alakode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 341501,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176210,
        "city": "Nagrota Surian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 563133,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534329,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670106,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 321615,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852130,
        "city": "Supaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581115,
        "city": "Ranebennur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741251,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263146,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691008,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 444006,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 600025,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685611,
        "city": "Adimali",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 769010,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673002,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 462018,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 424203,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 328029,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465680,
        "city": "Pachore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 562108,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 757014,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400077,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 845430,
        "city": "Sheohar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 283141,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 322203,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244301,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380008,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 516216,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847211,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 453111,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431738,
        "city": "Parbhani",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 334603,
        "city": "Lunkaransar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743129,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 391776,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400068,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 209303,
        "city": "Akbarpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508287,
        "city": "Haliya",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 735230,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 671316,
        "city": "Kanhangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 191204,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "Zone": "N2"
       },
       {
        "pincode": 421002,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 431108,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 148021,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583277,
        "city": "Kushtagi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413224,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 207243,
        "city": "Ganjdundwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494335,
        "city": "Kanker",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 843301,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 232105,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711110,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 524321,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 127020,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160001,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500071,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384435,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 811107,
        "city": "Sheikhpura",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 285211,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396217,
        "city": "Daman",
        "state": "Daman & Diu",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 251306,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303110,
        "city": "Viratnagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 683581,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 203393,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425434,
        "city": "Chopda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 688522,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 509375,
        "city": "Achampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764077,
        "city": "Chandahandi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 380016,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 782106,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 143514,
        "city": "Sri Hargobindpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627659,
        "city": "Thisayanvilai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805132,
        "city": "Pakribarwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627418,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758041,
        "city": "Champua",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621116,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302029,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 389170,
        "city": "Jhalod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680312,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 431708,
        "city": "Loha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686671,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713404,
        "city": "Jamalpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152124,
        "city": "Fazilka",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835235,
        "city": "Simdega",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600053,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670002,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 741168,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627011,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 782475,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 832105,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581204,
        "city": "Kalaghatgi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533256,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 610005,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573130,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612502,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416216,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394115,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 571102,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752057,
        "city": "Khurdha",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124104,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387220,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 673015,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 635307,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829146,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 470775,
        "city": "Hatta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 601301,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572130,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629802,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766032,
        "city": "Kesinga",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 829131,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804407,
        "city": "Khodaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600102,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 643003,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631501,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172102,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 306307,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783386,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 110065,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 735226,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624054,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412110,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581307,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811313,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415717,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 574242,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451667,
        "city": "Pansemal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 328024,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630301,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 472101,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 190010,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203407,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396115,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 284123,
        "city": "Lalitpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500133,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683580,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641659,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574272,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 734314,
        "city": "Labha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 281404,
        "city": "Chhata",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743439,
        "city": "Hingalganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 232332,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521165,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 472001,
        "city": "Tikamgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 627712,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742160,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 603307,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416523,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 181209,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 383250,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 388465,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 305802,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412306,
        "city": "Nira",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382022,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 722121,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689698,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 132122,
        "city": "Samalkha",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532005,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 804401,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572106,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577551,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636141,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572107,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263653,
        "city": "Dwarahat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400101,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686601,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 686670,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686540,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 271902,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422009,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 854106,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 784168,
        "city": "Gohpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 208120,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 756001,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 756111,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 345027,
        "city": "Sheo",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 440034,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686689,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 211021,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735202,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226010,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 314024,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 171222,
        "city": "Theog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 476444,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 321006,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203145,
        "city": "Bulandshahr",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679357,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 673649,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 140802,
        "city": "Sirhind-Fategarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442914,
        "city": "Bhadravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 385360,
        "city": "Radhanpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802131,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641408,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680007,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 142038,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 226305,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 472447,
        "city": "Prithvipur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754228,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534229,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 570001,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 221401,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380052,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 733141,
        "city": "Gangarampur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518412,
        "city": "Nandikotkur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689108,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 457777,
        "city": "Petlawad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 328035,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845305,
        "city": "Raxaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 341515,
        "city": "Degana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509133,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382765,
        "city": "Viramgam",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 711309,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431530,
        "city": "Parli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 284305,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635108,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713219,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821315,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 365450,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 332021,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 488001,
        "city": "Panna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 612402,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768050,
        "city": "Nuapada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 127042,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 133207,
        "city": "Ambala",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110049,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 416630,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 604306,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680504,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781319,
        "city": "Kalgachia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 770041,
        "city": "Koira",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 145001,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 335025,
        "city": "Ganga Nagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410200,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 327031,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441217,
        "city": "Kurkheda",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577229,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224209,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 492102,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 792120,
        "city": "Changlang",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 691546,
        "city": "Kottarakkara",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 562114,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688004,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 606802,
        "city": "Polur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470223,
        "city": "Jhunku",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 785012,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 821112,
        "city": "Kochas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 127043,
        "city": "Siwani",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534243,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505531,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 586105,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531087,
        "city": "Koyyuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676101,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 211104,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 501218,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 604208,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423205,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686032,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 811241,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400056,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 127311,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441605,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 711307,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250402,
        "city": "Mawana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 205121,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609201,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688565,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 799284,
        "city": "Dhalai",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 741150,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 273016,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533005,
        "city": "Kakinada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603106,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603406,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534251,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631604,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743512,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305405,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412215,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 689580,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 690542,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 389265,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680026,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571807,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515001,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 201318,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 788113,
        "city": "Bhaga",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 533286,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 304804,
        "city": "Deoli-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688541,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631701,
        "city": "Cheyyar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700023,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176002,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 422302,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 160035,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843133,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 622315,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795127,
        "city": "Kakching",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 673315,
        "city": "Balussery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 670101,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 641065,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396030,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 823001,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160053,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 712428,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629175,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680664,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713512,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 241123,
        "city": "Shahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221408,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626526,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227808,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721457,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 685588,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 481664,
        "city": "Ghugri",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742226,
        "city": "Morgram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 587104,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229503,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505454,
        "city": "Dharmapuri-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623537,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606106,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 251307,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632203,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754209,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799141,
        "city": "Santirbazar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 225411,
        "city": "Rudauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370665,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691550,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 304801,
        "city": "Tonk",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212401,
        "city": "Phulpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416111,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 604202,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754140,
        "city": "Tirtol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500045,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 441223,
        "city": "Sindewahi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571116,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686124,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 781035,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305625,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243304,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517561,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370605,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 431704,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 127111,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450110,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 222170,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413739,
        "city": "Shrirampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743263,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533217,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415626,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 680573,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571165,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795104,
        "city": "Tadubi",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 673307,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600061,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 573147,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 343001,
        "city": "Jalore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382655,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 209621,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416107,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 828202,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141001,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 722208,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412205,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 573144,
        "city": "Arsikere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811211,
        "city": "Munger",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 133204,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 391160,
        "city": "Chhota Udaipur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416102,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 613102,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413210,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 723154,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804423,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804433,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 642107,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533212,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501504,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382027,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 394340,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783325,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 581337,
        "city": "Yellapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225409,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410504,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 420421,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 575159,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625702,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678686,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781352,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 415803,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 683591,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 412307,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110011,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 484665,
        "city": "Ranchha",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 121010,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 576212,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123101,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678543,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 604403,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686522,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679321,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743133,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143001,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799271,
        "city": "Kumarghat",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686562,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 686672,
        "city": "Koothattukulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742409,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 173218,
        "city": "Kunihar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 822126,
        "city": "Satbarwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431712,
        "city": "Umarkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 508280,
        "city": "Tungaturthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325206,
        "city": "Baran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177213,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227809,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363610,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 802205,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756137,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 742407,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 274407,
        "city": "Sewarhi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273212,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680306,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 736175,
        "city": "Dinhata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 344701,
        "city": "Sheo",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502381,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247343,
        "city": "Nanauta",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786172,
        "city": "Margherita",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 232102,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370201,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 301420,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 284205,
        "city": "Mauranipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518102,
        "city": "Srisailam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362258,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 396425,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 425318,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 277121,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695106,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 173204,
        "city": "Dharampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521136,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 812001,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 757028,
        "city": "Betnoti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731233,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686612,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110030,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 473585,
        "city": "Chanderi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 508247,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754132,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 813102,
        "city": "Banka",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 842010,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271001,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495671,
        "city": "Champa",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 635101,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271404,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 643219,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721211,
        "city": "Ghatal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609505,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534280,
        "city": "Narsapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781351,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 743313,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691555,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788736,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 143066,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 475335,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 160010,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444101,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 521424,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713332,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 735233,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600074,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 175047,
        "city": "Thunag",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 453661,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 577523,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732204,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734005,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799011,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 452013,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144105,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689663,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700062,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 248161,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752034,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691583,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629164,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521105,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144030,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444603,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 764049,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 473660,
        "city": "Karera-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 785603,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 517423,
        "city": "Kuppam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400019,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 396015,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 845519,
        "city": "Motihari",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 793108,
        "city": "Cherrapunji",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 625115,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689547,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 847424,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614805,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382845,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 628809,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 403404,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 203202,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396126,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 829115,
        "city": "Phusro",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 274409,
        "city": "Sewarhi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271313,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516437,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 249205,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785631,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 401204,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517128,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621129,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 262407,
        "city": "Bareilly",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 782486,
        "city": "Hamren",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 629157,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 523274,
        "city": "Singarayakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 586106,
        "city": "Bijapur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700058,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581127,
        "city": "Hangal",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 508117,
        "city": "Bhuvanagiri",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600012,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848209,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 722170,
        "city": "Bankura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782450,
        "city": "Kampur Town",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 415705,
        "city": "Shringartali",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843116,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 176042,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 520005,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686611,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 803111,
        "city": "Pavapuri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799045,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 507159,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 232118,
        "city": "ChakiaUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509371,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758044,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670649,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 226013,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 232106,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530023,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843108,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 845434,
        "city": "Jiwdhara",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 636102,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722145,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 342002,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 612605,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786157,
        "city": "JaytishNagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 506244,
        "city": "Ghanpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391121,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 520008,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 202395,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382120,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 485666,
        "city": "Jaitwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 670110,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 795113,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 623111,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444819,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 845487,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 395006,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 796009,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 192301,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768025,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821304,
        "city": "Rohtas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 132117,
        "city": "Karnal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176319,
        "city": "Sundla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 731219,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 136043,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396412,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 670102,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 210207,
        "city": "Chitrakoot",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 491559,
        "city": "Pandariya",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 281121,
        "city": "Vrindavan",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 283206,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783374,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 413209,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110076,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 462003,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 502277,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621126,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456668,
        "city": "Tarana",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 312027,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742237,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 202151,
        "city": "Jewar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 563103,
        "city": "Kolar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 815301,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673619,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 442006,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 326512,
        "city": "Bhawani Mandi",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515867,
        "city": "Rayadurgam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 477446,
        "city": "Lahar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 271825,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785648,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 601101,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110093,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 421003,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413719,
        "city": "Shirdi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 207250,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600068,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 401209,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 261125,
        "city": "Sitapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607403,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530008,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 604201,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 520002,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 273165,
        "city": "Anandnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332405,
        "city": "Bajore",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 844117,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 251327,
        "city": "Deoband",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 333027,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 261205,
        "city": "Biswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761151,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 481768,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 143528,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713171,
        "city": "Panagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 825327,
        "city": "hazaribagh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431103,
        "city": "Kannad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686010,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 124107,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516002,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 313902,
        "city": "Sarada",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425105,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 670593,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 755001,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250001,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388120,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 443209,
        "city": "Dusrabid",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 614615,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762024,
        "city": "Boudh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841201,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523316,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364310,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 534236,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721139,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 476221,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 146006,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605002,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 670302,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 495002,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 785693,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 752105,
        "city": "Pipili",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412217,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 442404,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 277304,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577220,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521132,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313326,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 383120,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 203412,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507163,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533346,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201000,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 610104,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 370650,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 756167,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394317,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 793114,
        "city": "Marshillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 208004,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 506132,
        "city": "Narsampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508373,
        "city": "Chandur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281005,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503217,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521150,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755027,
        "city": "Binjharpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 814114,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535004,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202143,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424301,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 785104,
        "city": "Majuli",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 678685,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 201005,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686572,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400005,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 414306,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 275304,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641046,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721433,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591344,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577125,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 285121,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700052,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 835220,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 690527,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 822117,
        "city": "Japla",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614012,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795130,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 228178,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678555,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 401702,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 212635,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131030,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229315,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768038,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614101,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 570020,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444805,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263636,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444005,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 622408,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 201006,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624005,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396330,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 581328,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143506,
        "city": "Batala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755018,
        "city": "SUKINDA",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680004,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 174021,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 243631,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735201,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523331,
        "city": "Doranala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689652,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 124127,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 206253,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625214,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209208,
        "city": "Ghatampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362231,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 721146,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226104,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814158,
        "city": "Poriyahat",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 451225,
        "city": "Maheshwar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 679309,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 496224,
        "city": "Bargidih",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 583129,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413544,
        "city": "Latur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 621716,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 184103,
        "city": "Hiranagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262401,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761115,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743355,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506002,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 678005,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144036,
        "city": "Nakodar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 735205,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 111120,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 535592,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 325203,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209827,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360360,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 231214,
        "city": "Robertsganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380057,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 643103,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 231226,
        "city": "Dudhinagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 790101,
        "city": "Dirang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 202305,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533463,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635209,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521201,
        "city": "Nuzvid",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516268,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132026,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 851218,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788118,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 383315,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 670704,
        "city": "Chavassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 229306,
        "city": "Raebareli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342604,
        "city": "Piparcity",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 415520,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176115,
        "city": "Baijnath",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425116,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 680020,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 515291,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 765024,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394355,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 484774,
        "city": "Beohari",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 814143,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 685533,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 391220,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 230126,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110108,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 678596,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571801,
        "city": "Kollegala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321401,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 314801,
        "city": "Dungarpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384130,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 360515,
        "city": "Bhanvad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 831035,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623531,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814148,
        "city": "Rangalia",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 752067,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 852131,
        "city": "Supaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721502,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 458667,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 275202,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517167,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273301,
        "city": "Kaptanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263601,
        "city": "Almora",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515414,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743427,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 493662,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 273408,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788801,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 835226,
        "city": "Simdega",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441103,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144304,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 491885,
        "city": "Khairagarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 142023,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444913,
        "city": "Warud",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 505306,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671351,
        "city": "Parappa",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 396463,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 532402,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 486771,
        "city": "Sidhi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 151509,
        "city": "Talwandi Sabo",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508202,
        "city": "Haliya",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743204,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 271210,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209801,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 575029,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415602,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757081,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425502,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441224,
        "city": "Mul",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 382725,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 516257,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731132,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680685,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 224204,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768049,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 190007,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 122106,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627152,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124103,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721439,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110018,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 522317,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723153,
        "city": "Balarampur-WB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754253,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 736202,
        "city": "Barobisha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679534,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174402,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626183,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307001,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522659,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524318,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591218,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396220,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521164,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680586,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 233231,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685536,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 387430,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 212214,
        "city": "Saray Ankil",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585244,
        "city": "Aland",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852112,
        "city": "Kumarkhand",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 392014,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 734423,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123412,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 639008,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673655,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742188,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 273152,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 501506,
        "city": "Ibrahimpatnam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 507157,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621714,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 584134,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847402,
        "city": "Phulparas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571403,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781002,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 685531,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 285130,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 233226,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110071,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 841438,
        "city": "Mirganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532195,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522646,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614020,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574258,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 756039,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 678507,
        "city": "Nenmara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 421102,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416308,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 202396,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491881,
        "city": "Khairagarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 504307,
        "city": "Ichoda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 424201,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 700076,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 683112,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 804424,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 636122,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271608,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110058,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 210005,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829150,
        "city": "Ramgarh",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 227116,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 495334,
        "city": "Mungeli",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 210430,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444714,
        "city": "Morshi",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 736182,
        "city": "Jaigaon",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431526,
        "city": "Ambajogai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 854114,
        "city": "Korha",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 285124,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 797109,
        "city": "Tseminyu",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 272173,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721449,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786185,
        "city": "Chabua",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 515865,
        "city": "Rayadurgam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250406,
        "city": "Mawana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500053,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632008,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396236,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 204101,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629151,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752045,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 506172,
        "city": "Tadvai",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425404,
        "city": "Shindkheda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 562107,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 582118,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851128,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 828205,
        "city": "Chirkunda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691580,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 274701,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600035,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 752120,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793160,
        "city": "Khliehriat",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 752112,
        "city": "Pipili",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 517192,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 712612,
        "city": "Kamarpukur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 307802,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636908,
        "city": "Salem",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 761110,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 193224,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 844133,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 723148,
        "city": "Hura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743201,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396215,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680561,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 585319,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415720,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 641114,
        "city": "Madukkarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629901,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 474005,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 628802,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638057,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480557,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 385320,
        "city": "Shihori",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416314,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 711411,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721306,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 765017,
        "city": "Raygada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332404,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 152125,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522268,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494227,
        "city": "keshkal",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 802222,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721129,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123102,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670502,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 516267,
        "city": "Galiveedu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534350,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577121,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416807,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577554,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283115,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680124,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 679340,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 327606,
        "city": "Kushalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415528,
        "city": "Phaltan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 639202,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 811308,
        "city": "Jhajha",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 194401,
        "city": "Diskit",
        "state": "Ladakh",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 785601,
        "city": "Sarupathar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 246162,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673502,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 481551,
        "city": "Linga",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 576113,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272153,
        "city": "Bansi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814101,
        "city": "Dumka",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518503,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 456005,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 629855,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415603,
        "city": "Chiplun",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441303,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 141014,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761143,
        "city": "Jagannathprasad",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734429,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 480996,
        "city": "Nainpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 392170,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 534392,
        "city": "Kovvur",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 301415,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 443203,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743248,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 391775,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 493526,
        "city": "Baloda Bazar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 382030,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 384225,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 244222,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431001,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 630411,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177040,
        "city": "Hamirpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110402,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 262402,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221108,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 451550,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 261501,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577205,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403110,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 628501,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210424,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500195,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533297,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781344,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 490020,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 486447,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713362,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 845450,
        "city": "Bettiah",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734301,
        "city": "Kalimpong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 580023,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691578,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 202122,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 639207,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301029,
        "city": "Alwar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 824216,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416146,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691532,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 788110,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 400070,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 685586,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 603004,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 380030,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 284301,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362004,
        "city": "Junagadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 591224,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 275207,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 185201,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 392162,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 465669,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 326517,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627253,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533344,
        "city": "Bikkavolu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 177118,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 504301,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384221,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713501,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380055,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 441116,
        "city": "Gondia",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 690105,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 387650,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 212660,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621314,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 602026,
        "city": "Tiruvallur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134204,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721403,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 263140,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768213,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 422001,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 202391,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 797001,
        "city": "Kohima",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 271851,
        "city": "Bahraich",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533292,
        "city": "Gokavaram",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517506,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 182202,
        "city": "Doda",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 222143,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560112,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571104,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221603,
        "city": "Madhuban",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625502,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630611,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781028,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 755062,
        "city": "Binjharpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700017,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 828105,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 273004,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605107,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 411021,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 466665,
        "city": "Narsinghgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 341508,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209505,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222301,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222142,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 481051,
        "city": "Malanjkhand",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 811311,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 600084,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 764084,
        "city": "Kotapad",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 608301,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784102,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 735237,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 801111,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754103,
        "city": "Jagatsinghpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 229504,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689515,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 524346,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624624,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781104,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 221314,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734315,
        "city": "Labha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678684,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 583230,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392310,
        "city": "Karjan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431603,
        "city": "Nanded",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 604205,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 212652,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 630702,
        "city": "Paramakudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736131,
        "city": "Barobisha",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 792110,
        "city": "Roing",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 638154,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131304,
        "city": "Gohana",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 770037,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841425,
        "city": "Kuchaikote",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 678583,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600016,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713104,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262528,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321205,
        "city": "Nagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303903,
        "city": "Chaksu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 504273,
        "city": "Dahegaon",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141417,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575028,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 784164,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 122017,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 182146,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761020,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 141121,
        "city": "Machhiwara",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 251001,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141201,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785690,
        "city": "Sonari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 626114,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761211,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 201303,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 801118,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695013,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 676122,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 685613,
        "city": "Munnar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 797101,
        "city": "Peren",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 535532,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851133,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 206125,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607204,
        "city": "Ulundurpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243503,
        "city": "Faridpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585330,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581105,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 604204,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700020,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 140301,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 606709,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209506,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 285202,
        "city": "Orai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759121,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 401103,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144202,
        "city": "Sri Hargobindpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400612,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583238,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494229,
        "city": "Kondagaon",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 636705,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530041,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 562162,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176086,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600111,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843333,
        "city": "Pupri",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632101,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683501,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 691553,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781314,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 314031,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688536,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 443204,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 795009,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 624712,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425418,
        "city": "Navapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 768046,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 486669,
        "city": "Madwas",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 600060,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396380,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141119,
        "city": "Malerkotla",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262123,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673514,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 387110,
        "city": "Mahemdavad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 174004,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303314,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516474,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603306,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364505,
        "city": "Palitana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 501144,
        "city": "Tandur-TL",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160014,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 491230,
        "city": "Bhanupratappur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 141005,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271206,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176097,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 192125,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 492016,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110088,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 576244,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 827006,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 735204,
        "city": "Birpara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 245201,
        "city": "Hapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124202,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274122,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 327001,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122506,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 629168,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670353,
        "city": "Nileshwar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 600128,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176208,
        "city": "Shahpur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637021,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828201,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761006,
        "city": "Chikiti",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517584,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431736,
        "city": "Mukhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 192126,
        "city": "Pahalgam",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517131,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301414,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225124,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 380006,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 759111,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 571232,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243506,
        "city": "Faridpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 585436,
        "city": "Aurad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695312,
        "city": "Attingal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 845418,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403514,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 414111,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 321413,
        "city": "Bayana",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 415410,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 494553,
        "city": "Dantewada",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 636007,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585401,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680553,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 680505,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 227409,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678558,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 854315,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 560072,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 500011,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 607112,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 124501,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "TRUE",
        "Zone": "N1"
       },
       {
        "pincode": 421101,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515833,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 586203,
        "city": "Basavana Bagewadi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741181,
        "city": "Plassey",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263131,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 466445,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 281307,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758016,
        "city": "Karanjia",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731234,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713386,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721142,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 333307,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 145022,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 689581,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 670009,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 276206,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 769007,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 641014,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784527,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686514,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 283105,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 401106,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843337,
        "city": "Sheohar",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 174314,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533444,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 584121,
        "city": "Kushtagi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 460225,
        "city": "Athner",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 678632,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 246425,
        "city": "Rudraprayag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 688559,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 624205,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413405,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 401407,
        "city": "Palghar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416310,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 203390,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 752110,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643218,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700046,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 396155,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 825412,
        "city": "Rajdhanwar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 333032,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450341,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 577588,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600079,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486008,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 591106,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756126,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504303,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524124,
        "city": "Sullurupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756027,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 626101,
        "city": "Aruppukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 223103,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533446,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 587112,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755043,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 612503,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734008,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521183,
        "city": "Nandigama",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335801,
        "city": "Pilibanga",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413113,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 271606,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 141124,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 811303,
        "city": "Chakai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577303,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176089,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 311604,
        "city": "Mandalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721668,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735224,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623501,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 814110,
        "city": "Dumka",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534134,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 607301,
        "city": "Cuddalore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444812,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413228,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 781112,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 143003,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 737106,
        "city": "Ranipool",
        "state": "Sikkim",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 670661,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382481,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 416416,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 742161,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680546,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571107,
        "city": "Kushalnagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205303,
        "city": "Kishni",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382640,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 766025,
        "city": "Jaipatna",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 614102,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689591,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721653,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690566,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713519,
        "city": "Nabadwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403720,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 333704,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 311024,
        "city": "Raila",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 193503,
        "city": "Bandipora",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413204,
        "city": "Jamkhed",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 455225,
        "city": "Chapda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 783101,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 831021,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 613307,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440024,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521138,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 520011,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614715,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562112,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560092,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 515261,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411030,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 630106,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 362130,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 762103,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 185152,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691581,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 786002,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 784165,
        "city": "Narayanpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 686515,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400087,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 211009,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500056,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591173,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 508285,
        "city": "Bhuvanagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691517,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 497448,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 629810,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382740,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 532591,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628720,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 380038,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 570030,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413701,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743456,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 641106,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 363760,
        "city": "Viramgam",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 635107,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431120,
        "city": "Soegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 390025,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 231225,
        "city": "Anpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271301,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583270,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683585,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 362257,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 415712,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 572127,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847236,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 331517,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582211,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799263,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 841443,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571603,
        "city": "Krishnarajngr",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384210,
        "city": "Becharaji",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 126102,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493448,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 370040,
        "city": "Bhuj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 635655,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496245,
        "city": "Kunkuri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 171208,
        "city": "Chirgaon-HP",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305004,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680581,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 303102,
        "city": "Viratnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583283,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 274309,
        "city": "Padrauna",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 835240,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441101,
        "city": "Savner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 534007,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678557,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 680513,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 284001,
        "city": "Jhansi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 422610,
        "city": "Akole",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 855117,
        "city": "Thakurganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506011,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 482001,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 506122,
        "city": "Narsampet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635115,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637205,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 462047,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 416120,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 364620,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 700156,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431503,
        "city": "Selu",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712307,
        "city": "Ilipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 842002,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 335512,
        "city": "Hanumangarh",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 209720,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382830,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 754295,
        "city": "Athagarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 828116,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 522211,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754227,
        "city": "Aul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713427,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 325208,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 445215,
        "city": "Pusad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 629902,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 611103,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323615,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396211,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 232107,
        "city": "Chandauli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480224,
        "city": "Amarwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 782142,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 503125,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534206,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622305,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321411,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 211018,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600097,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 517277,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713433,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533305,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625021,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721154,
        "city": "Kolaghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 611003,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413322,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 228125,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683102,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 802134,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380053,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 173023,
        "city": "Haripurdhar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802162,
        "city": "Jagdishpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 630602,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160059,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 497001,
        "city": "Ambikapur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 712306,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 832402,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581319,
        "city": "Ankola",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627764,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 603305,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413504,
        "city": "Bhoom",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 531077,
        "city": "Paderu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673523,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523212,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734219,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571236,
        "city": "Shanivarasanthe",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321403,
        "city": "Rupbas",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 518005,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 623522,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751026,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 782127,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 831006,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332029,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441204,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 326502,
        "city": "Bhawani Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441904,
        "city": "Bhandara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 245412,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732125,
        "city": "Chanchal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 475675,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 144026,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388570,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 441002,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721507,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 192302,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 177202,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131029,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 758086,
        "city": "Kendujhar",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 852214,
        "city": "Triveninganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733143,
        "city": "Itahar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 126125,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382445,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 686022,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 204208,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 431138,
        "city": "Paithan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415804,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 283113,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 263154,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843129,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 480108,
        "city": "Sausar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 190009,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 751022,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518176,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643004,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423501,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416436,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416529,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 224238,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 444901,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 440037,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 400065,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110001,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 505188,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683546,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 609801,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 323803,
        "city": "Lakheri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841313,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 732101,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 396185,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 620006,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625022,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 384320,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416435,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 790002,
        "city": "Kalaktang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 620017,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721459,
        "city": "Dantan",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 272270,
        "city": "Menhdawal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741301,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175137,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 805109,
        "city": "Rajgir",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571448,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364365,
        "city": "Amreli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 481222,
        "city": "Balaghat",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 613207,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143026,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305002,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 396135,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141111,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 766023,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 362540,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 392130,
        "city": "Dahej",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 690571,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 176314,
        "city": "Chamba",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517586,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560053,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 768119,
        "city": "Deogarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 185101,
        "city": "Poonch",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500178,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486220,
        "city": "Sohagi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673603,
        "city": "Areacode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 784528,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 206121,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 207245,
        "city": "Ganjdundwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 441210,
        "city": "Umred",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441115,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 313330,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562157,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 505450,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535161,
        "city": "Seetharampram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151201,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785676,
        "city": "Moranhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 415713,
        "city": "Dapoli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 241124,
        "city": "Shahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230306,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695543,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 311408,
        "city": "Shahpura",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110105,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 613703,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415214,
        "city": "Mandangad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 441104,
        "city": "Ramtek",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 852110,
        "city": "Supaul",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152024,
        "city": "Jalalabad",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 737132,
        "city": "Rangpo",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 517193,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691022,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 533214,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342011,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 243634,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 465697,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695609,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303122,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 575005,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 360035,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 262726,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784117,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 422112,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 495007,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 462017,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 534426,
        "city": "Dwaraka Tirumala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515835,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711225,
        "city": "Khanakul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 682503,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636106,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 392180,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 323026,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571441,
        "city": "Kollegala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110034,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 721135,
        "city": "Kharagpur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 261401,
        "city": "Mishrikh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 793016,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 323305,
        "city": "Rawatbhata",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 483770,
        "city": "Barhi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 450052,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 534326,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230133,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271832,
        "city": "Bhinga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735303,
        "city": "Mathabhanga",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382321,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 629202,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 383001,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 184210,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673006,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577538,
        "city": "Challakere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306603,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151209,
        "city": "Kotkapura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 284202,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700022,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 741501,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382430,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 577550,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 611001,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573213,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500051,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621002,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623802,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 326034,
        "city": "Bhawani Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 424107,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712124,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731246,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 143202,
        "city": "Beas",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 343039,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 590012,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 172021,
        "city": "Nankhari",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 470441,
        "city": "Barodia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 444719,
        "city": "Dharni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 788155,
        "city": "Hailakandi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 516433,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182147,
        "city": "Doda",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444302,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 844141,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 795122,
        "city": "Kangpokpi",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 641002,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783372,
        "city": "Kokrajhar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 175003,
        "city": "Mandi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 411056,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 580112,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110112,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 413728,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 201103,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 490011,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 769042,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624108,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627133,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 848239,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689532,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 814102,
        "city": "Poriyahat",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110044,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500077,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 515842,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673125,
        "city": "Sultan Bathery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 602105,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 482009,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 689647,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 792122,
        "city": "Kharsang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 457119,
        "city": "Alote",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 190004,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 274205,
        "city": "Barhaj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572123,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782411,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 481672,
        "city": "ShahpuraMP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 271805,
        "city": "Ikauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 758015,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177105,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802207,
        "city": "Piro",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 591136,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638315,
        "city": "Anthiyour",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160026,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 473446,
        "city": "Chanderi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 835244,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110099,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 387100,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 829118,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 272128,
        "city": "Itava",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741137,
        "city": "Nakashipara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110511,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 783360,
        "city": "Gossaigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 500039,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413202,
        "city": "Karmala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 731245,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 334004,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627956,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 613601,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761105,
        "city": "Polasara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 136020,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176207,
        "city": "Sihunta",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 451660,
        "city": "Thikri",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 207402,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 639004,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755007,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 851129,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 497333,
        "city": "Premnagar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 753012,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 412408,
        "city": "Ambegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 470226,
        "city": "Jhunku",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 222204,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 251305,
        "city": "Budhana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785664,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 135021,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442701,
        "city": "Chandrapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 143004,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 442005,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303302,
        "city": "Bassi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110082,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 741245,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509002,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 795146,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 335805,
        "city": "Pilibanga",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 762019,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 125011,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 180019,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 393155,
        "city": "Rajpipla",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 844116,
        "city": "Hajipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614403,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517257,
        "city": "Punganur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580004,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 481883,
        "city": "Bajag",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 688011,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 425516,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721606,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 610210,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500081,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 152130,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415615,
        "city": "Ganpatipule",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 458226,
        "city": "Ratangarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 506004,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 635804,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 394160,
        "city": "Mandvi (Surat)",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 465447,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 678004,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 737139,
        "city": "Ravangla",
        "state": "Sikkim",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 690554,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 464111,
        "city": "Berasia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 784151,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 764056,
        "city": "Jeypore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 798621,
        "city": "Mon",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523254,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 782470,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 312605,
        "city": "Pratapgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521148,
        "city": "Gudivada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 263139,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 802136,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380041,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 221110,
        "city": "Mughal Sarai",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 679306,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 759019,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 636401,
        "city": "Mettur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500080,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 828403,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 685512,
        "city": "Kumily",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 521129,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 145002,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591225,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758045,
        "city": "Karanjia",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444507,
        "city": "Washim",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 759020,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 481445,
        "city": "Katangi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 571445,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843102,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523329,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227816,
        "city": "Jagdishpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 509325,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803116,
        "city": "Rajgir",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 346003,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691576,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 626142,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682504,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 626104,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 622002,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736146,
        "city": "Mathabhanga",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734215,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689590,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 686548,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 208006,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535260,
        "city": "Gajapatinagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244224,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 637102,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533341,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 228411,
        "city": "Lal Gopalganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682025,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 344028,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 753011,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743744,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 132104,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 174304,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 126114,
        "city": "Jind",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242127,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 622105,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534452,
        "city": "Dwaraka Tirumala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627601,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415413,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 752017,
        "city": "Nirakarpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713379,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 814149,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 461116,
        "city": "Hoshangabad",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 392011,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 210123,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274208,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415722,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673017,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 690561,
        "city": "Kayamkulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 741507,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 123507,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560016,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636451,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625120,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534122,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574218,
        "city": "Sullia",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641405,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843121,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523367,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391740,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 229416,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532284,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 301708,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431114,
        "city": "Sillod",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 614803,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110106,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 571429,
        "city": "Maddur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 794109,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 828135,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 602001,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 516004,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571802,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425426,
        "city": "Navapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 205268,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415524,
        "city": "Koregaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 768217,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581306,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686721,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742152,
        "city": "Murshidabad",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305801,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515731,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841234,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522007,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 360550,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 384345,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 422620,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 228151,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700005,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 700049,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 388430,
        "city": "Khambhat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 600020,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143527,
        "city": "Sri Hargobindpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442918,
        "city": "Gondpipri",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 387305,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 335524,
        "city": "Rawatsar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 475112,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 788738,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "Zone": "NE"
       },
       {
        "pincode": 132115,
        "city": "Samalkha",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515101,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 211003,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847233,
        "city": "Benipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 723162,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523185,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 190019,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 621110,
        "city": "Chinnasalem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 851122,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 532410,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583214,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342013,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450112,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 590005,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143022,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 683594,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523111,
        "city": "Kanigiri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 466001,
        "city": "Sehore",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 212405,
        "city": "Phulpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143601,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754240,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 360004,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 343044,
        "city": "Sanchore",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 209727,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 226016,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759027,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845402,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627105,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495004,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 691547,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 854327,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 604405,
        "city": "Vandavasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 590016,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303118,
        "city": "Jaipur",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 333503,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 842007,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 570027,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 683572,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686616,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 522660,
        "city": "Vinukonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629165,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 587204,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416123,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628618,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203399,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 246127,
        "city": "Kotdwara",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517502,
        "city": "Tirupati",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 829107,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625707,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 503185,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 443405,
        "city": "Nandura",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 424102,
        "city": "Pachora",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 175036,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 624218,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695104,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 814166,
        "city": "Madhupur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 758022,
        "city": "Anandapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 451228,
        "city": "Thikri",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 580029,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781026,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 227131,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500089,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636813,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642134,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721659,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444126,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 783346,
        "city": "Gossaigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 411024,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 785633,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 181221,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 482051,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 443402,
        "city": "Jalgaon Jamod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 804434,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 424204,
        "city": "Soegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 243601,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 637212,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 261207,
        "city": "Laharpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 821307,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 796111,
        "city": "Darlawn",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 305011,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 342601,
        "city": "Piparcity",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847109,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 735306,
        "city": "Mekhliganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 135106,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 140111,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 829138,
        "city": "Phusro",
        "state": "Jharkhand",
        "Zone": "E1"
       },
       {
        "pincode": 458441,
        "city": "Neemuch",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 802101,
        "city": "Buxar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 764072,
        "city": "Pappadahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577117,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 480109,
        "city": "Sausar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431211,
        "city": "Partur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754291,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 732149,
        "city": "Malda",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 641023,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 755006,
        "city": "Mangalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766014,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262545,
        "city": "Dharchula",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 370511,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 229130,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 603405,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686604,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 735302,
        "city": "Mainaguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 463111,
        "city": "Berasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 571146,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581102,
        "city": "Hangal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741189,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 402402,
        "city": "Shriwardhan",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 524137,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400709,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 524407,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624004,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 180004,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628303,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440006,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 686001,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571610,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 121009,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 486002,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 394190,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 176204,
        "city": "Palion",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 148027,
        "city": "Patran",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341504,
        "city": "Degana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 227807,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627051,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 146023,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 828302,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 643240,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 182161,
        "city": "Chenani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632002,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344502,
        "city": "Ramsar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 845416,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 413211,
        "city": "Indapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576229,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614612,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175031,
        "city": "Nihri",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 277402,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632003,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521344,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722204,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 712104,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140701,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741161,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 415609,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 495222,
        "city": "Bilaspur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 678722,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 688501,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 643213,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276123,
        "city": "Kerakat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614902,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626513,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624102,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 132040,
        "city": "Panipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 126112,
        "city": "Safidon",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224147,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 793012,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 262723,
        "city": "Dhaurahara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 605201,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334601,
        "city": "Lunkaransar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305819,
        "city": "Makrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 143416,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 532408,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756169,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 723215,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 461772,
        "city": "Pipariya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 470113,
        "city": "Bina",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 626109,
        "city": "Virudhunagar",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413722,
        "city": "Rahuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500103,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 821306,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841441,
        "city": "Bhore",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 676307,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 412402,
        "city": "Rajgurunagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 244001,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 388530,
        "city": "Vatadara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 416218,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 635130,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503101,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591231,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152102,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 133102,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686546,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689544,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 484664,
        "city": "Ranchha",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 444204,
        "city": "Jalgaon Jamod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 343043,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333012,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 517642,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606905,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 847403,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431701,
        "city": "Waranga Phata",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 723146,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 582207,
        "city": "Navalgund",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524401,
        "city": "Tada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 560091,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 805122,
        "city": "Sirdala",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 454116,
        "city": "Sardarpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 501511,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 495330,
        "city": "Mungeli",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 785702,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 466125,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 471301,
        "city": "Malhera",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 522256,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415619,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 560047,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 332301,
        "city": "Fatehpur-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691538,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 444004,
        "city": "Akola",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 271502,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341302,
        "city": "Khatu Khurd",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 507301,
        "city": "Aswaraopeta",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176092,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491227,
        "city": "Dhamtari",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 848516,
        "city": "Tajpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 827013,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 590015,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229204,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587118,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225205,
        "city": "Ramnagar-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 601202,
        "city": "Gummudipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241302,
        "city": "Madhoganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679554,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 533343,
        "city": "Bikkavolu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 690544,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713160,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700136,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122100,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 521225,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 628206,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 453441,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 176401,
        "city": "Surajpur jhikla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 691307,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 530001,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334302,
        "city": "Kolayat",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 752035,
        "city": "Balugaon",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 491336,
        "city": "Kawardha",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 518021,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781122,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 460660,
        "city": "Multai",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 144221,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 276304,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 631211,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382418,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700043,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 148004,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403502,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757104,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 333030,
        "city": "Pilani",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768150,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624614,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 342902,
        "city": "Gotan",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160011,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500012,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 614714,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695524,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 465113,
        "city": "Polay Kalan",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 462014,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 582206,
        "city": "Kerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686695,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 202004,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 136027,
        "city": "Kaithal",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210427,
        "city": "Mahoba",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627109,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 209864,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 766018,
        "city": "Jaipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394440,
        "city": "Mandvi (Surat)",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 334305,
        "city": "Bajju",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781025,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 679104,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 786191,
        "city": "Duliajan",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 612703,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612702,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221508,
        "city": "Saidabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641655,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 341522,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503114,
        "city": "Padmajiwadi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591241,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685618,
        "city": "Kuthumkal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572115,
        "city": "Sira",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110512,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 762029,
        "city": "Phulbani",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 521211,
        "city": "Nuzvid",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 230406,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400057,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 580005,
        "city": "Dharwad",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576247,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785626,
        "city": "Dergaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 637202,
        "city": "Tiruchengode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756037,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680687,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 224225,
        "city": "Kumarganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 759014,
        "city": "Bhuban",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 344032,
        "city": "Balotra",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 506391,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577435,
        "city": "Siddapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202525,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500100,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444807,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 600047,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 144028,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508218,
        "city": "Nereducharla",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680695,
        "city": "Irinjalakuda",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 784170,
        "city": "Behali",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 577126,
        "city": "Koppa-ka",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 792121,
        "city": "Jairampur",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 415540,
        "city": "Mhaswad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 781029,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 209125,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 160055,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 248008,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581331,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307514,
        "city": "Reodar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638672,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 783129,
        "city": "Lakhipur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 571430,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636308,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416315,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415011,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721603,
        "city": "Tamluk",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 132102,
        "city": "Samalkha",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 360050,
        "city": "Jasdan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 602107,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 172104,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627415,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743383,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700080,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 590003,
        "city": "Belgaum",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209210,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 671312,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382029,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700125,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711113,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 563161,
        "city": "Srinivaspur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243407,
        "city": "Nawabganj-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695304,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 395002,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 472118,
        "city": "Jatara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 400043,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 700143,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 612303,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244102,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 465441,
        "city": "Agar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 642202,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721401,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 326529,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621011,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312021,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 761200,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 441207,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 134007,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 848110,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 230304,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 805103,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 509407,
        "city": "Narayanpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686025,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 752021,
        "city": "Nirakarpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635001,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641305,
        "city": "Mettupalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403524,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 452012,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 333514,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177107,
        "city": "Dehra Gopipur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682017,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600064,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 212645,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 285129,
        "city": "Jalaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533247,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676542,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523368,
        "city": "Giddalur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722156,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412220,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 604601,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416511,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 202139,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 123401,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689106,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 440003,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 636121,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 627116,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 281301,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814141,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 508214,
        "city": "Suryapet",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 229308,
        "city": "Bacchawaran",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784163,
        "city": "Itanagar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 415539,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 515501,
        "city": "Mudigubba",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303806,
        "city": "Chomu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755022,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 520016,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626128,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391774,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 560042,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 171012,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 843352,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524239,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 628207,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 788003,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 272207,
        "city": "Naugarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176022,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673317,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 192304,
        "city": "Pulwama",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700105,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 457990,
        "city": "Alirajpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 516289,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768120,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 563152,
        "city": "Chintamani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 796571,
        "city": "Hnahthial",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 524127,
        "city": "Naidupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844119,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 303303,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761009,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621308,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441111,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 642106,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680631,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 177206,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 402101,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502269,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 393236,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 321030,
        "city": "Bayana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 814113,
        "city": "Deoghar",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 221103,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785625,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 491004,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 757086,
        "city": "Bangriposi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 535102,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754286,
        "city": "Salipur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 229401,
        "city": "Unchahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 624707,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741164,
        "city": "Krishnanagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 284206,
        "city": "Chirgaon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 576124,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686041,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 263145,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689667,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416501,
        "city": "Gadhinglaj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 177119,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673101,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631202,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 227812,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263401,
        "city": "Kashipur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 570018,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 244103,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 847224,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 741257,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 813112,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523346,
        "city": "CumbumAP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534001,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581145,
        "city": "Ranebennur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 184201,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 341024,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 384440,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 242306,
        "city": "Shahjahanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 765002,
        "city": "Raygada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 531085,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 445105,
        "city": "Arni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 515303,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680319,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 628716,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688502,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581402,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241125,
        "city": "Shahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680609,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 576226,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829111,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609204,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 766017,
        "city": "Junagarh-OR",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 754113,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 410405,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638460,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 307022,
        "city": "Pindwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362250,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 690108,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 602109,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 230124,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 242301,
        "city": "Tilhar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244104,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736172,
        "city": "Mathabhanga",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 146102,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 824124,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700099,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144402,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641030,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783339,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 411046,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 581107,
        "city": "Gadag",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 440023,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 222135,
        "city": "Mungra Badshahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387420,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 722110,
        "city": "Sonamukhi",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799288,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 800023,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 734203,
        "city": "Kurseong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175011,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361230,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 171009,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209861,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688010,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689613,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 629502,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 632007,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 736169,
        "city": "Sitai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 507138,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683564,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 576210,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620001,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685601,
        "city": "Iddukki",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 394326,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 832109,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 416517,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 462016,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 613005,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151510,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271318,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 732147,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626106,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 587120,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713121,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 332044,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 460220,
        "city": "Athner",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 790106,
        "city": "Lumla",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 522324,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758036,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 307801,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176120,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 491993,
        "city": "Berla",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 587311,
        "city": "Jamkhandi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614807,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670644,
        "city": "Mananthavadi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 805112,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515456,
        "city": "Guntakal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144626,
        "city": "Sultanpur Lodhi",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 676522,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 326022,
        "city": "Jhalawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 384415,
        "city": "Becharaji",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 627862,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313003,
        "city": "Udaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632507,
        "city": "Ranipet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422502,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 360013,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 400025,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 364130,
        "city": "Mahuva",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 581335,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 345001,
        "city": "Jaisalmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 302040,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229120,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755012,
        "city": "Binjharpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500605,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 785647,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 227208,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387550,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 327604,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396223,
        "city": "Daman",
        "state": "Daman & Diu",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 583228,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503187,
        "city": "Banswada",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416134,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 794002,
        "city": "Tura",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 784175,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 629195,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 244303,
        "city": "Sambhal",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 576234,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678687,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 607201,
        "city": "Memalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382043,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 224216,
        "city": "Jalalpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533255,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491332,
        "city": "Berla",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 614706,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577181,
        "city": "Kalasa",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 127032,
        "city": "Bhiwani",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 562129,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 570028,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 629701,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841406,
        "city": "Malmaliya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854330,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 343029,
        "city": "Bhinmal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497442,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 843361,
        "city": "Sonbarsa",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 711313,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 342001,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585429,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754211,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 470021,
        "city": "Sagar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 396301,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 231212,
        "city": "Dudhinagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144417,
        "city": "Nawanshahr",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243127,
        "ODA": "TRUE",
        "Zone": "#N\/A"
       },
       {
        "pincode": 521456,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 151204,
        "city": "Kotkapura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621133,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413248,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 301023,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441225,
        "city": "Saoli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 522508,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 841417,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 518390,
        "city": "Gooty",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 160102,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 613503,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 141027,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764069,
        "city": "Nabarangapur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 140308,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700107,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 460110,
        "city": "Athner",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 523116,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629158,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505153,
        "city": "Peddapalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380330,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 695121,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 394540,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 388716,
        "city": "Godhra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 208039,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600086,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754203,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413251,
        "city": "Karmala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 847222,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 383246,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 411054,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 576111,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786126,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 752002,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758047,
        "city": "Champua",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 765016,
        "city": "Kalyansingpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571418,
        "city": "Nagamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680521,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 841410,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 454446,
        "city": "Manawar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 122109,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 562159,
        "city": "Ramanagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591314,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382308,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 416626,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 829104,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721448,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781309,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 827016,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 492010,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 484446,
        "city": "Kotma",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 416810,
        "city": "Talere",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110102,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 689509,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416211,
        "city": "Rashiwade Bk",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 415311,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 246765,
        "city": "Nagina",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 509409,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362276,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 133002,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641670,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 800006,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 190008,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 671313,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 507114,
        "city": "Kukanoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 752116,
        "city": "Konark",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 311401,
        "city": "Raila",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 755044,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421605,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 193101,
        "city": "Baramulla",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515601,
        "city": "Gorantla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741506,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 160003,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 756047,
        "city": "Soro",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 345021,
        "city": "Pokhran",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 688525,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 495680,
        "city": "Korba",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 246727,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 450221,
        "city": "Nepanagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 587319,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144801,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735222,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799203,
        "city": "Teliamura",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 212620,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497553,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695526,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 312613,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125044,
        "city": "Hisar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 743134,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 758035,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621114,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416417,
        "city": "AshtaSangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 636109,
        "city": "Attur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670741,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581440,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534320,
        "city": "Tanuku",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493663,
        "city": "Abhanpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 689502,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 342009,
        "city": "Jodhpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 767024,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 691015,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 700072,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 384355,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 362275,
        "city": "Veraval",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 520004,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 736166,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583101,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 173026,
        "city": "Nahan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 851130,
        "city": "Begusarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 263646,
        "city": "Ranikhet",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 230204,
        "city": "Kunda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131040,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 364210,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 227120,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 743504,
        "city": "Fatepur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 626189,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 361335,
        "city": "Dwarka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 828103,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804422,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421311,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 206001,
        "city": "Etawah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 848208,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 503246,
        "city": "Navipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572224,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679323,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721158,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444107,
        "city": "Murtizapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 673583,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 504219,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754111,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 422210,
        "city": "Sinnar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 841434,
        "city": "Siwan",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713424,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 440016,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 362620,
        "city": "Manavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 614808,
        "city": "Pushpavanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 388520,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 735251,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 608801,
        "city": "Cuddalore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591319,
        "city": "Gokak",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 462040,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 135001,
        "city": "Yamuna Nagar",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 477449,
        "city": "Lahar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 202413,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522503,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382865,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271214,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 755017,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 515465,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403115,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682308,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332706,
        "city": "Khandela",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626206,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732208,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734006,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 382775,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 142003,
        "city": "Moga",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 425513,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 144631,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516104,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682006,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 577145,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 461222,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 695573,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 761207,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721160,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695146,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 410201,
        "city": "Karjat",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 493773,
        "city": "Dhamtari",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 673104,
        "city": "Vadakara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713166,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124506,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "TRUE",
        "Zone": "N1"
       },
       {
        "pincode": 721515,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382610,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 785622,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 123502,
        "city": "Rewari",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 344044,
        "city": "Siwana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 344012,
        "city": "Barmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 576114,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691543,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 636306,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411055,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 689513,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621106,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225303,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700070,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 403108,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 682311,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174321,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682027,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 506367,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246746,
        "city": "Kanth",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847404,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781365,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 452005,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 444806,
        "city": "Paratwada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691536,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 532263,
        "city": "Sompeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457441,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 391450,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 535124,
        "city": "Rajam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743166,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631001,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401602,
        "city": "Dahanu",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695563,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425522,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 382012,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 263142,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 261135,
        "city": "Laharpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679594,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686574,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 423213,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 249404,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841233,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 834006,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695019,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 454775,
        "city": "Pithampur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 110039,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 508284,
        "city": "Choutuppal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 424206,
        "city": "Jamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 571253,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412403,
        "city": "Rajgurunagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 471501,
        "city": "Chhatarpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 735121,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 524303,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 828308,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 534265,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630558,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670663,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 781040,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 450343,
        "city": "Khandwa",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 232111,
        "city": "ChakiaUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177210,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530037,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 123413,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 574221,
        "city": "Kadaba",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413601,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 227115,
        "city": "Malihabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600008,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 143529,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582113,
        "city": "Hoovina Hadagali",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 831005,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 624208,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 324005,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144204,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431133,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 847214,
        "city": "Madhubani",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624009,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 586111,
        "city": "Indi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743212,
        "city": "Barrackpore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534201,
        "city": "Bhimavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623704,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313803,
        "city": "Kherwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614621,
        "city": "Manamelkudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689585,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425432,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 844114,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577246,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535579,
        "city": "Salur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226203,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531113,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 140105,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 243302,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673582,
        "city": "Areacode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 532211,
        "city": "Tekkali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 751016,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673652,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 614404,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380003,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 384175,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 141204,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835211,
        "city": "Kolebira",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 611102,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517424,
        "city": "Venkatagirikota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 401206,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 414208,
        "city": "Ashti",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 783123,
        "city": "Boko",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 621303,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302017,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 761206,
        "city": "Paralakhemundi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 843332,
        "city": "Sonbarsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 175013,
        "city": "Joginder Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732203,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 804418,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 505404,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505211,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396002,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 176110,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 530013,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 757075,
        "city": "Udala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 767005,
        "city": "Balangir",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 142026,
        "city": "Jagraon",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382465,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 324002,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 313341,
        "city": "Badnaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 764086,
        "city": "Kalimela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 795126,
        "city": "Moirang",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 604307,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682301,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 301032,
        "city": "Alwar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686608,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 173225,
        "city": "Kunihar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635106,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700134,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627356,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 210208,
        "city": "Shankargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521111,
        "city": "Nuzvid",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671311,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 638108,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673641,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 605014,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486450,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 413613,
        "city": "Omerga",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 142021,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 305921,
        "city": "Badnaur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 361315,
        "city": "Bhatiya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 222149,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110069,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 516502,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723145,
        "city": "Para",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 689656,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 755016,
        "city": "Binjharpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577168,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721625,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 145003,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 641017,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 221404,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 505214,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768214,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 248202,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 480002,
        "city": "Chhindwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 476224,
        "city": "Sabalgarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 624714,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365001,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 683503,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 794102,
        "city": "Baghmara",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 425501,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 413001,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 722171,
        "city": "Khatra",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 751013,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 160033,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628906,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700097,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609111,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224152,
        "city": "Akbarpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502293,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683108,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 770001,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766031,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 711302,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 848116,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743128,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 609105,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 483442,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 673633,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 637215,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574146,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852111,
        "city": "Simrahi Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 733216,
        "city": "Raiganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690567,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 485772,
        "city": "Maihar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431006,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 621316,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247670,
        "city": "Roorkee",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416408,
        "city": "Tasgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 504101,
        "city": "Bhainsa",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 241503,
        "city": "Miyanganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400047,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 506314,
        "city": "Rayaparthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629404,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 445202,
        "city": "Digras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 723128,
        "city": "Manbazar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 174311,
        "city": "Bhota",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 343024,
        "city": "Sirohi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321608,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 835221,
        "city": "Khunti",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 503110,
        "city": "Kamareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415643,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144203,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400081,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 636902,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 334803,
        "city": "Nokha",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591234,
        "city": "Ugar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416322,
        "city": "Islampur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 370435,
        "city": "Mandvi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 801301,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 285251,
        "city": "Konch",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 413703,
        "city": "Shrigonda",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 614209,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736158,
        "city": "Sitai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400054,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627151,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761112,
        "city": "Asika",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 814133,
        "city": "Godda",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370240,
        "city": "Gandhidham",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 743165,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 743247,
        "city": "Swarupnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847308,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441606,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 222139,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841316,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 600120,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621001,
        "city": "Thuraiyur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600046,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577423,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250005,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689573,
        "city": "Haripad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 313701,
        "city": "Jhadol",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 768017,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 580031,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577444,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 233306,
        "city": "Aunrihar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560015,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 754202,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 784525,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 678101,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 152025,
        "city": "Muktsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 768228,
        "city": "Bamra",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673648,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 754200,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495661,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 642110,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 380015,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 305811,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413410,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394630,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 208017,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522413,
        "city": "Piduguralla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276129,
        "city": "Dullahpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 205263,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577433,
        "city": "Shiralakoppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416419,
        "city": "Kavathe Mahankal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 507101,
        "city": "Kothagudem",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441701,
        "city": "Brahmapuri",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 785113,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 483503,
        "city": "Katni",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 532558,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171011,
        "city": "Shimla",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151001,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 785016,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 690560,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 505527,
        "city": "Karim Nagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500024,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 382729,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691548,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 333024,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680313,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 518512,
        "city": "Nandyal",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 797116,
        "city": "Dimapur",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 390004,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 756048,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781321,
        "city": "Kalgachia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 786102,
        "city": "Chabua",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 686040,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 248005,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425117,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757055,
        "city": "Morada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627433,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742103,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 249137,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735223,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411005,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 627202,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226006,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 686008,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 614901,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 760004,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 341518,
        "city": "Merta",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 736201,
        "city": "Kumargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152004,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683579,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 491228,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 680670,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 412404,
        "city": "Rajgurunagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 676517,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689520,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382850,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 712308,
        "city": "Jamalpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370655,
        "city": "Naliya",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 576218,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400206,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577401,
        "city": "Sagara",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225003,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742170,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 384011,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 462030,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 520040,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 802164,
        "city": "Sandesh",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 322029,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521163,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 737137,
        "city": "Rangpo",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 756100,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 482021,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 415116,
        "city": "Rahimatpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 642205,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 504292,
        "city": "Kaghaznagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786012,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 680512,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 228155,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 301018,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230401,
        "city": "Pratapgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110074,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 502281,
        "city": "Pregnapur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 131022,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 306116,
        "city": "Falna",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 410509,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 251309,
        "city": "Budhana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 582208,
        "city": "Navalgund",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 826002,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625521,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 643101,
        "city": "Coonoor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176085,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695033,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 623528,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 631203,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676303,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680669,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 487555,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 394655,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 631402,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680516,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 232329,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600070,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 414006,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 502329,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 424308,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 609115,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 642140,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 385565,
        "city": "Tharad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 222002,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712616,
        "city": "Arambag",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 485113,
        "city": "Satna",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 736121,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 273201,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225203,
        "city": "Barabanki",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361009,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 751019,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332702,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 440032,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 784148,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 641033,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 305204,
        "city": "Ras",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587313,
        "city": "Mudhol",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 414204,
        "city": "Jamkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 721260,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 826003,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 152005,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412107,
        "city": "Pirangut",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 682020,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 641050,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629183,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 784189,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 627952,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 261201,
        "city": "Biswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 575027,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591229,
        "city": "Nipani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576257,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 794004,
        "city": "Resubelpara",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 603314,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700083,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176021,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742134,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 121001,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400046,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638505,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250007,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 387411,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144629,
        "city": "Sultanpur Lodhi",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 676511,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 713304,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 676514,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 522314,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 799012,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 762107,
        "city": "M Rampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431525,
        "city": "Vasmat",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 575004,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 676525,
        "city": "Pandikkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 685590,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 474009,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 245207,
        "city": "Hapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 803307,
        "city": "Sheonar",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 192305,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 505474,
        "city": "Huzurabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767070,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847102,
        "city": "Benipatti",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 841424,
        "city": "Mashrakh",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 504110,
        "city": "Nirmal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382875,
        "city": "Vijapur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 767033,
        "city": "Titilagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585314,
        "city": "Aland",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678546,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 827010,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 394541,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 202415,
        "city": "Moradabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 800018,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 721147,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 421032,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577571,
        "city": "Chitradurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416138,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 425308,
        "city": "Bhusawal",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 473445,
        "city": "Mungaoli",
        "state": "Madhya Pradesh",
        "Zone": "C1"
       },
       {
        "pincode": 302033,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 209204,
        "city": "Kanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 273006,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262906,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627108,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533226,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400048,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 209863,
        "city": "Unnao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 334401,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 343045,
        "city": "Sanchore",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 480447,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 680583,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 571125,
        "city": "Hunsur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311022,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415711,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 835224,
        "city": "Simdega",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 788031,
        "city": "Udarbond",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 721670,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 700007,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 181131,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 396105,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 788166,
        "city": "Ramkrishna Nagar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 515762,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 410213,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 534427,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533401,
        "city": "Tuni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143520,
        "city": "Gurdaspur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673570,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 181142,
        "city": "Jakh",
        "state": "Jammu & Kashmir",
        "Zone": "N2"
       },
       {
        "pincode": 784004,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 691537,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 395005,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700146,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 695037,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 678762,
        "city": "Thachanattukara",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 732102,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 431520,
        "city": "Parli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 370425,
        "city": "Mundra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680523,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 800007,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 122022,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 209311,
        "city": "Akbarpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 462046,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 509208,
        "city": "Makthal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384121,
        "city": "Himmatnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 230145,
        "city": "Patti-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 212106,
        "city": "JariBazar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627658,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 389355,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 691577,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 736170,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 847411,
        "city": "Jhanjharpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534235,
        "city": "Kaikaluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415103,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 140602,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 176051,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 277405,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 147203,
        "city": "Mandi Gobindgarh",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 321634,
        "city": "Alwar",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 605005,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627753,
        "city": "Sankarankovil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322216,
        "city": "Nadoti",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574328,
        "city": "Panja",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700152,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 586216,
        "city": "Kolhar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486006,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 160054,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 225412,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 522412,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503201,
        "city": "Bodhan",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 125058,
        "city": "Fatehabad",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 125054,
        "city": "Sirsa",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621701,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629603,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695034,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 202001,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 523168,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143101,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 335064,
        "city": "Hanumangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 414501,
        "city": "Pathardi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 123024,
        "city": "Loharu",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516355,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 312617,
        "city": "Nimbahera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 795114,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 416705,
        "city": "Rajapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 628103,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 534268,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 181134,
        "city": "Jakh",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 685554,
        "city": "Kuthumkal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631201,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 225002,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209723,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 263642,
        "city": "Bageshwar",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680021,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413130,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 741176,
        "city": "Katwa",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 522304,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 731133,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 516215,
        "city": "Rayachoti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683577,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 321642,
        "city": "Kherli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 223222,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423502,
        "city": "Dindori-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 523180,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365490,
        "city": "Amreli",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 396175,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 401210,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 584120,
        "city": "Kowthal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 502109,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 671532,
        "city": "Parappa",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 382427,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 263129,
        "city": "Haldwani",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 751018,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 193401,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 392221,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 689107,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 602106,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 206248,
        "city": "Bidhuna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712404,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184204,
        "city": "Billawar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 734101,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 380054,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 524223,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713101,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 626126,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384421,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 456002,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 391111,
        "city": "Dabhoi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 281123,
        "city": "Mathura",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 481885,
        "city": "ShahpuraMP",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 713403,
        "city": "Panagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 833213,
        "city": "Noamundi",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110119,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 191111,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 209859,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614713,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670304,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631503,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585353,
        "city": "Humnabad",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 314034,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 642144,
        "city": "Pollachi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491771,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 685583,
        "city": "Koothattukulam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413801,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 160038,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 534281,
        "city": "Narsapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638113,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 332708,
        "city": "Khandela",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 560096,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 271855,
        "city": "Nanpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712202,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 232101,
        "city": "Mughal Sarai",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636301,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 607303,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 534176,
        "city": "Nallajerla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247342,
        "city": "Gangoh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734015,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 587201,
        "city": "Badami",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 686668,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 521260,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581342,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 829120,
        "city": "Patratu",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 691574,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 621101,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785018,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 380017,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 560069,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 407001,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 676541,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 361240,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 466120,
        "city": "Ashta",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 273404,
        "city": "Sikriganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636008,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 574144,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 328030,
        "city": "Dholpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678682,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 411052,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 534101,
        "city": "Tadepalligudem",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321021,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 605602,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721501,
        "city": "Ama Dubi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 262525,
        "city": "Lohaghat",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843147,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 226024,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 673325,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 333504,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 735234,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631027,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 624212,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 243403,
        "city": "Aonla",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412409,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 800024,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 442105,
        "city": "Wardha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 802208,
        "city": "Arwal",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 731221,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626203,
        "city": "Sattur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 560031,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 630556,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638454,
        "city": "Sathyamangalam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673310,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515413,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670563,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 444711,
        "city": "Dhamangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 148000,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 635112,
        "city": "Krishnagiri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491001,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 600118,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 695585,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 799102,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 424312,
        "city": "Kannad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 562105,
        "city": "Chikkaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581140,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591281,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574197,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 204210,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 635701,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 637404,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743356,
        "city": "Kakdwip",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523253,
        "city": "Chimakurthy",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670501,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 175130,
        "city": "Patlikuhal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 175024,
        "city": "Sarkaghat",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 184147,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636455,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600030,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 125033,
        "city": "Hansi",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600055,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 833106,
        "city": "Manoharpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 671543,
        "city": "Poinachi",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 562127,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416519,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 813210,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 635651,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122110,
        "city": "Bhiwadi",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 425423,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 431212,
        "city": "Georai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 425412,
        "city": "Nandurbar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 691504,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 735227,
        "city": "Alipurduar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396475,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 122000,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 496001,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 272189,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 756059,
        "city": "Agarpada",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 250515,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625203,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577221,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 682501,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416550,
        "city": "Sawantwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 781027,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 824233,
        "city": "Khizasarai",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 560022,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 735133,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 848213,
        "city": "Singhia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 395009,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 786614,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 623535,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 473880,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 848507,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 191131,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262121,
        "city": "Sitarganj",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784027,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 333304,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 382019,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 176109,
        "city": "Sujanpur",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 171220,
        "city": "Theog",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 496440,
        "city": "Raigarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 176037,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 845420,
        "city": "Chakia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782448,
        "city": "Lanka",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 843319,
        "city": "Pupri",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 121008,
        "city": "Faridabad",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500032,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673612,
        "city": "Balussery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 332709,
        "city": "Khandela",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 182143,
        "city": "Chenani",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 625522,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226002,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 841447,
        "city": "Mashrakh",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 533449,
        "city": "Kathipudi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 422611,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 679585,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 614625,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700041,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 144207,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250619,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628218,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 792123,
        "city": "Jairampur",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 461441,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 422222,
        "city": "Nashik",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 341533,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 584202,
        "city": "Raichur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403708,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 190005,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 458339,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 841225,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177601,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 421402,
        "city": "Murbad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 757027,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 414307,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 735132,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517152,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500108,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 413605,
        "city": "Naldurg",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 796005,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 641671,
        "city": "Palladam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 210302,
        "city": "Hamirpur-UP",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 413315,
        "city": "Pandharpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 607104,
        "city": "Neyveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396590,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 194103,
        "city": "Kargil",
        "state": "Ladakh",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 332746,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503309,
        "city": "Degloor",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 722111,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721647,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 477660,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 362315,
        "city": "Jetpur",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 531145,
        "city": "Araku",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 679577,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 247452,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362245,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 800028,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781016,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 843142,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 732151,
        "city": "Malda",
        "state": "West Bengal",
        "Zone": "E1"
       },
       {
        "pincode": 765034,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606807,
        "city": "Chetpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743269,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 534267,
        "city": "Palakollu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 311404,
        "city": "Shahpura",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388260,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 387355,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 171211,
        "city": "Nerwa",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713360,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756026,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403802,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 532429,
        "city": "Narasannapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 450051,
        "city": "Ashapur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 630553,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695523,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 221311,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 503305,
        "city": "Degloor",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 497118,
        "city": "Rajpur-CH",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 673604,
        "city": "Areacode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 560026,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 250002,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 323802,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 335525,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577515,
        "city": "Hosadurga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335526,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 360150,
        "city": "Bhanvad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 124406,
        "city": "Rohtak",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 222001,
        "city": "Jaunpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508126,
        "city": "Bhuvanagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572228,
        "city": "Huliyar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754297,
        "city": "Athagarh",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 504220,
        "city": "Bellampalli",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 174011,
        "city": "Naina Devi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 700122,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 210001,
        "city": "Banda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 607207,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822114,
        "city": "Garhwa",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 624107,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313027,
        "city": "Salumbar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229212,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626115,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 226015,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743193,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 734431,
        "city": "Bagdogra",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 505403,
        "city": "Rudrangi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742305,
        "city": "Domkal",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 800012,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 302038,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 524314,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 364313,
        "city": "Dhandhuka",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 632316,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322249,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415608,
        "city": "Sangameshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 607404,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271821,
        "city": "Ikauna",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 502248,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691587,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 362565,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 431132,
        "city": "Buldhana",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 148033,
        "city": "Patran",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 361110,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 827001,
        "city": "Bokaro",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176048,
        "city": "Jahu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 834005,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 444710,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577431,
        "city": "Shikaripur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680697,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 127110,
        "city": "Charkhi Dadri",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 531030,
        "city": "Seetharampram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756023,
        "city": "Basta",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 262903,
        "city": "Nighasan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 494004,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 695315,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 274805,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783351,
        "city": "Bilasipara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 415105,
        "city": "Karad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 791101,
        "city": "Basar",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 689692,
        "city": "Pathanamthitta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 425119,
        "city": "Jalgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 805143,
        "city": "Nawada",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 711112,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 530015,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 364320,
        "city": "Sihor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 205145,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574122,
        "city": "Karkala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 274501,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 673529,
        "city": "Koyilandy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 756041,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766021,
        "city": "Bhawanipatna",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721132,
        "city": "Midnapore",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 787110,
        "city": "Silapathar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 517213,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 497773,
        "city": "Chirimiri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 518196,
        "city": "Betamcherla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 630208,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 248197,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 501106,
        "city": "Mominpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734316,
        "city": "Kalimpong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 495447,
        "city": "Korba",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 517194,
        "city": "Pileru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 451224,
        "city": "Maheshwar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 560029,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 362571,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 843314,
        "city": "Sitamarhi",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 826001,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 325007,
        "city": "Kota",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691321,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 535240,
        "city": "Pendurthi",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400078,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 695012,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 741246,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 445106,
        "city": "Arni",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 283203,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 331801,
        "city": "Shri Dungargarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508244,
        "city": "Chandur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 172024,
        "city": "Kumarsain",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221705,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 690531,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 387360,
        "city": "Nadiad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 247772,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403801,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 246194,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 802152,
        "city": "Jagdishpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 756162,
        "city": "Bhadrak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 625706,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 327007,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852109,
        "city": "Simrahi Bazar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 671221,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 247667,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 224141,
        "city": "Faizabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 334301,
        "city": "Kolayat",
        "state": "Rajasthan",
        "Zone": "N2"
       },
       {
        "pincode": 415306,
        "city": "Atapadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416215,
        "city": "Bambavade",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 576283,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 636453,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416009,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 382211,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678613,
        "city": "Akaloor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 560099,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524410,
        "city": "Gudur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522212,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 221715,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 508286,
        "city": "Alair",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 676304,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 799281,
        "city": "Kailashahar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 731136,
        "city": "Suri",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521106,
        "city": "Pedapadu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 583138,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 243632,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 586121,
        "city": "Kolhar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713333,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 335065,
        "city": "Sangaria",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754110,
        "city": "Balikuda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 110048,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 624705,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 848125,
        "city": "KalyanpurBR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 627807,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 207124,
        "city": "Kasganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785685,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 476134,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 229410,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244401,
        "city": "Thakurdwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686575,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 193303,
        "city": "Kupwara",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 227132,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 733126,
        "city": "Balurghat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821305,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394664,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 670678,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689113,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 767017,
        "city": "Sonepur-OR",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 181152,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 460551,
        "city": "Amla",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 678597,
        "city": "Karimba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 323023,
        "city": "Bundi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244412,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144531,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678611,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 735140,
        "city": "Chalsa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431207,
        "city": "Partur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581123,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517124,
        "city": "Pakala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 306701,
        "city": "Falna",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403511,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 450114,
        "city": "Mundi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 576230,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 506001,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 721168,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388051,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 413112,
        "city": "Akluj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 440001,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 832401,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854321,
        "city": "Araria",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 490006,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 485331,
        "city": "Majhgawa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 402115,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 623516,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 762102,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 622003,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 487334,
        "city": "Gadarwara",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 792104,
        "city": "Hayuliang",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 140104,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 622207,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 755040,
        "city": "Cuttack",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 201101,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 799143,
        "city": "Manu bazar",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 627112,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 365555,
        "city": "Rajula",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 736205,
        "city": "Kumargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 523260,
        "city": "Martur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 361012,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 392035,
        "city": "Jambusar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 573113,
        "city": "Ramanathapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786005,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 263135,
        "city": "Nainital",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 799269,
        "city": "Kumarghat",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 302008,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142032,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312023,
        "city": "Mandalgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678556,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 803301,
        "city": "Sheonar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 450551,
        "city": "Sanawad",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 207002,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 620008,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 700093,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 456662,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 825403,
        "city": "Godabar",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 689588,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174005,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571423,
        "city": "Krishnarajpet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271312,
        "city": "Domariyaganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 767046,
        "city": "Saintala",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632526,
        "city": "Rameswaram",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 767020,
        "city": "Loisingha",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 768016,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 847409,
        "city": "Phulparas",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 792056,
        "city": "Bordumsa",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 625105,
        "city": "Melur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824130,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781023,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 560084,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 623711,
        "city": "Muthukulathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 452020,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 225403,
        "city": "Dariyabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 202142,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 391145,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 781371,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 122052,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781370,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 580026,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 799204,
        "city": "Aambasa",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 843360,
        "city": "Aurai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 382732,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 444802,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 212651,
        "city": "Fatehpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 491668,
        "city": "AmbagarhChowki",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 160063,
        "city": "Mohali",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246401,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 497555,
        "city": "Manendragarh",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 625218,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400606,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 207246,
        "city": "Ganjdundwara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 333502,
        "city": "Buhana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784522,
        "city": "Mangaldoi",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 690537,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754160,
        "city": "Tirtol",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 581119,
        "city": "Hirekerur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628615,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516173,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492099,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 812004,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 784502,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 788120,
        "city": "Bhaga",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523240,
        "city": "Podili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305006,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560008,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 796036,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 431523,
        "city": "Ambajogai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 303123,
        "city": "Renwal",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 571123,
        "city": "Gundlupet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713334,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 152126,
        "city": "Abohar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 577412,
        "city": "Thirthahalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205119,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 122503,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 210429,
        "city": "Rath",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516218,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 685581,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 123501,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781019,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 509210,
        "city": "Narayanpet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 721420,
        "city": "Contai",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781130,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 423305,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416616,
        "city": "Malvan",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 192124,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 603113,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203411,
        "city": "Siyana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713359,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 249302,
        "city": "Rishikesh",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 680733,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 713126,
        "city": "Guskhara",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 626008,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 610203,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533306,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 824208,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680571,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 515445,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250502,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 754145,
        "city": "Paradip",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577536,
        "city": "Challakere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785705,
        "city": "Golaghat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 768027,
        "city": "Kadobahal",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686606,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 516309,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 146024,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500991,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673579,
        "city": "Pulpally",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 500104,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 831010,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 243215,
        "city": "Nawabganj-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 670594,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 781135,
        "city": "Boko",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 620014,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 753121,
        "city": "Jalpaiguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680771,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 635304,
        "city": "Uthangarai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572112,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403505,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 249301,
        "city": "Pauri",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 560070,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415002,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 631551,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 614628,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 822132,
        "city": "Bisrampur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 484110,
        "city": "Shahdol",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 122229,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571109,
        "city": "Gundlupet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 203398,
        "city": "Anupshahar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627818,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680668,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 221111,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144009,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 458002,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 464331,
        "city": "Ganj Basoda",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 576233,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533213,
        "city": "Amalapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673122,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 402305,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 784172,
        "city": "Gohpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523105,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 764046,
        "city": "Balimela",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 184102,
        "city": "Kathua",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 385566,
        "city": "Tharad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700155,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 676504,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 580028,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625301,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 224203,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 795001,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 332001,
        "city": "Sikar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 503164,
        "city": "Padmajiwadi",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627003,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431723,
        "city": "Degloor",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 800015,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 781124,
        "city": "Chhaygaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 585227,
        "city": "Mannaekhelli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713144,
        "city": "Panagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 190006,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444303,
        "city": "Khamgaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 638452,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 247341,
        "city": "Gangoh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 515834,
        "city": "Uravakonda",
        "state": "Andhra Pradesh",
        "Zone": "S1"
       },
       {
        "pincode": 495552,
        "city": "Akaltara",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 852218,
        "city": "Triveninganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363035,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 202520,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 757102,
        "city": "Raj Berhampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 585201,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574162,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680102,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 604152,
        "city": "Gingee",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 754082,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638151,
        "city": "Karur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412204,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 497225,
        "city": "Wadrafnagar",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 110021,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 442203,
        "city": "Talegaon Sp",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 443304,
        "city": "Mehkar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 785699,
        "city": "Bokakhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 637306,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625007,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 423702,
        "city": "Gangapur-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 587134,
        "city": "Mahalingpur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 442903,
        "city": "Chimur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 767039,
        "city": "Kantabanji",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 767066,
        "city": "Titilagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 422605,
        "city": "Sangamner",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 400024,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 304023,
        "city": "Nainwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781036,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 624621,
        "city": "Palani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628902,
        "city": "Vilathikulam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 457340,
        "city": "Jaora",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 752121,
        "city": "Nimapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 222148,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695035,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 785638,
        "city": "Teok",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 400610,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638012,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 518467,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 476111,
        "city": "Morena",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 276204,
        "city": "Kerakat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628005,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416235,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 411026,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 574241,
        "city": "Puttur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577160,
        "city": "Mudigere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 628752,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629204,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 505303,
        "city": "Yellareddipet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412806,
        "city": "Mahabaleshwar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 209735,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713155,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 831016,
        "city": "Jamshedpur",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 458118,
        "city": "Manasa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 210209,
        "city": "Shankargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574158,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 585425,
        "city": "Bidar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 793113,
        "city": "Marshillong",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 495668,
        "city": "Champa",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 508222,
        "city": "Tungaturthi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854329,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523292,
        "city": "Singarayakonda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533436,
        "city": "Koyyuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 464995,
        "city": "Mandideep",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 394240,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 613502,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695123,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686681,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 743399,
        "city": "Dakshin Barasat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 370615,
        "city": "Nakhatrana",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 602102,
        "city": "Sriperumbudur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 486670,
        "city": "Amiliya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 501501,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 123023,
        "city": "Narnaul",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 614711,
        "city": "Thiruthuraipoondi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 491226,
        "city": "Balod",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 671322,
        "city": "Manjeshwar",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 574214,
        "city": "Belthangady",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362222,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 638060,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688521,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 396415,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 625547,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 431519,
        "city": "Ambajogai",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 581327,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362230,
        "city": "Madhavpur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 670675,
        "city": "Mahe",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 261506,
        "city": "Lakhimpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 801103,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 848103,
        "city": "Samastipur",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 134008,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 229211,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 737135,
        "city": "Rangpo",
        "state": "Sikkim",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 365630,
        "city": "Visavadar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 786001,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 612504,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176098,
        "city": "Khundian",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400063,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 141020,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 203206,
        "city": "Sikandrabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 210122,
        "city": "Atarra",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 144621,
        "city": "Begowal",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 695001,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 246421,
        "city": "Rudraprayag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533244,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 444108,
        "city": "Akot",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 402204,
        "city": "Alibag",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 176039,
        "city": "Dulchehra",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 221202,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 591222,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 632502,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492012,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 524347,
        "city": "Nellore",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 494771,
        "city": "Pakhanjur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 686634,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627813,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689102,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 689115,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 815352,
        "city": "Jamtara",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523002,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 636204,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 391780,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 639103,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508212,
        "city": "Kusumanchi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344035,
        "city": "Barmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 212212,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721152,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 456337,
        "city": "Nagda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 518442,
        "city": "Atmakur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272182,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627812,
        "city": "Tenkasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 572226,
        "city": "Tiptur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413115,
        "city": "Baramati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 414703,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 506101,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 629194,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 752003,
        "city": "Puri",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 451666,
        "city": "Sendhwa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 110091,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 732207,
        "city": "Kaliachak",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 635852,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 152001,
        "city": "Firozpur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388160,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 332023,
        "city": "Ramgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 620019,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781336,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 752081,
        "city": "Nayagarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444201,
        "city": "Jalgaon Jamod",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 462042,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 610004,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 624304,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691544,
        "city": "Kottarakkara",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 624305,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 736208,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 766107,
        "city": "Komana",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577601,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 311603,
        "city": "Shahpura",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 784178,
        "city": "Gohpur",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 685510,
        "city": "Iddukki",
        "state": "Kerala",
        "Zone": "S2"
       },
       {
        "pincode": 585237,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 496338,
        "city": "Jashpur Nagar",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 600039,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524202,
        "city": "Kavali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 136038,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 801302,
        "city": "Hilsa",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 763008,
        "city": "Semiliguda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854365,
        "city": "Purnia",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571501,
        "city": "Channapatna",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 743330,
        "city": "Baruipur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 788099,
        "city": "Sonabarighat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 457779,
        "city": "Jhabua",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 848236,
        "city": "Rusera",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 275102,
        "city": "Mau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415806,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 700011,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 222129,
        "city": "Cholapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636014,
        "city": "Salem",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303511,
        "city": "Lalsot",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 473105,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 518573,
        "city": "Sirvella",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500044,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 609806,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 215126,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571320,
        "city": "Hanur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 770075,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 637204,
        "city": "Velur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 495556,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 583285,
        "city": "Koppal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 627851,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 387340,
        "city": "Kapadvanj",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 274201,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 786174,
        "city": "Bordumsa",
        "state": "Arunachal Pradesh",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 387115,
        "city": "Umreth",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 502335,
        "city": "Medak",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535281,
        "city": "Seetharampram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 313203,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 151506,
        "city": "Mansa",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 504215,
        "city": "Luxettipet",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 690543,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 502221,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700037,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632519,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680671,
        "city": "Kodungallur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 110109,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 671314,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413513,
        "city": "Ahmadpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110012,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 509338,
        "city": "Kosgi",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 344704,
        "city": "Dhorimana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144530,
        "city": "Garhshanker",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416119,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 394518,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 770073,
        "city": "Sundargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 572116,
        "city": "Amarapuram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 190014,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 414702,
        "city": "Daund",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 637412,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 503122,
        "city": "Yellareddy",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781301,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 753002,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 321301,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 392012,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 845428,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533407,
        "city": "Tuni",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 689629,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 411040,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 624008,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 841301,
        "city": "Chhapra",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 425508,
        "city": "Raver",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 584167,
        "city": "Siruguppa",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321603,
        "city": "Bharatpur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 304505,
        "city": "Malpura",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 394640,
        "city": "Bardoli",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 312620,
        "city": "Nimbahera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 636703,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 423302,
        "city": "Satana",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 824202,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 152022,
        "city": "Jalalabad",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 523114,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 835218,
        "city": "Mahuadanr",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 247551,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621715,
        "city": "Ariyalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 110604,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 614303,
        "city": "Thanjavur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416812,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713202,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 854116,
        "city": "Gauripur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 637018,
        "city": "Rasipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 493449,
        "city": "Mahasamund",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 743423,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 414003,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 144027,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 563116,
        "city": "Bethamangala",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 382735,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 360320,
        "city": "Gondal",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 783321,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 431208,
        "city": "Jafrabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 611002,
        "city": "Nagapattinam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500007,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 190003,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695311,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585221,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516464,
        "city": "Tadipatri",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 331507,
        "city": "Sujangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 791122,
        "city": "Daporijo",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 574153,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571463,
        "city": "Malavalli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400700,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 624612,
        "city": "Oddanchatram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 535250,
        "city": "S Kota",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 335704,
        "city": "Srivijaynagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535557,
        "city": "Bobbili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 416804,
        "city": "Devgad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 637405,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 695032,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 689550,
        "city": "Kozhenchery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 251319,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 311025,
        "city": "Bhilwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638106,
        "city": "Dharapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 191123,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 609703,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 221601,
        "city": "Madhuban",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 144022,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441228,
        "city": "Saoli",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 495660,
        "city": "Jaijaipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 312616,
        "city": "Pratapgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 843131,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122104,
        "city": "Nuh",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721169,
        "city": "Panskura",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 190023,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 389320,
        "city": "Halol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 523205,
        "city": "Markapur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 250604,
        "city": "Ambuj Nagar",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 624210,
        "city": "Kodaikanal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 362570,
        "city": "UNA",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 470672,
        "city": "Damoh",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 209715,
        "city": "Auraiya",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 626149,
        "city": "Rajapalayam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 844120,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 629805,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 787033,
        "city": "Narayanpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 221204,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 795141,
        "city": "Tamenglong",
        "state": "Manipur",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 246444,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691312,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 736180,
        "city": "Cooch Behar",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673524,
        "city": "Perambra",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 518010,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500034,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 411001,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 395008,
        "city": "Kadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 622407,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680314,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 721159,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 249193,
        "city": "Uttarkashi",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628202,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 577101,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 458775,
        "city": "Bhanpura",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 560105,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 212502,
        "city": "Soraon",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 328021,
        "city": "Bari",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110504,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 679330,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 796025,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 416520,
        "city": "Oras",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263621,
        "city": "Dwarahat",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 494635,
        "city": "Bhanupratappur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 670141,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 505504,
        "city": "Mahadevpur",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 173004,
        "city": "Paonta Sahib",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 604102,
        "city": "Tindivanam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680600,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 631603,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384255,
        "city": "Patan",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 233303,
        "city": "Ghazipur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 591120,
        "city": "Khanapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758038,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 342305,
        "city": "Mathania",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 392005,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 305401,
        "city": "Nasirabad",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516329,
        "city": "Vempalli",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768032,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 621213,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 561204,
        "city": "Doddaballapura",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415612,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 637214,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 612006,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 723216,
        "city": "Bhaddi",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 721517,
        "city": "Jhargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 144213,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 152104,
        "city": "Muktsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 620025,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 523246,
        "city": "Podili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 852212,
        "city": "Saharsa",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 321409,
        "city": "Weir",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 711324,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 212307,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 390019,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 581355,
        "city": "Siddapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843130,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413528,
        "city": "Kaij",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 124404,
        "city": "Sonipat",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781346,
        "city": "Tihu",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 757001,
        "city": "Baripada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686579,
        "city": "Pala",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 243203,
        "city": "Baheri",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 600034,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209622,
        "city": "JalalabadUP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 415304,
        "city": "Vita",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 788802,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 212655,
        "city": "Khaga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679506,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 203131,
        "city": "Khurja",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 711306,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363020,
        "city": "Surendranagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 175002,
        "city": "Sundar Nagar",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 473115,
        "city": "Binaganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 605652,
        "city": "Viluppuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 679325,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 572175,
        "city": "Madhugiri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533342,
        "city": "Mandapeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 803216,
        "city": "Bihar Sharif",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143107,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 442710,
        "city": "Bhamragarh",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 314401,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110020,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 341528,
        "city": "Kuchaman",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691316,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781013,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 788125,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 176205,
        "city": "Palion",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 263641,
        "city": "Baijnath-UK",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 585223,
        "city": "Shahapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 623204,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283104,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 629179,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585355,
        "city": "Yadgir",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 679581,
        "city": "Chiramanangad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 638112,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743273,
        "city": "Swarupnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 643233,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 854112,
        "city": "Manihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 243724,
        "city": "Sahaswan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 453556,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 327026,
        "city": "Banswara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 362227,
        "city": "Keshod",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 571405,
        "city": "Mandya",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761122,
        "city": "Asika",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 521157,
        "city": "Pamarru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509128,
        "city": "Gadwal",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 741504,
        "city": "Gopalnagar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673026,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 382426,
        "city": "Gandhinagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 370410,
        "city": "Mundra",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 481771,
        "city": "Mandla",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 533291,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413223,
        "city": "Kurduwadi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 276202,
        "city": "Kerakat",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 341510,
        "city": "Merta",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 456770,
        "city": "Shajapur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 587155,
        "city": "Ramdurg",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785003,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 395017,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 784504,
        "city": "Thelamara",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 700034,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743507,
        "city": "Ghatakpukur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 822110,
        "city": "Daltonganj",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 854340,
        "city": "Birpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 629167,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755008,
        "city": "Chandipur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 816107,
        "city": "Pakur",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431513,
        "city": "Hingoli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 147004,
        "city": "Patiala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 533295,
        "city": "Rampachodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 530043,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 263639,
        "city": "Someshwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431507,
        "city": "Manjlegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 416712,
        "city": "Lanja",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 453551,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 394114,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 804429,
        "city": "Jehanabad",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 222125,
        "city": "Badlapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 394510,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 793005,
        "city": "Shillong",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 444301,
        "city": "Amdapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 641019,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 276142,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413543,
        "city": "Pune",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 642113,
        "city": "Udumalaipettai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 412211,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 490003,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 754131,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 481990,
        "city": "ShahpuraMP",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 301427,
        "city": "Neemrana",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854316,
        "city": "Forbesganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 363755,
        "city": "Viramgam",
        "state": "Gujarat",
        "Zone": "W1"
       },
       {
        "pincode": 388345,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 834008,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 802166,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 392210,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 742156,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679105,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 305005,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 507211,
        "city": "Yellandu",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 755031,
        "city": "Chandikhol",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638202,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 453115,
        "city": "Depalpur",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 688582,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341011,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 638107,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 416012,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 583130,
        "city": "Molakalmuru",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781121,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 425410,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 110052,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 416205,
        "city": "Kale",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 626119,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 494661,
        "city": "Narayanpur-CH",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 583203,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522265,
        "city": "Repalle",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 641035,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 533106,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 443403,
        "city": "Nandura",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 624201,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 606806,
        "city": "Tiruvannamalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621216,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 227405,
        "city": "Amethi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388235,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680585,
        "city": "Shoranur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 414606,
        "city": "Newasa",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 712512,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 782141,
        "city": "Nagaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 402301,
        "city": "Mahad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 272125,
        "city": "Basti",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 628301,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711401,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 396140,
        "city": "Vapi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 301027,
        "city": "Viratnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 824113,
        "city": "Daudnagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 764061,
        "city": "Kotapad",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480559,
        "city": "Parasia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 148106,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271882,
        "city": "Nanpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 243720,
        "city": "Chandausi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 244251,
        "city": "Amroha",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847451,
        "city": "Phulparas",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 263148,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 829112,
        "city": "Phusro",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 614624,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396001,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 678701,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 623502,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678532,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 401301,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 629173,
        "city": "Marthandam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 431707,
        "city": "Loha",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 606708,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621702,
        "city": "Thirukkattupalli",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713143,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 784180,
        "city": "BiswanathCH",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 384153,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713207,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 752032,
        "city": "Maludkhas",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 606204,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 384170,
        "city": "Unjha",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 505453,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680510,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600002,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 201012,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 174316,
        "city": "Amb",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224182,
        "city": "Ayodhya",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 249405,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 142039,
        "city": "Bhadaur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700029,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 768048,
        "city": "Padampur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761042,
        "city": "Digapahandi",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412309,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 788114,
        "city": "Bhaga",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 770036,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743422,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786004,
        "city": "Dibrugarh",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 493889,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 333028,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 732205,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 571103,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 485551,
        "city": "Nagod",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 224155,
        "city": "Tanda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 414103,
        "city": "Ahmednagar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 580002,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713103,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 305925,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 785668,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 603301,
        "city": "Melmaruvathur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 625110,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272150,
        "city": "Rudhauli",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 743293,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 845102,
        "city": "Narkatiaganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821316,
        "city": "Sasaram",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 506142,
        "city": "Warangal",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689510,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 853201,
        "city": "Naugachia",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 201021,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 454334,
        "city": "Barwani",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 431725,
        "city": "Loha",
        "state": "Maharashtra",
        "Zone": "W2"
       },
       {
        "pincode": 781380,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 313324,
        "city": "Rajsamand",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431202,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 603318,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 273158,
        "city": "Anandnagar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 177032,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 517421,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 585215,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 501301,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 466446,
        "city": "Nasrullaganj",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 388245,
        "city": "Balasinor",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 835232,
        "city": "Gumla",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 383430,
        "city": "Idar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 503307,
        "city": "Armoor",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691300,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 679537,
        "city": "Sankaramangalam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 413712,
        "city": "Loni",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 517644,
        "city": "Srikalahasti",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 683557,
        "city": "Perumbavoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 796015,
        "city": "Aizawl",
        "state": "Mizoram",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 793102,
        "city": "Nongpoh",
        "state": "Meghalaya",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 522009,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 224181,
        "city": "Rajesultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 622505,
        "city": "Pudukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 475673,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 247232,
        "city": "Saharanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 680565,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 804427,
        "city": "Makhdumpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444726,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 177039,
        "city": "Bangana",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 632201,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 843126,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785649,
        "city": "Sivasagar",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 506104,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283142,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535003,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 711205,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 581317,
        "city": "Karwar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148104,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 534003,
        "city": "Eluru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 576228,
        "city": "Bhatkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 490002,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 783121,
        "city": "Goalpara",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 848109,
        "city": "Samastipur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517583,
        "city": "Puttur-AP",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516228,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 461445,
        "city": "Itarsi",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 361250,
        "city": "Dhrol",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 248006,
        "city": "Dehradun",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 500009,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 305022,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500659,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 524121,
        "city": "Sullurupeta",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768047,
        "city": "Bargarh",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 505425,
        "city": "Dharmapuri-TS",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562131,
        "city": "Kunigal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 282004,
        "city": "Agra",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 306501,
        "city": "Pali",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271401,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 363440,
        "city": "Limbdi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 632531,
        "city": "Kanchipuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 573162,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 143412,
        "city": "Patti",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 413205,
        "city": "Jamkhed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 229125,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 431536,
        "city": "Gangakher",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 507318,
        "city": "Khammam",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625503,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 621205,
        "city": "Kulithalai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 765022,
        "city": "Gunupur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 491331,
        "city": "Berla",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 473287,
        "city": "Maksudangarh",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 683110,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 326510,
        "city": "Ramganj Mandi",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 500018,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 456008,
        "city": "Ujjain",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 247453,
        "city": "Nanauta",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 531219,
        "city": "Bheemunipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574143,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635103,
        "city": "Hosur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 175030,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516227,
        "city": "Badvel",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394410,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144533,
        "city": "Rupnagar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 612804,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 580001,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 445401,
        "city": "Yavatmal",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 577128,
        "city": "Shimoga",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283207,
        "city": "Firozabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 160079,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 682039,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 761016,
        "city": "Mohana",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 570010,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 452001,
        "city": "Indore",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 622306,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 623211,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 470227,
        "city": "Rehli",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 713205,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 678102,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 144620,
        "city": "Kapurthala",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 783302,
        "city": "Dhubri",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 605766,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 413116,
        "city": "Baramati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 306115,
        "city": "Falna",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691604,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174003,
        "city": "Bilaspur-HP",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 852213,
        "city": "Sonbarsa-BH",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312902,
        "city": "Chittaurgarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 221406,
        "city": "Bhadohi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691305,
        "city": "Ayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273311,
        "city": "Nichlaul",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 389210,
        "city": "Lunawada",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 824205,
        "city": "Sherghati",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 176025,
        "city": "Jawali",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 312404,
        "city": "Bari Sadri",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 388110,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 632313,
        "city": "Arani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 205120,
        "city": "Mainpuri",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 313206,
        "city": "Fatehnagar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 521215,
        "city": "Vissannapetaa",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678547,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 262803,
        "city": "Khutar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222103,
        "city": "Jalalpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 180002,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 361160,
        "city": "Kalavad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 689597,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 364140,
        "city": "Talaja",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 627129,
        "city": "Vallioor",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283119,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 423211,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 263143,
        "city": "Rudrapur",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 754153,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 440033,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 625514,
        "city": "Madurai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802112,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 250102,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 625102,
        "city": "Ponnamaravathi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 461251,
        "city": "Harda",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 784514,
        "city": "Udalguri",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 712408,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 786612,
        "city": "Naharkatia",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 637415,
        "city": "Namakkal",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 122231,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 440030,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670521,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 502286,
        "city": "Narayankhed",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 681029,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 122226,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 231302,
        "city": "Chunnar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 271881,
        "city": "Nanpara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 110401,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 844123,
        "city": "Lalganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824219,
        "city": "Sherghati",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 312602,
        "city": "Mandphiya",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 790116,
        "city": "Bomdila",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 786181,
        "city": "Margherita",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 805128,
        "city": "Wazirganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 680711,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 174503,
        "city": "Tahliwal",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686534,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 191102,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 493558,
        "city": "Saraipalli",
        "state": "Chhattisgarh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 680562,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 629806,
        "city": "Nagercoil",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 271002,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 230137,
        "city": "Lalganj Ajhara",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 841436,
        "city": "Mirganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 500085,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 768222,
        "city": "Kuchinda",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 388440,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 782105,
        "city": "Morigaon",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 505327,
        "city": "Jagtial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 524236,
        "city": "Pamuru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700071,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 488441,
        "city": "Amanganj",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 474004,
        "city": "Gwalior",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742174,
        "city": "Nowda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 431717,
        "city": "Degloor",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 585283,
        "city": "Shorapur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 393003,
        "city": "Ankleshwar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 577573,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686552,
        "city": "Changanacherry",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 383276,
        "city": "Modasa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 144407,
        "city": "Phagwara",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824218,
        "city": "Aurangabad-BR",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 122220,
        "city": "Gurgaon",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759021,
        "city": "Dhenkanal",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 670013,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 416541,
        "city": "Ratnagiri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 626213,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 272164,
        "city": "Khalilabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 581453,
        "city": "Alnavar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209115,
        "city": "Pukhrayan",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788001,
        "city": "Silchar",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 382710,
        "city": "Mehsana",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 148038,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 322234,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 766101,
        "city": "Kesinga",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 802351,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 821109,
        "city": "Mohania",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 305812,
        "city": "Kishangarh",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 228001,
        "city": "Sultanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 682010,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 621219,
        "city": "Perambalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 180016,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 515311,
        "city": "Madakasira",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515741,
        "city": "Kalyandurg",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 722202,
        "city": "Barjora",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 751009,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 713384,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 770022,
        "city": "Bamra",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 577175,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 785014,
        "city": "Jorhat",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 680028,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 841238,
        "city": "Maharajganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 201018,
        "city": "Ghaziabad",
        "state": "Uttar Pradesh",
        "Zone": "N2"
       },
       {
        "pincode": 203208,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 626501,
        "city": "Theni",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 768030,
        "city": "Bargarh",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 761003,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 403507,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 605803,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 394307,
        "city": "Valsad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 521110,
        "city": "Pedapadu",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 581338,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574105,
        "city": "Manipal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441502,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 341027,
        "city": "Nagaur",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 805123,
        "city": "Nawada",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 174034,
        "city": "Ghumarwin",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 759125,
        "city": "Puranakatak",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 670691,
        "city": "Kuthuparamba",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768216,
        "city": "Brajrajnagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 764038,
        "city": "Koraput",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 110003,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 522616,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 801108,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 673616,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 754022,
        "city": "Cuttack",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 424307,
        "city": "Dhule",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 736176,
        "city": "Dinhata",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 795008,
        "city": "Imphal",
        "state": "Manipur",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686537,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 341025,
        "city": "Khimsar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 181207,
        "city": "Akhnoor",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 403410,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 262802,
        "city": "Gola",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 516312,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 171304,
        "city": "Karsog",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 621719,
        "city": "Pennadam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415505,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 391442,
        "city": "Vadodara",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 758014,
        "city": "Kendujhar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841427,
        "city": "Gopalganj",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 171204,
        "city": "Kotkhai",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 781376,
        "city": "Baihata",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 412115,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 670309,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 761018,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679335,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 680684,
        "city": "Chalakudy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 303105,
        "city": "Kotputli",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 734403,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 505326,
        "city": "Koratla",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303901,
        "city": "Chaksu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854205,
        "city": "Bhawanipur Rajdham",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680308,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 751006,
        "city": "Bhubaneshwar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574222,
        "city": "Bantwal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713151,
        "city": "Bardhaman",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 530030,
        "city": "Visakhapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 835229,
        "city": "Torpa",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 638458,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 276122,
        "city": "Jiyanpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 742404,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 570032,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 475115,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 700133,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 331402,
        "city": "Sardarshahr",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141205,
        "city": "Raikot",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 222104,
        "city": "Shahganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632209,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 136119,
        "city": "Kurukshetra",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533288,
        "city": "Rampachodavaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 321609,
        "city": "Mahwa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678103,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 413704,
        "city": "Rahuri",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 845417,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143402,
        "city": "Tarn Taran Sahib",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 250625,
        "city": "Baraut",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 332719,
        "city": "Reengus",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224145,
        "city": "Faizabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 792129,
        "city": "Deomali",
        "state": "Arunachal Pradesh",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 713210,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412214,
        "city": "UrliKanchan",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 403206,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 177042,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 224171,
        "city": "Faizabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 679339,
        "city": "Edavanna",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 627701,
        "city": "Kovilpatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781317,
        "city": "Barpeta",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 481666,
        "city": "Tikariya",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 713217,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 762101,
        "city": "Balliguda",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 480880,
        "city": "Katangi",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 515331,
        "city": "Hindupur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517419,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 680621,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 523135,
        "city": "Addanki",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 322033,
        "city": "Sawai Madhopur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431200,
        "city": "Jalna",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 533248,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 516356,
        "city": "Proddatur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303338,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 262405,
        "city": "Sitarganj",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 225120,
        "city": "Haidargarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700163,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 380023,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 416130,
        "city": "Ichalkaranji",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 403723,
        "city": "Goa",
        "state": "Goa",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 713169,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400082,
        "city": "Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 110078,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 176213,
        "city": "Dharamshala",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 676318,
        "city": "Malappuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 623603,
        "city": "Perunali",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 492005,
        "city": "Raipur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 243639,
        "city": "Budaun",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 766105,
        "city": "Nuapada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400032,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 271207,
        "city": "Balrampur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 815305,
        "city": "Giridih",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 211001,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 226301,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 172034,
        "city": "Jeori",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 535002,
        "city": "Vizianagaram",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 531055,
        "city": "Yellamanchili",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 517003,
        "city": "Chittoor",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 673575,
        "city": "Kalpetta",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 609107,
        "city": "Sirkazhi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 246473,
        "city": "Chamoli",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628008,
        "city": "Tuticorin",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 625602,
        "city": "Vadipatti",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577129,
        "city": "Kadur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 273012,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 614614,
        "city": "Peravurani",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 691572,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 573212,
        "city": "Hassan",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 843127,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 444602,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 441902,
        "city": "Gondia",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 577539,
        "city": "Channagiri",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508376,
        "city": "Suryapet",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 700145,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 143105,
        "city": "Amritsar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 522233,
        "city": "Chilakaluripet",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743376,
        "city": "Canning",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 413005,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394375,
        "city": "Navapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 413003,
        "city": "Solapur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 394230,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 811102,
        "city": "Lakhisarai",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 785674,
        "city": "Nazira",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 176502,
        "city": "Ghati Billan",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 303007,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 456443,
        "city": "Agar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 176062,
        "city": "Palampur",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689123,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 605801,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 202389,
        "city": "Dibai",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 689648,
        "city": "Adoor",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 788722,
        "city": "Karimganj",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 741509,
        "city": "Hanskhali",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 400051,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843141,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 743295,
        "city": "Joypul",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523184,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 737113,
        "city": "Pelling",
        "state": "Sikkim",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 743426,
        "city": "Taki",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 171207,
        "city": "Rohru",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533242,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518466,
        "city": "Kurnool",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577133,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 500054,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 228133,
        "city": "Lambhua",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 796431,
        "city": "WestPhaileng",
        "state": "Mizoram",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 413510,
        "city": "Osmanabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 305202,
        "city": "Beawar",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 691014,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 175035,
        "city": "Thunag",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431504,
        "city": "Mantha",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 635602,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 261001,
        "city": "Sitapur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 631209,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 781304,
        "city": "Sarthebari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 523157,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 582222,
        "city": "Hubli",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 767034,
        "city": "Sambalpur",
        "state": "Orissa",
        "Zone": "E1"
       },
       {
        "pincode": 755019,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 799008,
        "city": "Agartala",
        "state": "Tripura",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 160015,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 306601,
        "city": "Khinwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 535594,
        "city": "Kurupam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 626127,
        "city": "Sivakasi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 686144,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 742190,
        "city": "Raghunathganj",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 816103,
        "city": "Shikaripara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 276124,
        "city": "Jiyanpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571605,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303004,
        "city": "Dausa",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 572168,
        "city": "Tumkur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 844101,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 475686,
        "city": "Datia",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 225306,
        "city": "Fatehpur-UP",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 583268,
        "city": "Gangavathi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 176403,
        "city": "Pathankot",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382715,
        "city": "Kadi",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 177046,
        "city": "Nadaun",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 492018,
        "city": "Abhanpur",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 531117,
        "city": "Narsipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 574235,
        "city": "Vitla",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400703,
        "city": "Navi mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 685532,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 361003,
        "city": "Jamnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 583281,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756121,
        "city": "Jajpur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 440029,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 521130,
        "city": "Avanigadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 302030,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262522,
        "city": "Pithoragarh",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431152,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 500037,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 691541,
        "city": "Pangodu",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 591308,
        "city": "Gokak",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 673517,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 769015,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 627012,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713170,
        "city": "Balagarh",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 416425,
        "city": "Sangli",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 711409,
        "city": "Howrah",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 302055,
        "city": "Ajmer",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400108,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 731202,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 494337,
        "city": "Charama",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 151101,
        "city": "Bathinda",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 770032,
        "city": "Rourkela",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 632103,
        "city": "Gudiyattam",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 411035,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 848511,
        "city": "Dalsingh Sarai",
        "state": "Bihar",
        "Zone": "E1"
       },
       {
        "pincode": 323303,
        "city": "Rawatbhata",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 561209,
        "city": "Bagepalli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600023,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 303012,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 782143,
        "city": "Kaliabor",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 360012,
        "city": "Rajkot",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 625532,
        "city": "Usilampatti",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 521162,
        "city": "Machilipatnam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 328023,
        "city": "Kheragarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 477335,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 276404,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412802,
        "city": "Khandala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 754229,
        "city": "Kendrapara",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 841209,
        "city": "Ekma",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 761135,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 754011,
        "city": "Adaspur",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 799114,
        "city": "Udaipur-TR",
        "state": "Tripura",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 800011,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 202138,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 848501,
        "city": "Mohiuddin Nagar",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394652,
        "city": "Songadh",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 600076,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 518594,
        "city": "Allagadda",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 509382,
        "city": "Mahabubnagar",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 605754,
        "city": "Thirukoilure",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 786192,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 333011,
        "city": "Jhunjhunu",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571154,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 577111,
        "city": "Chikkamagalur",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 723202,
        "city": "Jhalda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 824232,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609605,
        "city": "Karaikal",
        "state": "Pondicherry",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 518510,
        "city": "Sirvella",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 581128,
        "city": "Haveri",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209722,
        "city": "Kannauj",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 403703,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 262534,
        "city": "Berinag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 441302,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 686600,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 400072,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 843119,
        "city": "Muzaffarpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 713357,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686686,
        "city": "Muvattupuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 421503,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 221306,
        "city": "Gopiganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 608701,
        "city": "Lalpet",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 670330,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 643242,
        "city": "Ooty",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 635710,
        "city": "Tiruppattur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 425427,
        "city": "Shirpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 679552,
        "city": "Edappal",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 516108,
        "city": "Koduru",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761030,
        "city": "Kodala",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 533249,
        "city": "Razole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 229216,
        "city": "Lalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 574236,
        "city": "Moodabidri",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 765001,
        "city": "Raygada",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 394516,
        "city": "Surat",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 205125,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 482010,
        "city": "Jabalpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 453220,
        "city": "Badnagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 209724,
        "city": "Farrukhabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 712302,
        "city": "Tarkeshwar",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 688567,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 518165,
        "city": "Allagadda",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 828133,
        "city": "Dhanbad",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 133005,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 713516,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 517237,
        "city": "Madanapalle",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 743145,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 501502,
        "city": "Vikarabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 576127,
        "city": "Uchila",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 604303,
        "city": "Marakkanam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444601,
        "city": "Amravati",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 314032,
        "city": "Sagwara",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 742401,
        "city": "Katwa",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 211005,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623401,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 571342,
        "city": "Chamarajanagar",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 582217,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 562132,
        "city": "Bangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 734010,
        "city": "Siliguri",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 614402,
        "city": "Pattukkottai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 834007,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 622102,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 444908,
        "city": "Warud",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 581384,
        "city": "Honnavar",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 626612,
        "city": "Sivaganga",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 441123,
        "city": "Nagpur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 251320,
        "city": "Mawana",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 305415,
        "city": "Kekri",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721633,
        "city": "Bajkul",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 502273,
        "city": "Jogipet",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591214,
        "city": "Chikodi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712331,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690550,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 262201,
        "city": "Bisalpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 494002,
        "city": "Jagdalpur",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 322230,
        "city": "Karauli",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 683565,
        "city": "Kunnathunad",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 587114,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 742138,
        "city": "Khargram",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 623103,
        "city": "Karaikudi",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 533105,
        "city": "Rajahmundry",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572221,
        "city": "Turuvukere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 396560,
        "city": "Vansda",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 276301,
        "city": "Azamgarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 508115,
        "city": "Bhuvanagiri",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 176001,
        "city": "Kangra",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 678732,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 581401,
        "city": "Sirsi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783332,
        "city": "Tamarhat",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 385570,
        "city": "Tharad",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 533220,
        "city": "Draksharamam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 185203,
        "city": "Sunderbani",
        "state": "Jammu & Kashmir",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 477105,
        "city": "Bhind",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 845424,
        "city": "Sahebganj-BH",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 583202,
        "city": "Hospet",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 134116,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 641103,
        "city": "Coimbatore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522408,
        "city": "Narasaraopet",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 490022,
        "city": "Durg",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 502251,
        "city": "Zahirabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 571474,
        "city": "Mandya",
        "state": "Karnataka",
        "Zone": "S1"
       },
       {
        "pincode": 110503,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 583126,
        "city": "Kudligi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 251316,
        "city": "Muzaffarnagar",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 695010,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 306104,
        "city": "Sojat",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 131407,
        "city": "Sonipat",
        "state": "Haryana",
        "Zone": "N2"
       },
       {
        "pincode": 506105,
        "city": "Mahabubabad",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 403508,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 360578,
        "city": "Porbandar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 670702,
        "city": "Kannur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 854312,
        "city": "Araria",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 793119,
        "city": "Nongstoin",
        "state": "Meghalaya",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 274703,
        "city": "Salempur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 362260,
        "city": "Mendarda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 643224,
        "city": "Gudalur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 283114,
        "city": "Fatehabad-UP",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 400004,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 500078,
        "city": "Hyderabad",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 781012,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 783124,
        "city": "Dudhnoi",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 207249,
        "city": "Aliganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 741249,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 631208,
        "city": "Sholinghur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 504302,
        "city": "Mancherial",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 758037,
        "city": "Barbil",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 586118,
        "city": "Talikoti",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 493895,
        "city": "Gariyaband",
        "state": "Chhattisgarh",
        "Zone": "C1"
       },
       {
        "pincode": 755004,
        "city": "Binjharpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 679336,
        "city": "Cherpulassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 457118,
        "city": "Alote",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 624620,
        "city": "Gujiliamparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303121,
        "city": "Achrol",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 571435,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400095,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 522529,
        "city": "Guntur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 424101,
        "city": "Chalisgaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 204214,
        "city": "Sikandra Rao",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 700064,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 759144,
        "city": "Angul",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 475682,
        "city": "Lahar",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 680311,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 515701,
        "city": "Anantapur",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802126,
        "city": "Dumraon",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 411501,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 148026,
        "city": "Sangrur",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 462019,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 192221,
        "city": "Anantnag",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 176303,
        "city": "Dalhousie",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 683543,
        "city": "Aluva",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 711406,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 190018,
        "city": "Srinagar",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 211010,
        "city": "Allahabad",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 202280,
        "city": "Aligarh",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 628205,
        "city": "Eral",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 148028,
        "city": "Sunam",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 414305,
        "city": "Alephata",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 610109,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 712206,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 522102,
        "city": "Chirala",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 689623,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 761146,
        "city": "Hinjilicut",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 606208,
        "city": "Sankarapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713381,
        "city": "Dubrajpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 402117,
        "city": "Mangaon",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 416006,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 638812,
        "city": "Tirupur",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 688552,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 571250,
        "city": "Gonikoppal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 520007,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 802156,
        "city": "Arrah",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 306302,
        "city": "Bilara",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 847107,
        "city": "Dholi",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 673638,
        "city": "Kozhikode",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581129,
        "city": "Dandeli",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209747,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 127041,
        "city": "Meham",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 271311,
        "city": "Colonejganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 627360,
        "city": "Tirunelveli",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 487225,
        "city": "Narsinghpur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 471515,
        "city": "Lavkush Nagar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 712304,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 364110,
        "city": "Bhavnagar",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 607401,
        "city": "Pondicherry",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 591118,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 174101,
        "city": "Baddi",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 361306,
        "city": "Khambhalia",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 680518,
        "city": "Guruvayoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 686555,
        "city": "Karukachal",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 202145,
        "city": "Hathras",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 788815,
        "city": "Kalain",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 670111,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 585306,
        "city": "Chincholi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 508254,
        "city": "Nalgonda",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 756131,
        "city": "Tihidi",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 582203,
        "city": "Rona",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209731,
        "city": "Chhibramau",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 533429,
        "city": "Yerravaram",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 801505,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 734317,
        "city": "Kalimpong",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695126,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 146022,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 781350,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 277219,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 627423,
        "city": "Ambasamudram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 400102,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 160018,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 524341,
        "city": "Atmakur-AP",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591125,
        "city": "Bailhongal",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 411004,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 403713,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 394716,
        "city": "Ahwa",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 303908,
        "city": "Chaksu",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 670104,
        "city": "Thalassery",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 631212,
        "city": "Arakkonam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 400045,
        "city": "Mumbai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 845302,
        "city": "Motihari",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 570015,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 454448,
        "city": "Manawar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 250105,
        "city": "Meerut",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 824230,
        "city": "Gaya",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 423203,
        "city": "Malegaon",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 624303,
        "city": "Dindigul",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 600022,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 497559,
        "city": "Chirimiri",
        "state": "Chhattisgarh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 788126,
        "city": "Pailapool",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 410613,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 731239,
        "city": "Bolpur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 678683,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 465661,
        "city": "Rajgarh-MP",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 832304,
        "city": "Ghatshila",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 680587,
        "city": "Pazhayannur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 273411,
        "city": "Barhalganj",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 124109,
        "city": "Jhajjar",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 721428,
        "city": "RamnagarWB",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 812006,
        "city": "Bhagalpur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 611101,
        "city": "Thiruvarur",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 515122,
        "city": "Puttaparthi",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 415502,
        "city": "Vaduj",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 743132,
        "city": "Naihati",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 834001,
        "city": "Ranchi",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 690524,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 612904,
        "city": "Jayankondam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 676102,
        "city": "Tirur",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 587103,
        "city": "Bagalkot",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 334403,
        "city": "Bikaner",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 532484,
        "city": "Srikakulam",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 333302,
        "city": "Udaipurwati",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 623807,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 761131,
        "city": "Bhanjanagar",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 507117,
        "city": "Manuguru",
        "state": "Telangana",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 277218,
        "city": "Ballia",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 485661,
        "city": "Maihar",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 576216,
        "city": "Kundapura",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 572121,
        "city": "Koratagere",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 678008,
        "city": "Palakkad",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 227125,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 431719,
        "city": "Udgir",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 396440,
        "city": "Navsari",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 713358,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 623514,
        "city": "Ramanathapuram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 412415,
        "city": "Junnar",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 846004,
        "city": "Darbhanga",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 518533,
        "city": "Velgodu",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 814103,
        "city": "Shikaripara",
        "state": "Jharkhand",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 609203,
        "city": "Mayiladuthurai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 396310,
        "city": "Bilimora",
        "state": "Gujarat",
        "ODA": "TRUE",
        "Zone": "W1"
       },
       {
        "pincode": 271308,
        "city": "Gonda",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 502280,
        "city": "Siddipet",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 608602,
        "city": "Chidambaram",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 685582,
        "city": "Thodupuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 403722,
        "city": "Goa",
        "state": "Goa",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 571119,
        "city": "Mysore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 302036,
        "city": "Jaipur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 382433,
        "city": "Ahmedabad",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 678689,
        "city": "Anjumoorthy",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 133101,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 461775,
        "city": "Pipariya",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 410507,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 586202,
        "city": "Sindagi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 383355,
        "city": "Bhiloda",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 690107,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 761002,
        "city": "Behrampur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 690515,
        "city": "Tiruvalla",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 581347,
        "city": "Yellapur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 680545,
        "city": "Thrissur",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 583237,
        "city": "Kushtagi",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 415622,
        "city": "Khed",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 384315,
        "city": "Vadnagar",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 303119,
        "city": "Paota",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 688512,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 415515,
        "city": "Wai",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 800030,
        "city": "Patna",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 415518,
        "city": "Satara",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 756017,
        "city": "Balasore",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 412201,
        "city": "Pune",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 462101,
        "city": "Bhopal",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 505122,
        "city": "Jammikunta",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 209841,
        "city": "Lucknow",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 731223,
        "city": "Rampurhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 781348,
        "city": "Nalbari",
        "state": "Assam",
        "ODA": "TRUE",
        "Zone": "NE"
       },
       {
        "pincode": 313605,
        "city": "Dhariyawad",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 581332,
        "city": "Kumta",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 325219,
        "city": "Kawai",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 721605,
        "city": "Haldia",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 246193,
        "city": "Gumkhal",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 416005,
        "city": "Kolhapur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 516162,
        "city": "Cuddapah",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 532214,
        "city": "Pathapatnam",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 325223,
        "city": "Khanpur",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 262531,
        "city": "Berinag",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 612301,
        "city": "Kumbakonam",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 783381,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 247666,
        "city": "Haridwar",
        "state": "Uttarakhand",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 385519,
        "city": "Palanpur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 741173,
        "city": "Nazirpur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 515134,
        "city": "Gorantla",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 713375,
        "city": "Rupnarayanpur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 473101,
        "city": "Guna",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 271803,
        "city": "Bhinga",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 303508,
        "city": "Bandikui",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 247778,
        "city": "Shamli",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 173021,
        "city": "Paonta Sahib",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 141015,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 301408,
        "city": "Rajgarh-RJ",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 713201,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 686513,
        "city": "Mundakayam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 781373,
        "city": "Tamulpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 370405,
        "city": "Mundra",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 835304,
        "city": "Lohardaga",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 583103,
        "city": "Bellary",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 473865,
        "city": "Dabra",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 431101,
        "city": "Aurangabad",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 798612,
        "city": "Tuensang",
        "state": "Nagaland",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 690565,
        "city": "Irinjalakuda",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 768101,
        "city": "Sambalpur",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 133301,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 813108,
        "city": "Sultanganj",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 734104,
        "city": "Darjeeling",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 331026,
        "city": "Malsisar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 246737,
        "city": "Chandpur",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 854336,
        "city": "Birpur",
        "state": "Bihar",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 577218,
        "city": "Davangere",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 458116,
        "city": "Manasa",
        "state": "Madhya Pradesh",
        "ODA": "TRUE",
        "Zone": "C1"
       },
       {
        "pincode": 122051,
        "city": "Bilaspur-HR",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 140501,
        "city": "Rajpura",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 575019,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 160005,
        "city": "Chandigarh",
        "state": "Chandigarh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 814155,
        "city": "Mahagama",
        "state": "Jharkhand",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 802154,
        "city": "Brahmapur",
        "state": "Bihar",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 207001,
        "city": "Etah",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 690546,
        "city": "Kollam",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 415209,
        "city": "Patan-MH",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 505402,
        "city": "Bejjanki",
        "state": "Telangana",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 621307,
        "city": "Manapparai",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 303339,
        "city": "Phulera",
        "state": "Rajasthan",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412103,
        "city": "Baramati",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 134101,
        "city": "Pinjore",
        "state": "Haryana",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 143605,
        "city": "Ajnala",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600037,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 388275,
        "city": "Anand",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 521226,
        "city": "Vissannapetaa",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 788112,
        "city": "Bhaga",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 110098,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 713376,
        "city": "Durgapur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 574173,
        "city": "Mangalore",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 732121,
        "city": "Malda",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 177110,
        "city": "Chintpurni",
        "state": "Himachal Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 488443,
        "city": "Raipura",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 756117,
        "city": "Dhamanagar",
        "state": "Orissa",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 124508,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "TRUE",
        "Zone": "N1"
       },
       {
        "pincode": 144046,
        "city": "Hoshiarpur",
        "state": "Punjab",
        "Zone": "N2"
       },
       {
        "pincode": 625516,
        "city": "Cumbum",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 144211,
        "city": "Dasuya",
        "state": "Punjab",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 686017,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 600089,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 713370,
        "city": "Asansol",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 523328,
        "city": "Yerragonda Palem",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 741252,
        "city": "Kalyani",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 695044,
        "city": "Thiruvananthapuram",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 591115,
        "city": "Kittur",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 391165,
        "city": "Chhota Udaipur",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 700100,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 582114,
        "city": "Yelburga",
        "state": "Karnataka",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 458556,
        "city": "Mandsaur",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 742165,
        "city": "Baharampur",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 712137,
        "city": "Dankuni",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 425431,
        "city": "Shahada",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 281205,
        "city": "Khair",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 600058,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 181122,
        "city": "Jammu",
        "state": "Jammu & Kashmir",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 783391,
        "city": "Bongaigaon",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 686632,
        "city": "Kottayam",
        "state": "Kerala",
        "ODA": "FALSE",
        "Zone": "S2"
       },
       {
        "pincode": 175102,
        "city": "Kullu",
        "state": "Himachal Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 412209,
        "city": "Shirur",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 743401,
        "city": "Basirhat",
        "state": "West Bengal",
        "ODA": "TRUE",
        "Zone": "E1"
       },
       {
        "pincode": 231304,
        "city": "Chunnar",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 274003,
        "city": "Deoria",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 632311,
        "city": "Vellore",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 110016,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 757035,
        "city": "Raruan",
        "state": "Orissa",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 441306,
        "city": "Katol",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 273401,
        "city": "Gorakhpur",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 144001,
        "city": "Jalandhar",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 444707,
        "city": "Morshi",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 486005,
        "city": "Rewa",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 700112,
        "city": "Kolkata",
        "state": "West Bengal",
        "ODA": "FALSE",
        "Zone": "E1"
       },
       {
        "pincode": 391152,
        "city": "Nasvadi",
        "state": "Gujarat",
        "ODA": "FALSE",
        "Zone": "W1"
       },
       {
        "pincode": 263151,
        "city": "Sitarganj",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 623713,
        "city": "Thiruvadanai",
        "state": "Tamil Nadu",
        "Zone": "S1"
       },
       {
        "pincode": 522330,
        "city": "Tenali",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 522501,
        "city": "Vijayawada",
        "state": "Andhra Pradesh",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 591212,
        "city": "Athani",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 688530,
        "city": "Alappuzha",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 416105,
        "city": "Jaysingpur",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 784028,
        "city": "Tezpur",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 231306,
        "city": "Varanasi",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 587154,
        "city": "Ilkal",
        "state": "Karnataka",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 600057,
        "city": "Chennai",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 522608,
        "city": "Ongole",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 638006,
        "city": "Erode",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 141008,
        "city": "Ludhiana",
        "state": "Punjab",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 110051,
        "city": "Delhi",
        "state": "Delhi",
        "ODA": "FALSE",
        "Zone": "N1"
       },
       {
        "pincode": 248142,
        "city": "Herbertpur",
        "state": "Uttarakhand",
        "ODA": "TRUE",
        "Zone": "N2"
       },
       {
        "pincode": 400609,
        "city": "Greater Thane",
        "state": "Maharashtra",
        "ODA": "FALSE",
        "Zone": "W2"
       },
       {
        "pincode": 691535,
        "city": "Kilimanoor",
        "state": "Kerala",
        "ODA": "TRUE",
        "Zone": "S2"
       },
       {
        "pincode": 201316,
        "city": "Noida",
        "state": "Uttar Pradesh",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 603111,
        "city": "Chengalpattu",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 786170,
        "city": "Tinsukia",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 457551,
        "city": "Ratlam",
        "state": "Madhya Pradesh",
        "ODA": "FALSE",
        "Zone": "C1"
       },
       {
        "pincode": 781126,
        "city": "Hajo",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 636701,
        "city": "Dharmapuri",
        "state": "Tamil Nadu",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 620002,
        "city": "Tiruchi",
        "state": "Tamil Nadu",
        "ODA": "FALSE",
        "Zone": "S1"
       },
       {
        "pincode": 410403,
        "city": "Lonavala",
        "state": "Maharashtra",
        "ODA": "TRUE",
        "Zone": "W2"
       },
       {
        "pincode": 781032,
        "city": "Guwahati",
        "state": "Assam",
        "ODA": "FALSE",
        "Zone": "NE"
       },
       {
        "pincode": 523113,
        "city": "Kandukur",
        "state": "Andhra Pradesh",
        "ODA": "TRUE",
        "Zone": "S1"
       },
       {
        "pincode": 305629,
        "city": "Bijainagar",
        "state": "Rajasthan",
        "ODA": "FALSE",
        "Zone": "N2"
       },
       {
        "pincode": 283151,
        "city": "Shikohabad",
        "state": "Uttar Pradesh",
        "ODA": "TRUE",
        "Zone": "N2"
       }
      
];
export default pincodeData;